import adapter from 'webrtc-adapter';
import {
  INaviInfo, isNumber, isString, isObject, ILogger, BasicLogger,
} from '@rongcloud/engine';
import { RCFrameRate } from './core/enums/RCFrameRate';
import { RCResolution } from './core/enums/RCResolution';
import { RCRTCMessageType } from './core/enums/inner/RCRTCMessageType';
import {
  IPKInfo, IPublishAttrs, IPublishedResource, IResource, ISubscribeAttr, RoomData,
} from './core/interfaces';
import { RCMediaType } from './core/enums/RCMediaType';
import { getBitrateMultiple, getNearestResolution, RongRTCVideoBitrate } from './core/enums/RCRTCResolution';
import { RCLocalTrack } from './core/tracks/RCLocalTrack';
import RCRTCPeerConnection from './core/webrtc/RCRTCPeerConnection';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from './core/tracks/RCRemoteTrack';
import { RCTrack } from './core/tracks/RCTrack';
import { IRTCUsers, IServerRTCRoomEntry } from './core/codec/interface';
import { RCLoggerTag } from './core/enums/RCLoggerTag';

/**
 * 构建增量消息内容
 * @param objectname 消息名
 * @param uris 增量变更资源
 */
export const buildPlusMessage = (messageName: RCRTCMessageType, uris: IPublishedResource[]) => ({
  name: messageName,
  // ignore 用于通知已实现全量 URI 的 RTCLib 忽略此消息
  content: JSON.stringify({ uris, ignore: true }),
});

/**
 * 构建预发布的全量资源变更消息
 * @param uris 全量资源数据
 */
export const buildTotalURIMessageContent = (uris: IPublishedResource[]) => JSON.stringify(uris);

/**
 * roomId 有效性验证
 * @param roomId
 */
export const isValidRoomId = (roomId: string) => isString(roomId) && /^[A-Za-z0-9+=_-]+$/.test(roomId) && roomId.length <= 64;

/**
 * 验证 tag 是否有效
 * @param tag
 * @returns
 */
export const isValidTag = (tag: string): boolean => /^[a-zA-Z\d-=]+$/g.test(tag);

/**
 * 页面地址有效性检测
 */
export const isValidLocation = location.protocol !== 'http:' || ['localhost', '127.0.0.1'].includes(location.hostname);

const getValue = (value: number | ConstrainULongRange | ConstrainDoubleRange | undefined): number => {
  if (value === undefined) {
    return 0;
  }
  if (isNumber(value)) {
    return value as number;
  }
  const tmp: ConstrainULongRange = value as ConstrainULongRange;
  return tmp.exact || tmp.ideal || tmp.max || 0;
};

/**
 * 获取视频流的分辨率及帧率数据，若无法获取真实值，则返回 0
 * @param track
 */
export const getVideoTrackInfo = (track: MediaStreamTrack) => {
  const settings = track.getSettings();
  const constraints = track.getConstraints();

  return {
    /**
     * applyConstraints 方法应用的宽高约束不一定可以立马生效
     * 取值优先 constraints
     */
    width: getValue(constraints.width) || settings.width || 0,
    height: getValue(constraints.height) || settings.height || 0,
    frameRate: settings.frameRate || getValue(constraints.frameRate),
  };
};

/**
 * 取视频流动态码率
 * @param track
 * @returns
 */
export const getDynamicBitrate = (track: MediaStreamTrack) => {
  const { width, height, frameRate } = getVideoTrackInfo(track);
  // 计算动态码率，若 videoTrack 的分辨率读取失败，则以 1920 * 1080 的默认分辨率计算码率
  const config = getNearestResolution(width || 1920, height || 1080);
  const multiple = getBitrateMultiple(frameRate);
  return { min: config.minBitrate * multiple, max: config.maxBitrate * multiple };
};

/**
 * 获取资源唯一性标识
 * @param item
 */
export const getTrackId = (item: IResource) => [item.msid, item.mediaType].join('_');

/**
 * 它接受一个类似“userId_tag_mediaType”的字符串，并返回一个包含字符串三个部分的对象
 * 解析 trackId 以获取资源信息
 * trackId 构成说明 trackId = [ userid, tag, mediaType ].join('_')
 *    userid    : 用户可随意定义可包含 _ 下划线
 *    tag       : `不可以有 _ 下划线`
 *    mediaType : RCMediaType 0:音频流  1:视频流 2:音视频混合流
 * @param {string} trackId - 远程轨道的轨道 ID。
 * @returns 具有三个属性的对象：mediaType、tag 和 userId。
 */
export const parseTrackId = (trackId: string) => {
  const arr = trackId.split('_');
  const mediaType: RCMediaType = parseInt(arr.pop()!);
  const tag = arr.pop()!;
  const userId = arr.join('_');
  return { mediaType, tag, userId };
};

export const parseStreamId = (streamId: string) => {
  const arr = streamId.split('_');
  const tag = arr.pop()!;
  const userId = arr.join('_');
  return { tag, userId };
};

export const formatStreamId = (userId: string, tag: string) => [userId, tag].join('_');

export const deepCopyResources = <T>(resources: T[]): T[] => resources.map((item) => ({ ...item }));

/**
 * 比对资源找出新增、状态变更及取消发布的资源
 * @param prevResources 原资源数据
 * @param resources 变更的全量资源
 */
export const diffPublishResources = (prevResources: IPublishedResource[], resources: IPublishedResource[], isReconnect: boolean = false) => {
  prevResources = prevResources.slice();

  const publishedList: IPublishedResource[] = [];
  const unpublishedList: IPublishedResource[] = [];
  const modifiedList: IPublishedResource[] = [];

  // 遍历新全量资源
  resources.forEach((item) => {
    const resId = getTrackId(item);
    // 从当前房间数据中查找相同资源索引
    let index = prevResources.findIndex((value) => getTrackId(value) === resId);

    /**
     * 重连计算时，直接通过 uri 来算新增和删减的资源。因为 resources 即是最新资源，不能算资源已重新发布的情况
     * 在断网重连情况下如果使用 trackId 计算 index 会导致己端在断网期间，远端取消发布资源 A（tag:C）又重新发布资源 B(tag:C)时, 无法
     * 计算出取消发布的资源 A，只能算出新发布的资源 B, 导致页面视图无法更新、且订阅成功 B 资源后不抛 onTrackReady
     */
    if (isReconnect) {
      index = prevResources.findIndex((value) => value.uri === item.uri);
    }
    if (index === -1) {
      // 新增资源
      publishedList.push(item);
      return;
    }
    // 资源变更
    const preItem = prevResources[index];
    if (preItem.uri !== item.uri) {
      // 资源已重新发布
      publishedList.push(item);
    } else if (preItem.state !== item.state) {
      // 资源状态变更
      modifiedList.push(item);
    }
    // 从原资源列表中移除已变更资源，剩余为取消发布资源
    prevResources.splice(index, 1);
  });
  unpublishedList.push(...prevResources);
  return { publishedList, unpublishedList, modifiedList };
};

export const diffPubResOnReconnect = (prevResources: IPublishedResource[], resources: IPublishedResource[]) => {
  prevResources = prevResources.slice();

  const publishedList: IPublishedResource[] = [];
  const unpublishedList: IPublishedResource[] = [];
  const modifiedList: IPublishedResource[] = [];

  resources.forEach((item) => {
    const index = prevResources.findIndex((value) => value.uri === item.uri);
    if (index === -1) {
      publishedList.push(item);
      return;
    }

    const preItem = prevResources[index];
    if (preItem.state !== item.state) {
      modifiedList.push(item);
    }
    prevResources.splice(index, 1);
  });
  unpublishedList.push(...prevResources);
  return { publishedList, unpublishedList, modifiedList };
};

const string10to64 = (number: number) => {
  const chars = '0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ+/'.split('');
  const radix = chars.length + 1;
  let qutient = +number;
  const arr = [];
  do {
    const mod = qutient % radix;
    qutient = (qutient - mod) / radix;
    arr.unshift(chars[mod]);
  } while (qutient);
  return arr.join('');
};

const getUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

/* 获取 22 位的 UUID */
export const getUUID22 = () => {
  let uuid: string | number = getUUID();
  uuid = `${uuid.replace(/-/g, '')}0`;
  uuid = parseInt(uuid, 16);
  uuid = string10to64(uuid);
  if (uuid.length > 22) {
    uuid = uuid.slice(0, 22);
  }
  return uuid;
};

/**
 * 转化 RCResolution 枚举值为分辨率宽高
 * @param resolution
 * @returns
 */
export const transResolution = (resolution: RCResolution): { width: number, height: number } => {
  if (RongRTCVideoBitrate[resolution]) {
    const { width, height } = RongRTCVideoBitrate[resolution];
    return { width, height };
  }

  // 保留原有逻辑，防止客户有自定义传值解析异常
  const [width, height] = (resolution as string).split('_').map((item) => parseInt(item.replace(/[^\d]/g, '')));
  return { width, height };
};

/**
 * 判断枚举值有效性
 * @param resolution
 * @returns
 */
export const isValidResolution = (resolution?: RCResolution): boolean => !!RCResolution[resolution!];

/**
 * 判断帧率枚举值有效性
 * @param fps
 * @returns
 */
export const isValidFPS = (fps?: RCFrameRate): boolean => !!RCFrameRate[fps!];

/**
 * 获取枚举值对应的帧率
 * @param fps
 * @returns
 */
export const transFrameRate = (fps: RCFrameRate): number => parseInt((fps as string).replace('FPS_', ''));

export type IBrowserDetails = {
  browser: string,
  version?: number,
  supportsUnifiedPlan: boolean
}

export const browserInfo: IBrowserDetails = (() => {
  const { browser, version, supportsUnifiedPlan } = adapter.browserDetails;
  return {
    browser,
    version,
    // 非明确显示不支持 unified-plan 的浏览器，默认为支持 unified-plan
    supportsUnifiedPlan: supportsUnifiedPlan !== false,
  };
})();

/**
 * 验证浏览器是否支持创建自定义文件流
 * @returns
 */
export function ifSupportLocalFileTrack(): boolean {
  return 'captureStream' in HTMLMediaElement.prototype || 'mozCaptureStream' in HTMLMediaElement.prototype;
}

/**
 * 验证浏览器是否支持屏幕共享
 * @returns
 */
export function ifSupportScreenShare(): boolean {
  return 'mediaDevices' in navigator && 'getDisplayMedia' in navigator.mediaDevices;
}

/**
 * 检查参数是否为 null
*/
export const isNull = (val: any): boolean => Object.prototype.toString.call(val) === '[object Null]';

/**
 * 公有云连接私有云 SDK 为非法连接
 */
export const isIllegalConnection = (navi: INaviInfo): boolean => !__IS_ENTERPRISE__ && navi.type === 1;

/**
 * 获取将要发布的 track 数量
 * 需要发布小流的算两个 track
 */
export const calcTracksNum = (tracks:(RCLocalTrack | IPublishAttrs)[], pc: RCRTCPeerConnection): number => {
  let length = 0;
  tracks.forEach((item) => {
    const trackId = item instanceof RCLocalTrack ? item.getTrackId() : item.track.getTrackId();
    /**
     * 已经发布过的 track，不计入个数
     */
    if (pc.getLocalTrack(trackId)) {
      return;
    }

    if (item instanceof RCLocalTrack) {
      length++;
    } else if ((item as IPublishAttrs).pubTiny && item.track.isVideoTrack()) {
      length += 2;
    }
  });
  return length;
};

/**
 * 解析房间数据
 */
export const parseRoomData = (data: IRTCUsers, mainRoomId: string, logger: ILogger, traceId: string): RoomData => {
  const result: RoomData = {};
  const userIds = Object.keys(data.users);
  userIds.length && userIds.forEach((userId) => {
    const tmp: IPublishedResource[] = [];
    const userData = data.users[userId];

    /**
     * 过滤掉副房间身份的人员
     */
    if (userData.extra) {
      const { roomId } = JSON.parse(userData.extra);
      if (mainRoomId !== roomId) {
        return;
      }
    }

    if (userData.uris) {
      try {
        tmp.push(...JSON.parse(userData.uris));
      } catch (error) {
        logger.warn(RCLoggerTag.L_PARSE_ROOMDATA_URIS_R, `invalid user data -> userId: ${userId}, userData: ${userData}`, traceId);
      }
    }
    result[userId] = tmp;
  });
  return result;
};

export const isRepeatPub = (newPub: IPublishedResource, allPublishList: IPublishedResource[]) => {
  let isInclude = false;
  let index = 0;
  for (let i = 0; i < allPublishList.length; i++) {
    const oldPub = allPublishList[i];
    if (`${newPub.msid}_${newPub.mediaType}` === `${oldPub.msid}_${oldPub.mediaType}`) {
      isInclude = true;
      index = i;
      break;
    }
  }
  return {
    isInclude,
    index,
  };
};
/**
 * 从加房间返回的数据中获取加入的副房间
 */
export const getPKInfoByRoomData = (mainRoomId: string, roomInfo: { key : string, value: string }[]) => {
  const pkInfo: IPKInfo = {};
  roomInfo.forEach((item) => {
    const PKValue = JSON.parse(item.value);
    const { inviterRoomId, inviteeRoomId } = PKValue;
    const roomId = (mainRoomId === inviterRoomId) ? inviteeRoomId : inviterRoomId;
    pkInfo[roomId] = JSON.parse(item.value);
  });
  return pkInfo;
};

/**
 * 解析观众加房间 kv 数据
 * 远端无人员时，kvEntries 的 key 不包含 RC_ANCHOR_LIST
 * 远端无资源时，key 不包含 RC_RES_、RC_CDN
 * 远端有资源、无 CDN 资源时，key 不包含 RC_CDN
 * 服务端 bug，偶现无 RC_RTC_SESSIONID
 */
export const parseAudienceRoomData = (roomId: string, kvEntries: IServerRTCRoomEntry[], logger: BasicLogger) => {
  const session = kvEntries.filter((kvItem: IServerRTCRoomEntry) => kvItem.key === 'RC_RTC_SESSIONID')[0];

  const sessionId = session ? session.value : '';

  /**
   * 房间内远端人员
   */
  const remoteUserIds: string[] = kvEntries.filter((kvItem: IServerRTCRoomEntry) => kvItem.key === 'RC_ANCHOR_LIST').map((kvItem: IServerRTCRoomEntry) => JSON.parse(kvItem.value || '[]'))[0];

  /**
   * 远端 RTC、MUC 资源
   */
  const remoteRes = kvEntries.filter((kvItem: IServerRTCRoomEntry) => kvItem.key.includes('RC_RES_')).map((kvItem: IServerRTCRoomEntry) => JSON.parse(kvItem.value || '{}'));

  /**
   * 远端 MUC 资源
   */
  const remoteMUCUris: IPublishedResource[] = remoteRes.length ? JSON.parse(remoteRes[0].mcu_uris || '[]') : [];
  /**
   * 远端 MUC tracks
   */
  const remoteMCUTracks: RCRemoteTrack[] = [];
  remoteMUCUris.forEach((uri: IPublishedResource) => {
    const { mediaType, tag } = uri;
    const track = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(logger, tag, '', roomId) : new RCRemoteVideoTrack(logger, tag, '', roomId);
    remoteMCUTracks.push(track);
  });

  /**
   * 远端 RTC 资源
   */
  const remoteRTCUris: IPublishedResource[] = [];
  /**
   * 远端 RTC tracks
   */
  const remoteRTCTracks: RCRemoteTrack[] = [];
  remoteRes.forEach((res) => {
    const RTCUris = JSON.parse(res.uris || '[]');
    remoteRTCUris.push(...RTCUris);

    RTCUris.forEach((uri: IPublishedResource) => {
      const { mediaType, tag, msid } = uri;
      const userId = msid.split('_')[0];
      const track = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(logger, tag, userId) : new RCRemoteVideoTrack(logger, tag, userId);
      remoteRTCTracks.push(track);
    });
  });

  /**
   * 房间内 CDN 信息
   */
  const CDNUris = kvEntries.filter((kvItem: IServerRTCRoomEntry) => kvItem.key === 'RC_CDN').map((kvItem: IServerRTCRoomEntry) => {
    const CDNUriStr = JSON.parse(kvItem.value || '[]');
    return JSON.parse(CDNUriStr.cdn_uris)[0];
  })[0];

  return {
    sessionId,
    remoteUserIds: remoteUserIds || [],
    remoteRTCUris,
    remoteMUCUris,
    remoteRTCTracks,
    remoteMCUTracks,
    remoteTracks: [...remoteRTCTracks, ...remoteMCUTracks],
    CDNUris: CDNUris || {},
  };
};

export const getTrackIdFromAttr = (track: RCTrack | ISubscribeAttr | IPublishAttrs): string => {
  if (track instanceof RCTrack) {
    return track.getTrackId();
  }
  return track.track.getTrackId();
};

export const int64ToTimestamp = (obj: any):number => {
  if (!isObject(obj) || obj.low === undefined || obj.high === undefined) {
    return obj;
  }
  let { low } = obj;
  if (low < 0) {
    low += 0xffffffff + 1;
  }
  low = low.toString(16);
  const timestamp = parseInt(obj.high.toString(16) + '00000000'.replace(new RegExp(`0{${low.length}}$`), low), 16);
  return timestamp;
};
