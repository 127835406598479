// @since version 5.3.0
import {
  ErrorCode, ILogger, IReceivedMessage, IRuntime, isBoolean, isUndefined, notEmptyString, RCConnectionStatus, validate,
} from '@rongcloud/engine';
import { LeaveOtherRoomCommand } from '../command/LeaveOtherRoomCommand';

import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCLivingType } from '../enums/RCLivingType';
import { RCRTCCode } from '../enums/RCRTCCode';
import {
  IPKInfo, ICancelReqInviteInfo, IEndPKMsgContent, IPKAnswerMsgContent, IPKEndInfo, IPKInviteAnswerInfo,
  IPKInviteInfo, IPKMsgContent, IRCRTCInitOptions, IReqInviteInfo, IReqResPKOptions, IResInviteContent,
} from '../interfaces';
import { Invoker } from '../Invoker';
import { getUUID } from '../service/helper';
import RCMediaService from '../service/RCMediaService';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';
import RCLivingRoom from './RCLivingRoom';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';

import { RTCContext } from '../codec/RTCContext';
import { RTCMode } from '../enums/RTCMode';
import { IReqRoomPKOptions, ICancelRoomPKOptions, IResRoomPKOptions } from '../codec/interface';

export type IOnRecvPKMsg = (msg: IReceivedMessage) => void

export interface IRoomPKEventListener {
  /**
   * 收到连麦邀请
   */
  onRequestJoinOtherRoom: (info: IPKInviteInfo) => {},
  /**
   * 收到取消连麦邀请
   */
  onCancelRequestOtherRoom: (info: IPKInviteInfo) => {},
  // onPKInviteTimeout: (info: IPKInviteTimeoutInfo) => {},
  /**
   * 收到连麦 PK 请求响应结果
   */
  onResponseJoinOtherRoom: (info: IPKInviteAnswerInfo) => {},
  /**
   * 收到 PK 结束
   */
  onFinishOtherRoom: (info: IPKEndInfo) => {}
}

export class RCLivingPKHandler {
  /**
   * PK 邀请超时时间，默认 30s
   */
  private readonly _inviteTimeout: number = 30

  private _appListener: IRoomPKEventListener | null = null

  private _mainRoomId: string

  /**
   * 跨房间连麦加入的 PK 房间
   */
  private _joinedPKRooms: {[roomId: string]: RCLivingRoom} = {}

  private readonly _logger: ILogger;

  constructor(
    private _invoker: Invoker,
    private _PKInfo: IPKInfo,
    private readonly _context: RTCContext,
    private readonly _runtime: IRuntime,
    private readonly _service: RCMediaService,
    private readonly _initOptions: IRCRTCInitOptions,
    /**
     * 主直播房间
     */
    private readonly _mainLivingRoom: RCLivingRoom,
    private readonly _registerPKMsgListener: (listener: IOnRecvPKMsg) => void,
    /**
     * 加入 PK 房间回调
     */
    private readonly _onJoinedPKRoom: (roomId: string, traceId: string) => void,

    protected readonly _clientSessionId?: string,
  ) {
    this._logger = this._context.logger;
    this._registerPKMsgListener(this._onRecvPKMsg.bind(this));
    this._mainRoomId = this._mainLivingRoom.getRoomId();
  }

  private _callAppListener<K extends keyof IRoomPKEventListener>(eventType: keyof IRoomPKEventListener, ...attrs: Parameters<IRoomPKEventListener[K]>) {
    const handle = this._appListener?.[eventType] as Function;
    if (!handle) {
      return;
    }
    try {
      handle(...attrs);
    } catch (error) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_CALL_APP_LISTENER_O, JSON.stringify(error));
    }
  }

  /**
   * 收到连麦邀请
   */
  private _onInvite(content: IPKMsgContent) {
    const inviteInfo = (content.inviteInfo || {}) as IReqInviteInfo;
    const { inviterRoomId, inviterUserId, extra } = inviteInfo;
    const info: IPKInviteInfo = {
      inviterRoomId,
      inviterUserId,
      extra,
    };

    this._PKInfo[inviterRoomId] = inviteInfo;

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: 'onRequestJoinOtherRoom',
      info,
    }));
    this._callAppListener('onRequestJoinOtherRoom', info);
  }

  /**
   * 收到取消连麦
   */
  private _onCancelInvite(content: IPKMsgContent) {
    const { inviterRoomId, inviterUserId, extra } = (content.inviteInfo || {}) as ICancelReqInviteInfo;
    const cancelInfo: IPKInviteInfo = {
      inviterRoomId,
      inviterUserId,
      extra,
    };

    delete this._PKInfo[inviterRoomId];

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: 'onCancelRequestOtherRoom',
      cancelInfo,
    }));
    this._callAppListener('onCancelRequestOtherRoom', cancelInfo);
  }

  private _onInviteTimeout(content: IPKMsgContent) {
    // 因服务计时不准，暂不处理。（与移动端一致）
  }

  /**
   * 收到响应连麦
   */
  private _onInviteAnswer(content: IPKAnswerMsgContent) {
    const { answerCode, inviteContent } = content;
    const {
      inviteSessionId, inviterUserId, inviterRoomId, inviteeUserId, inviterUserAutoMix, inviteeUserAutoMix, inviteeRoomId, extra,
    } = inviteContent;

    const answerInfo: IPKInviteAnswerInfo = {
      agree: answerCode === 1,
      inviterRoomId,
      inviterUserId,
      inviteeRoomId,
      inviteeUserId,
      extra,
    };

    /**
     * 收到非本人发起的邀请连麦时，需新组装 PKInfo
     */
    this._PKInfo[inviteeRoomId] = this._PKInfo[inviteeRoomId] || {
      inviteSessionId,
      inviterRoomId,
      inviterUserId,
      inviterUserAutoMix,
      inviteeRoomId,
    };

    this._PKInfo[inviteeRoomId].inviteeUserAutoMix = inviteeUserAutoMix;
    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: 'onResponseJoinOtherRoom',
      answerInfo,
    }));
    this._callAppListener('onResponseJoinOtherRoom', answerInfo);
  }

  private createLeaveOtherRoomCommand(room: RCLivingRoom, isQuitPK?: boolean, traceId?: string) {
    return new LeaveOtherRoomCommand(this, room, this._PKInfo, this._joinedPKRooms, isQuitPK, traceId);
  }

  /**
   * 收到连麦结束
   */
  private async _onPKEnd(content: IEndPKMsgContent) {
    const { inviteeRoomId, inviterRoomId, userId } = content.inviteInfo;
    const roomId = inviterRoomId === this._mainRoomId ? inviteeRoomId : inviterRoomId;
    const endInfo = {
      endRoomId: roomId,
      endUserId: userId,
    };

    // 兼容先退出房间，再收到 pk 结束的情况
    const room = this._joinedPKRooms[roomId];
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_QUIT_PK_LEAVE_PK_ROOM_O, undefined, traceId);
    room && await this._invoker.push(this.createLeaveOtherRoomCommand(room, true, traceId));
    delete this._PKInfo[roomId];

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: 'onFinishOtherRoom',
      endInfo,
    }));
    this._callAppListener('onFinishOtherRoom', endInfo);
  }

  /**
   * 处理跨房间连麦相关消息
   */
  private _onRecvPKMsg(msg: IReceivedMessage) {
    const { targetId: roomId, content, messageType } = msg;

    switch (messageType) {
      case RCRTCMessageType.PK_INVITE:
        this._onInvite(<IPKMsgContent>content);
        break;
      case RCRTCMessageType.PK_CANCEL_INVITE:
        this._onCancelInvite(<IPKMsgContent>content);
        break;
      case RCRTCMessageType.PK_INVITE_TIMEOUT:
        this._onInviteTimeout(<IPKMsgContent>content);
        break;
      case RCRTCMessageType.PK_INVITE_ANSWER:
        this._onInviteAnswer(<IPKAnswerMsgContent>content);
        break;
      case RCRTCMessageType.PK_END:
        this._onPKEnd(<IEndPKMsgContent>content);
        break;
      default:
        break;
    }
  }

  /**
   * 注册跨房间连麦监听事件
   */
  public registerRoomPKEventListener(listener: IRoomPKEventListener) {
    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_REGISTER_ROOM_PK_EVENT_LISTENER_O, JSON.stringify({
      listener: listener && Object.keys(listener),
    }));

    this._appListener = listener;
  }

  /**
   * 发起跨房间连麦请求
   * @param inviteeRoomId 被邀请者所处的房间 roomId
   * @param inviteeUserId 被邀请者 userId
   * @param options.autoMix 是否将本房间资源合并到被邀请者所处房间的 MCU 合流中
   * @param options.extra 拓展字段，可随邀请连麦消息透传给被邀请者
   */
  public async requestJoinOtherRoom(inviteeRoomId: string, inviteeUserId: string, options?: IReqResPKOptions): Promise<{code: RCRTCCode | ErrorCode}> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_REQUEST_JOIN_OTHER_ROOM_T, JSON.stringify({
      inviteeRoomId,
      inviteeUserId,
      options,
    }), traceId);

    if (!(
      validate('inviteeRoomId', inviteeRoomId, notEmptyString, true)
      && validate('inviteeUserId', inviteeUserId, notEmptyString, true)
    )) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> inviteeRoomId or inviteeUserId',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const inviteSessionId = getUUID();
    const autoMix = isBoolean(options?.autoMix) ? options?.autoMix : true;

    const inviteInfo: IReqInviteInfo = {
      inviteSessionId,
      inviterRoomId: this._mainRoomId,
      inviterUserId: this._context.getCurrentId(),
      inviterUserAutoMix: autoMix!,
      inviteeRoomId,
      inviteeUserId,
      inviteeTimeoutTime: this._inviteTimeout,
      extra: options?.extra || '',
    };
    const params: IReqRoomPKOptions = {
      roomId: this._mainRoomId,
      invitedRoomId: inviteeRoomId,
      invitedUserId: inviteeUserId,
      inviteTimeout: this._inviteTimeout,
      inviteInfo: JSON.stringify(inviteInfo),
      inviteSessionId,
    };

    const code = await this._context.requestRoomPK(params);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);

      return { code };
    }

    this._PKInfo[inviteeRoomId] = {
      inviteSessionId,
      inviterRoomId: this._mainRoomId,
      inviterUserId: this._context.getCurrentId(),
      inviterUserAutoMix: autoMix,
      inviteeRoomId,
    };

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), traceId);

    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 取消跨房间连麦请求
   * @param inviteeRoomId 被邀请者所处的房间 roomId
   * @param inviteeUserId 被邀请者 userId
   * @param extra 附加信息，可随取消邀请连麦消息透传给被邀请者
   */
  public async cancelRequestJoinOtherRoom(inviteeRoomId: string, inviteeUserId: string, extra?: string): Promise<{code: RCRTCCode | ErrorCode}> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_T, JSON.stringify({
      inviteeRoomId,
      inviteeUserId,
      extra,
    }), traceId);

    if (!(
      validate('inviteeRoomId', inviteeRoomId, notEmptyString, true)
      && validate('inviteeUserId', inviteeUserId, notEmptyString, true)
    )) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> inviteeRoomId or inviteeUserId',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }
    if (!this._PKInfo[inviteeRoomId]) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: `The request to connect with ${inviteeUserId} user in room ${inviteeRoomId} is not initiated`,
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const inviteInfo: ICancelReqInviteInfo = {
      inviterRoomId: this._mainRoomId,
      inviterUserId: this._context.getCurrentId(),
      inviteeRoomId,
      inviteeUserId,
      extra: extra || '',
    };
    const params: ICancelRoomPKOptions = {
      roomId: this._mainRoomId,
      invitedRoomId: inviteeRoomId,
      invitedUserId: inviteeUserId,
      inviteSessionId: this._PKInfo[inviteeRoomId].inviteSessionId,
      inviteInfo: JSON.stringify(inviteInfo),
    };

    const code = await this._context.cancelRoomPK(params);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);

      return { code };
    }

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), traceId);

    delete this._PKInfo[inviteeRoomId];
    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 响应跨房间连麦请求
   * @param inviterRoomId 邀请者所处的房间 roomId
   * @param inviterUserId 邀请者 userId
   * @param agree 是否同意连麦
   * @param options.autoMix 是否将本房间资源合并到邀请者所处房间的 MCU 合流中
   * @param options.extra 附加信息，可随响应连麦消息透传给邀请者
   */
  public async responseJoinOtherRoom(inviterRoomId: string, inviterUserId: string, agree: boolean, options?: IReqResPKOptions): Promise<{code: RCRTCCode | ErrorCode}> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_T, JSON.stringify({
      inviterRoomId,
      inviterUserId,
      agree,
      options,
    }), traceId);

    if (!(
      validate('inviterRoomId', inviterRoomId, notEmptyString, true)
      && validate('inviterUserId', inviterUserId, notEmptyString, true)
    )) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> inviterRoomId or inviterUserId',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }
    if (!this._PKInfo[inviterRoomId]) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: `User ${inviterUserId} in room ${inviterRoomId} did not send a request for connection`,
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const { inviteSessionId, inviterUserAutoMix } = this._PKInfo[inviterRoomId];
    const autoMix = isBoolean(options?.autoMix) ? options?.autoMix : true;
    const value = {
      inviteSessionId,
      inviterRoomId,
      inviterUserId,
      inviterUserAutoMix,
      inviteeRoomId: this._mainRoomId,
      inviteeUserId: this._context.getCurrentId(),
      inviteeUserAutoMix: autoMix!,
    };

    const multiRoomVal = Object.assign(value, { inviterUserAutoMix });
    const content: IResInviteContent = agree
      ? Object.assign(value, {
        MultiRoomKey: `${inviterRoomId}|${this._mainRoomId}`,
        MultiRoomValue: JSON.stringify(multiRoomVal),
      })
      : value;

    !isUndefined(options?.extra) && Object.assign(content, { extra: options!.extra });

    const params: IResRoomPKOptions = {
      agree,
      roomId: this._mainRoomId,
      inviteSessionId,
      inviteRoomId: inviterRoomId,
      inviteUserId: inviterUserId,
      content: JSON.stringify(content),
      key: `${inviterRoomId}|${this._mainRoomId}`,
      value: JSON.stringify(value),
    };

    const code = await this._context.responseRoomPK(params);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);

      return { code };
    }

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), traceId);

    this._PKInfo[inviterRoomId].inviteeUserAutoMix = autoMix;
    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 加入副直播房间
   * @roomId 副房间的 roomId
   */
  public async joinOtherRoom(roomId: string): Promise<{code: RCRTCCode, room?: RCLivingRoom, userIds?: string[], tracks?: RCRemoteTrack[], CDNEnable?: boolean}> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_T, JSON.stringify({
      roomId,
    }), traceId);

    if (!(validate('roomId', roomId, notEmptyString, true))) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> roomId',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    if (this._context.getConnectionStatus() !== RCConnectionStatus.CONNECTED) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.SIGNAL_DISCONNECTED,
        msg: 'IM disconnected',
      }), traceId);

      return { code: RCRTCCode.SIGNAL_DISCONNECTED };
    }

    if (this._joinedPKRooms[roomId]) {
      this._logger.warn(RCLoggerTag.L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: 'Join the room repeatedly',
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    const livingType = RCLivingType.VIDEO; // signal 不处理 livingType 故写死 RCLivingType.VIDEO

    // 加入副房间时，携带主房间 Id，用于在房间内区分人员身份
    const innerUserData = {
      extra: `{"roomId": "${this._mainRoomId}"}`,
    };

    const room = new RCLivingRoom(this._context, this._runtime, roomId, this._service, this._initOptions, livingType, false, false, this._clientSessionId);

    const { code, data } = await room.__innerInit(RTCMode.LIVE, undefined, livingType, innerUserData);

    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);

      return { code: code as any };
    }

    // 回调主直播房间，已加入 PK 房间
    this._joinedPKRooms[roomId] = room;
    await this._onJoinedPKRoom(roomId, traceId!);

    this._logger.info(RCLoggerTag.L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), traceId);

    return {
      room, code: RCRTCCode.SUCCESS, userIds: room.getRemoteUserIds(), tracks: room.getRemoteTracks(),
    };
  }

  /**
   * 退出副房间
   * @param room 要退出的副房间的 room 实例
   * @param isQuitPK 是否要结束连麦
   */
  public async leaveOtherRoom(room: RCLivingRoom, isQuitPK?: boolean): Promise<{code: RCRTCCode}> {
    const traceId = this._logger.createTraceId()!;
    this._logger.info(RCLoggerTag.L_APP_CALL_LEAVE_PK_ROOM_O, undefined, traceId);
    return this._invoker.push(this.createLeaveOtherRoomCommand(room, isQuitPK, traceId));
  }

  /**
   * 获取连麦信息
   * @param roomId 连麦房间的 roomId
   */
  public getPKInfo(roomId: string) {
    return this._PKInfo[roomId];
  }

  /**
   * 获取所有连麦信息
   */
  public getAllPKInfo() {
    return this._PKInfo;
  }

  /**
   * 获取已加入的副房间
   */
  public getJoinedPKRooms(): { [roomId: string]: RCLivingRoom } {
    return this._joinedPKRooms;
  }
}
