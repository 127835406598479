import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RCRTCLiveRole } from '../enums/RCRTCLiveRole';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { BaseCommand, CommandPriority, ICommandResult } from './BaseCommand';
import { CommandExecuteContext } from './CommandExecuteContext';
import { dealLeftUsers } from './helper';

export type StateMsgContent = { users: {
  userId: string,
  /**
   * 状态值，其中
   * * `0`: 进入房间
   * * `1`: 退出房间
   * * `2`: 用户离线，当作离开房间处理
   */
  state: '0' | '1' | '2',
  /**
   * 角色切换类型，0 代表非切换身份发生的加入、退出房间行为
   */
  switchRoleType: RCRTCLiveRole | 0,
  /**
   * 加房间的身份标识，保存主房间 roomId
   */
  extra?: {[key: string]: string}
}[] }

/**
 * RCRTC:State 消息解析结果
 */
export interface IParseUserStateRes {
  /**
   * 新加入人员 ID 列表
   */
  joined: string[]
  /**
   * 退出人员 ID 列表
   */
  left: string[]
  /**
   * 通过观众升级成为主播的主播 ID 列表
   */
  upgrade: string[]
  /**
   * 由主播降级为观众的人员 ID 列表
   */
  downgrade: string[]
}

export class ParseUserStateCommand extends BaseCommand<IParseUserStateRes> {
  constructor(
    private msgContent: StateMsgContent,
    private traceId: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.PARSE_USERSTATE;
  }

  get priority(): CommandPriority {
    return CommandPriority.NORMAL;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult<IParseUserStateRes>> {
    const { logger } = executeCtx;
    /**
     * 主动加入房间
     */
    const joined: string[] = [];
    /**
     * 主动退出房间
     */
    const left: string[] = [];
    /**
     * 观众升级为主播加入房间
     */
    const upgrade: string[] = [];
    /**
     * 主播降级为观众退出房间
     */
    const downgrade: string[] = [];

    const res = {
      joined, left, upgrade, downgrade,
    };

    const { users } = this.msgContent;
    if (users.length === 0) {
      return { code: RCRTCCode.SUCCESS, data: res };
    }

    /**
     * 过滤掉副房间身份的人员
     */
    for (let index = 0; index < users.length; index++) {
      const user = users[index];
      // 加入房间时
      if (user.extra && user.extra.roomId !== store.roomId) {
        // TODO return 存疑
        return { code: RCRTCCode.SUCCESS, data: res };
      }
      // 退出房间时
      if (Number(user.state) === 1 && !store.getRemoteUserIds().includes(user.userId)) {
        // TODO return 存疑
        return { code: RCRTCCode.SUCCESS, data: res };
      }
    }

    users.forEach((item) => {
      const { userId } = item;
      if (Number(item.state) === 0) {
        // 对端 im 重连之后调加入房间信令获取最新数据，服务会给本端下发“对端加入房间”的消息，本端内存已包含对端人员，所以需过滤掉
        const resArr = store.getResourcesByUserId(userId);
        if (!resArr) {
          item.switchRoleType ? upgrade.push(userId) : joined.push(userId);
          logger.info(RCLoggerTag.L_PARSE_USERSTATE_COMMAND_R, JSON.stringify({
            userId,
            msg: `user ${item.switchRoleType ? 'upgrade' : 'joined'}`,
          }), this.traceId);
        }
        store.setResourcesByUserId(userId, resArr || []);
      } else {
        logger.info(RCLoggerTag.L_PARSE_USERSTATE_COMMAND_R, JSON.stringify({
          userId,
          msg: `user ${item.switchRoleType ? 'downgrade' : 'left'}`,
        }), this.traceId);
        item.switchRoleType ? downgrade.push(userId) : left.push(userId);
      }
    });

    const allLeft = [...left, ...downgrade];

    // 用户离开房间时，自动退订对方资源
    if (allLeft.length) {
      await dealLeftUsers(allLeft, executeCtx, store, invoker, this.traceId);
    }

    return { code: RCRTCCode.SUCCESS, data: res };
  }
}
