import { BasicLogger } from '@rongcloud/engine';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';

export default class RC3AnoiseTrack {
  static workletModule = 'https://cdn.ronghub.com/plugin-rtc/wasm/5.0.0-alpha.2/process-worklet.js'

  static workletWasm = 'https://cdn.ronghub.com/plugin-rtc/wasm/5.0.0-alpha.2/AudioProcessing.wasm'

  static isOpen:boolean = false

  /**
   * 该函数接受一个布尔值作为参数，如果没有传递参数，该函数将默认为 true
   * @param {boolean} [isOpen=true] - 轨道是否开放。
   */
  static setStatus(isOpen: boolean = true) {
    RC3AnoiseTrack.isOpen = isOpen;
  }

  /**
   * 它设置 worklet 模块和 wasm。
   * @param {string} module - 将用于创建工作集的模块的名称。
   * @param {string} wasm - Rust 编译器生成的 wasm 文件。
   */
  static setWroklet(module: string, wasm: string) {
    RC3AnoiseTrack.workletModule = module;
    RC3AnoiseTrack.workletModule = wasm;
  }

  /**
   * 它创建一个 AudioContext，从输入流创建一个 MediaStreamSource，
   * 创建一个 MediaStreamDestination，创建一个 AudioWorkletNode，并将节点连接在一起。
   * @param {MediaStream} audioStream - 媒体流
   * @returns 一个媒体流对象。
   */
  static async transformStreamTrack(audioStream: MediaStream, logger: BasicLogger): Promise<{track: MediaStreamTrack, stop: Function}> {
    /* 如果开关未打开则直接返回 音轨。 */
    if (!RC3AnoiseTrack.isOpen) {
      return new Promise((resolve) => {
        resolve({
          track: audioStream?.getAudioTracks()[0],
          stop: () => {},
        });
      });
    }

    // 创建 AudioContext 并设定采集频率
    let audioCxt = new AudioContext({ sampleRate: 44100 });
    // 创建资源
    let renoise = audioCxt.createMediaStreamSource(audioStream);
    // 最终发布的音频载体
    let destination = audioCxt.createMediaStreamDestination();
    // 始始化 AAAnoiseWorklet 并设置为 null, 为后续垃圾回收准备
    let AAAnoiseWorklet: any = null;
    let AAAnoiseNode: any = null;
    AAAnoiseWorklet = await audioCxt.audioWorklet.addModule(RC3AnoiseTrack.workletModule).then(() => {
      logger.info(RCLoggerTag.L_RTC_3ANOISE_NODE_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        message: { msg: 'addModule RC3AnoiseTrack.workletModule success' },
      }));
      return fetch(RC3AnoiseTrack.workletWasm, {
        cache: 'force-cache',
        credentials: 'same-origin',
      }).then((response) => response.arrayBuffer()).catch((error) => {
        throw new Error(error.message);
      });
    }).then((buffer) => {
      AAAnoiseNode = new AudioWorkletNode(audioCxt, 'process-worklet', {
        numberOfInputs: 1,
        numberOfOutputs: 1,
        outputChannelCount: [1],
        processorOptions: {
          binary: buffer,
        },
      });
      return AAAnoiseNode;
    }).catch((err) => {
      logger.info(RCLoggerTag.L_RTC_3ANOISE_NODE_E, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        message: { msg: err.message },
      }));
    });

    if (!AAAnoiseWorklet) {
      return new Promise((resolve) => {
        resolve({
          track: audioStream?.getAudioTracks()[0],
          stop: () => {},
        });
      });
    }

    renoise.connect(AAAnoiseWorklet).connect(destination);
    let track = destination?.stream?.getAudioTracks()[0];
    let stop = () => {
      logger.info(RCLoggerTag.L_RTC_3ANOISE_NODE_STOP_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        message: { msg: 'call stop RC3AnoiseTrack.workletModule' },
      }));
      AAAnoiseWorklet?.port.postMessage({ type: 'stop', msg: '' });
      setTimeout(() => {
        const originTrack = audioStream?.getAudioTracks()[0];
        originTrack?.stop();

        audioCxt?.suspend();
        audioCxt?.close();

        destination?.disconnect();
        AAAnoiseWorklet?.disconnect();
        renoise?.disconnect();

        audioCxt = <any>null;
        AAAnoiseWorklet = null;
        destination = <any>null;
        renoise = <any>null;
        AAAnoiseNode = null;
        audioStream = <any>null;
        track?.stop();
        track = <any>null;
        stop = <any>null;
      }, 200);
    };

    return { track, stop };
  }
}
