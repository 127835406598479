import {
  IRuntime,
  ErrorCode,
  RCConnectionStatus,
} from '@rongcloud/engine';
import {
  IInnerRCRTCStateReport, ISendTrackState, IRecvTrackState, IR3R4BaseData, IR3TackData, IR4TackData,
} from './interfaces';
import { TrackState } from './enums/inner/TrackState';
import { RCMediaType } from './enums/RCMediaType';
import { browserInfo, getUUID22 } from '../helper';
import RCAbstractRoom from './room/RCAbstractRoom';
import { STAT_NONE } from './constants';
import RCAudienceLivingRoom from './room/RCAudienceLivingRoom';
import { PolarisRole } from './enums/inner/PolarisRole';
import { RTCContext } from './codec/RTCContext';
import { RCSendCode } from './enums/RCPolarisReporter';

export default class PolarisReporter {
  constructor(
    private readonly _context: RTCContext,
    private readonly _runtime: IRuntime,
    private readonly _roomId: string,
    private readonly _crtRTCRoom: RCAbstractRoom | RCAudienceLivingRoom,
    private readonly _userRole: PolarisRole = PolarisRole.MeetingOrAnchor, // 会议模式下为 1 | 直播模式下 主播为 1 观众为 2
  ) {}

  private async _send(report: string): Promise<RCSendCode> {
    if (this._context.getConnectionStatus() !== RCConnectionStatus.CONNECTED) {
      return RCSendCode.NOT_REPORT;
    }
    const rCSendCode = await this._context.setRTCState(this._roomId, report);
    if (rCSendCode === ErrorCode.SUCCESS) {
      return RCSendCode.REPORT_SUCCESS;
    }
    return RCSendCode.REPORT_FAIL;
  }

  private _getClientID() {
    const key = 'uuid';
    let uuid = this._runtime.localStorage.getItem(key);
    if (!uuid) {
      uuid = getUUID22();
      this._runtime.localStorage.setItem(key, uuid);
    }
    return uuid;
  }

  /**
   * 小流需去掉 _tiny，小流 resourceId 为 userId_tag_mediaType_tiny
   */
  private _getRealResourceId(resourceId: string): string {
    let realResourceId = resourceId;
    const tinyIndex = resourceId.indexOf('_tiny');
    tinyIndex > -1 && (realResourceId = resourceId.slice(0, tinyIndex));
    return realResourceId;
  }

  /**
   * 生成北极星上报的 trackId
   * @param resourceId userId_11_1_tiny 改为 userId_11_tiny_video
   */
  private _getPolarisTrackId(resourceId: string) {
    let polarisTrackId = '';
    const arr = resourceId.split('_');
    if (resourceId.includes('_tiny')) {
      const mediaSize = arr.pop();
      const mediaType = (parseInt(arr.pop()!) === RCMediaType.AUDIO_ONLY) ? 'audio' : 'video';
      const tag = arr.pop();
      const userId = arr.join('_');
      polarisTrackId = [userId, tag, mediaSize, mediaType].join('_');
    } else {
      const mediaType = (parseInt(arr.pop()!) === RCMediaType.AUDIO_ONLY) ? 'audio' : 'video';
      const tag = arr.pop();
      const userId = arr.join('_');
      polarisTrackId = [userId, tag, mediaType].join('_');
    }
    return polarisTrackId;
  }

  public async sendR3R4Data(data: IInnerRCRTCStateReport): Promise<RCSendCode> {
    const { iceCandidatePair, senders, receivers } = data;
    /**
     * 上下行 track 包含的公共字段
     */
    const baseData: IR3R4BaseData = {
      bitrateSend: iceCandidatePair?.bitrateSend || STAT_NONE,
      bitrateRecv: iceCandidatePair?.bitrateRecv || STAT_NONE,
      networkType: iceCandidatePair?.networkType || 'unknown',
      rtt: iceCandidatePair?.rtt || STAT_NONE,
      localAddress: `${iceCandidatePair?.IP || STAT_NONE}:${iceCandidatePair?.port}`,
      remoteAddress: `${iceCandidatePair?.remoteIP}:${iceCandidatePair?.remotePort}`,
      receiveBand: iceCandidatePair?.availableIncomingBitrate || STAT_NONE,
      sendBand: iceCandidatePair?.availableOutgoingBitrate || STAT_NONE,
      packetsLost: iceCandidatePair?.totalPacketsLost || STAT_NONE,
      deviceId: this._context.getCurrentId(),
    };

    let r3 = `R3\t${baseData.bitrateSend}\t-1\t-1\t-1\t${baseData.networkType}\t${
      baseData.rtt}\t${baseData.localAddress}\t${baseData.receiveBand}\t${
      baseData.sendBand}\t${baseData.packetsLost}\t${baseData.deviceId}\r`;

    let r4 = `R4\t${baseData.bitrateRecv}\t-1\t-1\t-1\t${baseData.networkType}\t${
      baseData.rtt}\t${baseData.localAddress}\t${baseData.receiveBand}\t${
      baseData.sendBand}\t${baseData.packetsLost}\t${baseData.deviceId}\r`;

    /**
     * 北极星上报 sender tracks 中的字段
     */
    const R3TrackData: IR3TackData[] = senders.map((item: ISendTrackState): IR3TackData => {
      const {
        trackId: resourceId, audioLevel, samplingRate, bitrate,
        packetsLostRate, frameRate, frameWidth, frameHeight,
        googRenderDelayMs, jitter, nackCount, pliCount,
        rtt, googFirsSent, encoderImplementation,
      } = item;

      const trackId = this._getPolarisTrackId(resourceId);

      /**
       * 小流需去掉 _tiny
       */
      const realResourceId = this._getRealResourceId(resourceId);

      return {
        trackId,
        googCodecName: encoderImplementation || String(STAT_NONE),
        audioLevel: (audioLevel || audioLevel === 0) ? audioLevel : STAT_NONE,
        bitrate: (bitrate || bitrate === 0) ? bitrate : STAT_NONE,
        packetsLostRate: (packetsLostRate || packetsLostRate === 0) ? packetsLostRate : STAT_NONE,
        frameRate: frameRate || STAT_NONE,
        resolution: (frameWidth && frameHeight) ? `${frameWidth} * ${frameHeight}` : `${STAT_NONE}`,
        jitter: jitter || STAT_NONE,
        nackCount: (nackCount || nackCount === 0) ? nackCount : STAT_NONE,
        pliCount: (pliCount || pliCount === 0) ? pliCount : STAT_NONE,
        rtt: rtt || STAT_NONE,
        googFirsSent,
        samplingRate,
        googRenderDelayMs,
        encoderImplementation: encoderImplementation || String(STAT_NONE),
        trackState: this._crtRTCRoom.getLocalTrack(realResourceId)?.isLocalMuted() ? TrackState.DISABLE : TrackState.ENABLE,
      };
    });

    /**
     * 北极星上报 received tracks 中的字段
     */

    const R4TrackData: IR4TackData[] = receivers.filter(
      // unified-plan 下 inactive 的数据会继续携带 ssrc，导致无 trackId
      (item) => !!item.trackId,
    ).map((item: IRecvTrackState): IR4TackData => {
      const {
        trackId: resourceId, audioLevel, samplingRate, bitrate,
        packetsLostRate, frameRate, frameWidth, frameHeight,
        googRenderDelayMs, jitter, nackCount, pliCount,
        rtt, googFirsReceived, codecImplementationName,
      } = item;

      const trackId = this._getPolarisTrackId(resourceId);

      /**
       * 小流需去掉 _tiny
       */
      const realResourceId = this._getRealResourceId(resourceId);

      return {
        trackId,
        googCodecName: codecImplementationName || String(STAT_NONE),
        audioLevel: (audioLevel || audioLevel === 0) ? audioLevel : STAT_NONE,
        bitrate: (bitrate || bitrate === 0) ? bitrate : STAT_NONE,
        packetsLostRate: (packetsLostRate || packetsLostRate === 0) ? packetsLostRate : STAT_NONE,
        frameRate: frameRate || STAT_NONE,
        resolution: (frameWidth && frameHeight) ? `${frameWidth} * ${frameHeight}` : `${STAT_NONE}`,
        jitter: jitter || STAT_NONE,
        nackCount: (nackCount || nackCount === 0) ? nackCount : STAT_NONE,
        pliCount: (pliCount || pliCount === 0) ? pliCount : STAT_NONE,
        rtt: rtt || STAT_NONE,
        googFirsReceived,
        samplingRate,
        googRenderDelayMs,
        codecImplementationName: codecImplementationName || String(STAT_NONE),
        trackState: this._crtRTCRoom.getRemoteTrack(realResourceId)?.isLocalMuted() ? TrackState.DISABLE : TrackState.ENABLE,
      };
    });

    let senderCode: RCSendCode = RCSendCode.REPORT_FAIL;
    r3 += R3TrackData.map((item) => `${item.trackId}\t${item.googCodecName}\t${item.audioLevel}\t${item.samplingRate}\t${
      item.bitrate}\t${item.packetsLostRate}\t${item.frameRate}\t${item.resolution}\t${
      item.googRenderDelayMs}\t${item.jitter}\t${item.nackCount}\t${item.pliCount}\t${
      item.rtt}\t${item.googFirsSent}\t${item.encoderImplementation}\t${item.trackState}`).join('\n');
    if (data.senders.length) {
      senderCode = await this._send(`${r3}\r${this._userRole}`);
    }

    let receiverCode: RCSendCode = RCSendCode.REPORT_FAIL;
    r4 += R4TrackData.map((item) => `${item.trackId}\t${item.googCodecName}\t${item.audioLevel}\t${item.samplingRate}\t${
      item.bitrate}\t${item.packetsLostRate}\t${item.frameRate}\t${item.resolution}\t${
      item.googRenderDelayMs}\t${item.jitter}\t${item.nackCount}\t${item.pliCount}\t${
      item.rtt}\t${item.googFirsReceived}\t${item.codecImplementationName}\t${item.trackState}`).join('\n');
    if (data.receivers.length) {
      receiverCode = await this._send(`${r4}\r${this._userRole}`);
    }

    if (senderCode !== RCSendCode.REPORT_SUCCESS && receiverCode !== RCSendCode.REPORT_SUCCESS) {
      return RCSendCode.REPORT_FAIL;
    }
    return RCSendCode.REPORT_SUCCESS;
  }

  /**
   * 加入房间
   */
  public sendR1() {
    const rtcVersion = __VERSION__;
    const imVersion = this._context.getCoreVersion();
    const platform = 'web';
    const pcName = (navigator as any).userAgentData?.platformn || navigator.platform;
    const pcVersion = STAT_NONE;
    const browserName = browserInfo.browser;
    const browserVersion = browserInfo.version;
    const deviceId = this._getClientID();
    const r1 = `R1\t${rtcVersion}\t${imVersion}\t${platform}\t${pcName}\t${pcVersion}\t${browserName}\t${browserVersion}\t${deviceId}\t${this._userRole}`;
    this._send(r1);
  }

  /**
   * RTC 和 LIVE 发布、取消发布
   * RTC 订阅、取消订阅
   */
  public sendR2(action: string, status: string, trackIds: string[]) {
    const deviceId = this._getClientID();
    const r2 = `R2\t${action}\t${status}\t${deviceId}\r${trackIds.join('\t')}\r${this._userRole}`;
    this._send(r2);
  }
}
