/**
 * Safari stats
 * 取不到上行丢包数，无法计算上行丢包率，无上行网络抖动
 * 无帧率
 * candidate 无本地网络类型、本端地址
 */
import { STAT_NONE } from '../../constants';
import { IInnerRCRTCStateReport } from '../../interfaces';
import { handleAudioLevel } from '../helper';
import AbstractStatParser from './AbstractStatParser';

export default class RTCReportParser extends AbstractStatParser {
  public formatRCRTCStateReport(stats: { [key: string]: any }) {
    const reports: IInnerRCRTCStateReport = {
      senders: [],
      receivers: [],
    } as any;

    // 当次报告创建时的时间戳
    const timestamp: number = Math.floor(stats.RTCPeerConnection.timestamp);
    reports.timestamp = timestamp;

    const keys = Object.keys(stats);

    // 总丢包数
    let totalPacketsLost = 0;

    // 上行码率总和
    let bitrateSend = 0;

    // 解析上行媒体流数据: RTCOutboundRTPVideoStream | RTCOutboundRTPAudioStream
    const outboundKeys = keys.filter((key) => /^RTCOutboundRTP(Video|Audio)Stream_/.test(key));
    outboundKeys.forEach((key) => {
      // 本端输出数据
      const outboundInfo = stats[key];
      if (this._sdpSemantics === 'unified-plan' && !this.isValidSender(outboundInfo)) {
        return;
      }

      const resourceId = this._store?.getTrackIdBySSRC(outboundInfo.ssrc)!;

      const {
        mediaType: kind,
        transportId, remoteId,
        packetsSent, bytesSent, trackId,
        encoderImplementation, pliCount, nackCount, firCount,
      } = outboundInfo;

      if (!trackId) {
        return;
      }

      const { audioLevel, frameHeight, frameWidth } = stats[trackId];

      let bitrate = this.updateBytesSent(resourceId, bytesSent, timestamp);

      if (bitrate < 0) {
        bitrate = 0;
      }

      // 总和累加
      bitrateSend += bitrate;

      reports.senders.push({
        trackId: resourceId,
        kind,
        packetsLostRate: null,
        remoteResource: false,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
        frameWidth,
        frameHeight,
        frameRate: null,
        bitrate,
        jitter: null,
        rtt: null,
        encoderImplementation,
        pliCount,
        nackCount,
        googFirsSent: STAT_NONE,
        samplingRate: STAT_NONE,
        googRenderDelayMs: STAT_NONE,
        trackState: STAT_NONE,
      });
    });

    // 下行码率总和
    let bitrateRecv = 0;

    // 下行流数据解析
    const inboundKeys = keys.filter((key) => /^RTCInboundRTP(Video|Audio)Stream_/.test(key));
    inboundKeys.forEach((key) => {
      const inboundInfo = stats[key];
      if (this._sdpSemantics === 'unified-plan' && !this.isValidReceiver(inboundInfo)) {
        return;
      }

      const {
        trackId, packetsLost, packetsReceived, jitter, bytesReceived,
        mediaType: kind,
        nackCount, pliCount,
      } = inboundInfo;

      const resourceId = this._store?.getTrackIdBySSRC(inboundInfo.ssrc)!;

      if (!trackId) {
        return;
      }

      const { frameHeight, frameWidth, audioLevel } = stats[trackId];

      totalPacketsLost += packetsLost;

      const packetsLostRate = this.updateReceiverPacketsLost(resourceId, packetsLost, packetsReceived);
      let bitrate = this.updateBytesRecv(resourceId, bytesReceived, timestamp);

      if (bitrate < 0) {
        bitrate = 0;
      }

      bitrateRecv += bitrate;

      reports.receivers.push({
        trackId: resourceId,
        kind,
        packetsLostRate,
        remoteResource: true,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
        frameWidth,
        frameHeight,
        frameRate: null,
        bitrate,
        jitter,
        codecImplementationName: null,
        nackCount,
        pliCount,
        rtt: null,
        samplingRate: STAT_NONE,
        googFirsReceived: STAT_NONE,
        googRenderDelayMs: STAT_NONE,
        trackState: STAT_NONE,
      });
    });

    // 解析本端/远端 IP、Port 数据
    const transportKey = keys.filter((key) => /^RTCTransport_/.test(key))[0];
    if (transportKey) {
      const rtcTransport = stats[transportKey];
      const { selectedCandidatePairId } = rtcTransport;

      if (selectedCandidatePairId) {
        const iceCandidatePair = stats[selectedCandidatePairId];
        const {
          availableOutgoingBitrate,
          // 下行带宽只在有下行资源时有值
          availableIncomingBitrate,
          currentRoundTripTime: rtt,
          localCandidateId,
          remoteCandidateId,
        } = iceCandidatePair;

        const localCandidate = stats[localCandidateId];
        const { address: IP, port } = localCandidate;
        const remoteCandidate = stats[remoteCandidateId];
        const { address: remoteIP, port: remotePort, protocol } = remoteCandidate;

        reports.iceCandidatePair = {
          IP: IP || null,
          port,
          networkType: null,
          remoteIP,
          remotePort,
          protocol,
          bitrateRecv,
          bitrateSend,
          rtt: rtt * 1000,
          availableOutgoingBitrate,
          availableIncomingBitrate,
          totalPacketsLost,
        };
        // 给下行 rtt 赋值
        reports.receivers.forEach((item) => {
          item.rtt = rtt;
        });
      }
    }
    return reports;
  }

  public getAudioLevelList(stats: { [key: string]: any }) {
    const audioLevelList: {
      trackId: string,
      audioLevel: number | null
    }[] = [];
    const keys = Object.keys(stats);

    // 解析上行媒体流数据: RTCOutboundRTPVideoStream | RTCOutboundRTPAudioStream
    const outboundKeys = keys.filter((key) => /^RTCOutboundRTP(Video|Audio)Stream_/.test(key));
    outboundKeys.forEach((key) => {
      // 本端输出数据
      const outboundInfo = stats[key];
      if (this._sdpSemantics === 'unified-plan' && !this.isValidSender(outboundInfo)) {
        return;
      }

      const { trackId, mediaType: kind } = outboundInfo;
      if (kind === 'video') {
        return;
      }

      const resourceId = this._store?.getTrackIdBySSRC(outboundInfo.ssrc)!;
      const audioLevel = stats[trackId];

      audioLevelList.push({
        trackId: resourceId,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
      });
    });

    // 下行流数据解析
    const inboundKeys = keys.filter((key) => /^RTCInboundRTP(Video|Audio)Stream_/.test(key));
    inboundKeys.forEach((key) => {
      const inboundInfo = stats[key];
      if (this._sdpSemantics === 'unified-plan' && !this.isValidReceiver(inboundInfo)) {
        return;
      }

      const { trackId, mediaType: kind } = inboundInfo;

      if (!trackId || kind === 'video') {
        return;
      }

      const { audioLevel } = stats[trackId];
      const resourceId = this._store?.getTrackIdBySSRC(inboundInfo.ssrc)!;

      audioLevelList.push({
        trackId: resourceId,
        audioLevel: (audioLevel || audioLevel === 0) ? handleAudioLevel(audioLevel) : null,
      });
    });

    return audioLevelList;
  }
}
