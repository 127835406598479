import { ErrorCode } from '@rongcloud/engine';
import { int64ToTimestamp } from '../../helper';
import { RTCContext } from '../codec/RTCContext';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { PullRoomStatusEvent } from '../enums/PullRoomStatusEvent';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { CommandEvent, CommandExecuteContext } from './CommandExecuteContext';
import {
  handleFullRoomData, transPullDataToResMsgCont, transPullDataToStateMsgCont, transPullFullUsersData,
} from './helper';
import { ParseRemoteResCommand } from './ParseRemoteResCommand';
import { ParseUserStateCommand } from './ParseUserStateCommand';

export class PullRTCRoomStatusCommand extends BaseCommand {
  constructor(
    private _roomId: string,
    private _traceId: string,
    private _context: RTCContext,
    private _targetPullVersion?: number,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.PULL_RTCROOM_STATUS;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger } = executeCtx;
    const localVersion = store.getRoomStatusVersion();

    logger.info(RCLoggerTag.L_PULL_ROOM_STATUS_T, JSON.stringify({
      roomId: this._roomId,
      localVersion,
    }), this._traceId);

    if (this._targetPullVersion && this._targetPullVersion <= localVersion) {
      logger.info(RCLoggerTag.L_PULL_ROOM_STATUS_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        msg: 'the roomData has been pulled',
        localVersion,
        targetPullVersion: this._targetPullVersion,
      }), this._traceId);
      return { code: ErrorCode.SUCCESS };
    }

    const { code, data } = await this._context.pullRTCRoomStatus(this._roomId, localVersion);
    if (code !== ErrorCode.SUCCESS) {
      logger.info(RCLoggerTag.L_PULL_ROOM_STATUS_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), this._traceId);
      return { code };
    }
    const {
      bFullStatus, version: newVersion, usersData, roomStatus,
    } = data!;

    logger.info(RCLoggerTag.L_PULL_ROOM_STATUS_R, JSON.stringify({
      bFullStatus,
      version: int64ToTimestamp(newVersion),
      usersData,
      roomStatus,
    }), this._traceId);

    store.setRoomStatusVersion(newVersion);

    /**
     * 处理全量数据
     */
    if (bFullStatus) {
      const { urisData, CDNUris } = transPullFullUsersData(usersData);
      await handleFullRoomData(urisData, store, executeCtx, invoker, CDNUris, this._traceId);
      return { code };
    }

    /**
     * 处理增量列表数据，默认不合并人员、资源变动
     */
    for (let index = 0; index < roomStatus.length; index++) {
      const item = roomStatus[index];
      if (item.event === PullRoomStatusEvent.RESOURCECHANGE) {
        const resData = transPullDataToResMsgCont(item);
        /**
         * 无 uris 或 cdn_uris 时，无需继续执行解析资源数据
         */
        if (!resData) {
          continue;
        }
        const { content, userId } = resData;
        if (userId === store.crtUserId) {
          continue;
        }

        await new ParseRemoteResCommand(
          content,
          RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
          userId,
          this._traceId,
        ).execute(executeCtx, store, invoker);
      } else {
        /**
         * 转化拉到的房间数据为通用解析人员方法需要的格式
         * 处理解析完的结果
         */
        const content = transPullDataToStateMsgCont(item);
        const { code, data } = await new ParseUserStateCommand(content as any, this._traceId).execute(executeCtx, store, invoker);
        executeCtx.emit(CommandEvent.USER_STATE_CHANGE, data);
      }
    }
    return { code };
  }
}
