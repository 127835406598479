import { RCResolution } from './RCResolution';

type BitrateConf = { width: number, height: number, maxBitrate: number, minBitrate: number }

// export const RongRTCVideoBitrate: { [key: RCResolution]: BitrateConf } = {
export const RongRTCVideoBitrate = {
  [RCResolution.W176_H132]: {
    width: 176, height: 132, maxBitrate: 150, minBitrate: 80,
  },
  [RCResolution.W176_H144]: {
    width: 176, height: 144, maxBitrate: 150, minBitrate: 80,
  },
  [RCResolution.W180_H180]: {
    width: 180, height: 180, maxBitrate: 200, minBitrate: 100,
  },
  [RCResolution.W240_H180]: {
    width: 240, height: 180, maxBitrate: 240, minBitrate: 120,
  },
  [RCResolution.W240_H240]: {
    width: 240, height: 240, maxBitrate: 280, minBitrate: 120,
  },
  [RCResolution.W256_H144]: {
    width: 256, height: 144, maxBitrate: 240, minBitrate: 120,
  },
  [RCResolution.W320_H180]: {
    width: 320, height: 180, maxBitrate: 280, minBitrate: 120,
  },
  [RCResolution.W320_H240]: {
    width: 320, height: 240, maxBitrate: 400, minBitrate: 120,
  },
  [RCResolution.W360_H360]: {
    width: 360, height: 360, maxBitrate: 520, minBitrate: 140,
  },
  [RCResolution.W480_H360]: {
    width: 480, height: 360, maxBitrate: 650, minBitrate: 150,
  },
  [RCResolution.W480_H480]: {
    width: 480, height: 480, maxBitrate: 800, minBitrate: 180,
  },
  [RCResolution.W640_H360]: {
    width: 640, height: 360, maxBitrate: 800, minBitrate: 180,
  },
  [RCResolution.W640_H480]: {
    width: 640, height: 480, maxBitrate: 900, minBitrate: 200,
  },
  [RCResolution.W720_H480]: {
    width: 720, height: 480, maxBitrate: 1000, minBitrate: 200,
  },
  [RCResolution.W848_H480]: {
    width: 848, height: 480, maxBitrate: 1860, minBitrate: 200,
  },
  [RCResolution.W960_H720]: {
    width: 960, height: 720, maxBitrate: 2000, minBitrate: 250,
  },
  [RCResolution.W1280_H720]: {
    width: 1280, height: 720, maxBitrate: 2200, minBitrate: 250,
  },
  [RCResolution.W1920_H1080]: {
    width: 1920, height: 1080, maxBitrate: 4000, minBitrate: 400,
  },
  [RCResolution.W144_H176]: {
    width: 144, height: 176, maxBitrate: 150, minBitrate: 80,
  },
  [RCResolution.W144_H256]: {
    width: 144, height: 256, maxBitrate: 240, minBitrate: 120,
  },
  [RCResolution.W180_H240]: {
    width: 180, height: 240, maxBitrate: 240, minBitrate: 120,
  },
  [RCResolution.W180_H320]: {
    width: 180, height: 320, maxBitrate: 280, minBitrate: 120,
  },
  [RCResolution.W240_H320]: {
    width: 240, height: 320, maxBitrate: 400, minBitrate: 120,
  },
  [RCResolution.W360_H480]: {
    width: 360, height: 480, maxBitrate: 650, minBitrate: 150,
  },
  [RCResolution.W360_H640]: {
    width: 360, height: 640, maxBitrate: 800, minBitrate: 180,
  },
  [RCResolution.W480_H640]: {
    width: 480, height: 640, maxBitrate: 900, minBitrate: 200,
  },
  [RCResolution.W480_H720]: {
    width: 480, height: 720, maxBitrate: 1000, minBitrate: 200,
  },
  [RCResolution.W480_H848]: {
    width: 480, height: 848, maxBitrate: 1860, minBitrate: 930,
  },
  [RCResolution.W720_H1280]: {
    width: 720, height: 1280, maxBitrate: 2200, minBitrate: 250,
  },
  [RCResolution.W720_H960]: {
    width: 720, height: 960, maxBitrate: 2760, minBitrate: 910,
  },
  [RCResolution.W1080_H1920]: {
    width: 1080, height: 1920, maxBitrate: 4000, minBitrate: 400,
  },
};

/**
 * 分辨率适配
 * @param {number} resolution
 * @returns
 */
const resolutionAdapter = (resolution :number): number => {
  const ResolutionAdapter: any = {
    192: 180,
    368: 360,
    1088: 1080,
  };
  return ResolutionAdapter[resolution] ? ResolutionAdapter[resolution] : resolution;
};

/**
* 向上取最接近的视频分辨率配置
* @param {number} width
* @param {number} height
*/
export const getNearestResolution = (width: number, height: number): BitrateConf => {
  // 分辨率适配
  width = resolutionAdapter(width);
  height = resolutionAdapter(height);

  // 优先精准匹配
  const conf = RongRTCVideoBitrate[`W${width}_H${height}` as RCResolution];
  if (conf) {
    return conf;
  }

  // 不规则分辨率计算最接近的配置
  const area = width * height;
  const confs = Object.keys(RongRTCVideoBitrate)
    .map((key) => RongRTCVideoBitrate[key as RCResolution])
    // 升序
    .sort((item, item2) => item.height * item.width - item2.width * item2.height)
    // 过滤分辨率小于 area 的配置，避免分配带宽不足
    .filter((item) => item.height * item.width >= area);
  // 若 confs 长度为 0 说明分辨率远大于可支持的分辨率配置，取最大配置
  return confs[0] || RongRTCVideoBitrate.W1920_H1080;
};

export const Multiplier: {
  [key: number]: number
} = {
  10: 1,
  15: 1,
  24: 1.5,
  30: 1.5,
};

/**
 * 根据帧率获取码率倍数
 * @param frameRate
 */
export const getBitrateMultiple = (frameRate: number) => {
  let d = Number.MAX_VALUE;
  let rate = 1;
  for (const key in Multiplier) {
    const d2 = Math.abs(frameRate - parseInt(key));
    if (d2 < d) {
      d = d2;
      rate = Multiplier[key];
    }
  }
  return rate;
};
