import { RCRTCCode } from '../../enums/RCRTCCode';
import { IRCTrackBitrate } from '../../interfaces';
import { RCLocalTrack } from '../../tracks/RCLocalTrack';
import { RCRemoteTrack } from '../../tracks/RCRemoteTrack';
import { IOfferInfo, ASdpStrategy } from './ASdpStrategy';
import PlanBSdpBuilder from './PlanBSdpBuilder';

export class PlanBStrategy extends ASdpStrategy {
  private senders: { [trackId: string]: RTCRtpSender } = {}

  private _localTracks: RCLocalTrack[] = []

  addLocalTrack(track: RCLocalTrack): void {
    if (this._localTracks.every((item) => item.getTrackId() !== track.getTrackId())) {
      this._localTracks.push(track);
    }

    const msid = track.getStreamId();
    const msTrack = track.__innerGetMediaStreamTrack()!;
    // 复用 stream 避免多次重复初始化
    const stream = this._outboundStreams[msid] || (this._outboundStreams[msid] = new MediaStream());

    // 清理同类型轨道数据
    stream.getTracks().forEach((track) => {
      track.kind === msTrack.kind && stream.removeTrack(track);
    });
    stream.addTrack(msTrack);

    // addTrack
    const trackId = track.getTrackId();
    const sender = this.senders[trackId];
    if (sender) {
      sender.replaceTrack(msTrack);
    } else {
      // 有流音轨
      this.senders[trackId] = this._peer.addTrack(msTrack, stream);
      // 在 原生的 track 上添加一个不可修改的属性 trackId
      Object.defineProperty(msTrack, 'trackId', {
        value: trackId,
        writable: false,
        enumerable: false,
        configurable: false,
      });
    }
  }

  removeLocalTrack(track: RCLocalTrack): void {
    const index = this._localTracks.findIndex((item) => item === track);
    index >= 0 && this._localTracks.splice(index, 1);

    const trackId = track.getTrackId();
    const sender = this.senders[trackId];
    if (!sender) {
      return;
    }
    sender.replaceTrack(null);
    this._peer.removeTrack(sender);
    delete this.senders[trackId];
  }

  updateSubRemoteTracks(remoteTracks: RCRemoteTrack[]) {
    // plan-b 中订阅与取消订阅不体现在 SDP 数据中，不需要对 peerConnection 做操作
  }

  updateRecvTransceiverMap(trackId: string, transceiver: RTCRtpTransceiver) {
    // plan-b 无需维护 offer 中的订阅关系，offer 只描述上行数据
  }

  /**
   * 指定上行码率范围
   * @param maxBitrate
   * @param minBitrate
   * @param startBitrate
   */
  setBitrate(maxBitrate: number, minBitrate: number, startBitrate?: number): void {
    // this._maxBitrate = maxBitrate;
    // this._minBitrate = minBitrate;
    // this._startBitrate = startBitrate || maxBitrate * 0.7;
  }

  async createOffer(iceRestart: boolean): Promise<IOfferInfo> {
    const offer = await this._peer.createOffer({ iceRestart, offerToReceiveAudio: true, offerToReceiveVideo: true });

    for (const msid in this._outboundStreams) {
      /**
       * 更改 SDP 描述中的 msid
       * 将 MediaStream id 更改为 msid
       * 例: ca5dffd8-d6b2-489d-9e4d-4814321efd38 更改为 1001_11
       */
      const streamId = this._outboundStreams[msid].id;
      offer.sdp = offer.sdp!.replace(new RegExp(streamId, 'g'), msid);
    }

    if (offer.sdp) {
      offer.sdp = this.resetSdp(offer.sdp);
    }

    await this._peer.setLocalDescription(offer);
    return { type: 'offer', semantics: 'plan-b', sdp: offer.sdp! };
  }

  setRemoteAnswer(sdp: string): Promise<RCRTCCode> {
    sdp = this.resetSdp(sdp);
    return super.setRemoteAnswer(sdp);
  }

  // 重置设置 SDP 信息
  protected resetSdp(sdp: string):string {
    const sdpBuilder = new PlanBSdpBuilder(this._logger, sdp);
    // 计算动态码率
    const videoBitrate:IRCTrackBitrate = { max: 0, min: 0 };
    const audioBitrate:IRCTrackBitrate = { max: 0, min: 0 };

    // 计算所以码率的合集。{0 10} & {5, 30} === {0, 30}
    this._localTracks.forEach((item) => {
      const { min, max, start } = item.getBitrate();
      if (item.isAudioTrack()) {
        audioBitrate.max = audioBitrate.max < max ? max : audioBitrate.max;
        audioBitrate.min = audioBitrate.min > min ? min : audioBitrate.min;
        // 选择用户在设置碼率中最大值，如果最大值的最大码率时也设置了 start 就使用用户设置的
        if (audioBitrate.max === max) {
          audioBitrate.start = start;
        } else {
          audioBitrate.start = audioBitrate.max * 0.7;
        }
      }
      if (item.isVideoTrack()) {
        videoBitrate.max = videoBitrate.max < max ? max : videoBitrate.max;
        videoBitrate.min = videoBitrate.min > min ? min : videoBitrate.min;
        // 选择用户在设置碼率中最大值，如果最大值的最大码率时也设置了 start 就使用用户设置的
        if (videoBitrate.max === max) {
          videoBitrate.start = start;
        } else {
          videoBitrate.start = videoBitrate.max * 0.7;
        }
      }
    });

    // 设置音频码率
    sdpBuilder.setAudiosBitrate(audioBitrate);
    // 设置视频码率
    sdpBuilder.setVideosBitrate(videoBitrate);
    return sdpBuilder.stringify();
  }
}
