import {
  IRuntime, validate, isArray, ErrorCode, BasicLogger,
} from '@rongcloud/engine';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from '../tracks/RCRemoteTrack';
import { RCRTCCode } from '../enums/RCRTCCode';
import {
  IPublishedResource, IRCRTCInitOptions, IRCRTCReportListener, IRCRTCTrackEventListener, ISubscribeAttr, IAudioLevelChangeHandler,
} from '../interfaces';

import {
  IBroadcastSubReqBody, IRTCReqHeader, RCMediaService, ICDNUris, IMCUReqHeaders, ICDNPlayUrlReq, getUUID,
} from '../service';
import { R2Action } from '../enums/inner/R2Action';
import { R2Status } from '../enums/inner/R2Status';
import {
  diffPublishResources, getTrackId, getVideoTrackInfo, parseTrackId, transFrameRate, transResolution, isValidResolution,
  isValidFPS, parseAudienceRoomData, getTrackIdFromAttr,
} from '../../helper';
import RCLocalMediaStream from './RCLocalMediaStream';
import { RCStreamType } from '../enums/inner/RCStreamType';
import { RCResolution } from '../enums/RCResolution';
import { RCFrameRate } from '../enums/RCFrameRate';
import { getBitrateMultiple, getNearestResolution } from '../enums/RCRTCResolution';
import { RCLivingType } from '../enums/RCLivingType';
import { RCMediaType } from '../enums/RCMediaType';
import { PolarisRole } from '../enums/inner/PolarisRole';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';
import { push } from '../../async-task-queue';
import RCAudioLevelReport from './RCAudioLevelReport';
import { RCInnerCDNPullKind } from '../enums/RCInnerCDNPullKind';
import { RCInnerCDNPullIsHttps } from '../enums/RCInnerCDNPullIsHttps';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RTCContext } from '../codec/RTCContext';
import { RTCMode } from '../enums/RTCMode';
import { IServerRTCRoomEntry } from '../codec/interface';
import { IMediaServerQualityData, RCRTCResourceAction } from '../logger/IQualityReportData';
import { Store } from '../Store';
import ReportMediaActionLogger from '../logger/QualityLogger';
import PolarisReporter from '../PolarisReporter';

export interface IAudienceRoomEventListener extends IRCRTCTrackEventListener {
  /**
   * 主播加入
   * @param userIds 加入主播的 id 列表
   */
  onAnchorJoin? (userId: string[]): void
  /**
   * 主播退出
   * @param userIds 退出主播的 id 列表
   */
  onAnchorLeave? (userId: string[]): void
  /**
   * 房间内合流发布资源
   * @param tracks 新发布的合流音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
   * @description
   * 当房间内某个主播第一次发布资源时触发
   */
  onTrackPublish? (tracks: RCRemoteTrack[]): void
  /**
   * 房间内取消合流发布资源
   * @param tracks 被取消发布的合流音轨与视轨数据列表
   * @description
   * 当房间内全部主播退出房间时，SDK 内部会取消对资源的订阅，业务层仅需处理 UI 业务
   */
  onTrackUnpublish? (tracks: RCRemoteTrack[]): void
  /**
   * 房间内主播发布资源
   * @param tracks 主播新发布的音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
   */
  onAnchorTrackPublish? (tracks: RCRemoteTrack[]): void
  /**
   * 房间内主播取消发布资源
   * @param tracks 被主播取消发布的音轨与视轨数据列表
   * @description 当资源被取消发布时，SDK 内部会取消对相关资源的订阅，业务层仅需处理 UI 业务
   */
  onAnchorTrackUnpublish? (tracks: RCRemoteTrack[]): void
  /**
   * 房间主播禁用/启用音频
   * @param audioTrack RCRemoteAudioTrack 类实例
   */
  onAudioMuteChange? (audioTrack: RCRemoteAudioTrack): void
  /**
  * 房间主播禁用/启用视频
  * @param videoTrack RCRemoteVideoTrack 类实例对象
  */
  onVideoMuteChange? (videoTrack: RCRemoteVideoTrack): void
  /**
   * 房间内主播把发布的资源推至 CDN
   */
  onCDNInfoEnable? (CDNInfo: {resolution: RCResolution, fps: RCFrameRate}): void
  /**
   * 主播停止推 CDN
   */
  onCDNInfoDisable? (): void
  /**
   * 主播改变推 CDN 的分辨率或帧率
   */
  onCDNInfoChange? (CDNInfo: {resolution: RCResolution, fps: RCFrameRate}): void
}

const tinyConf = { ...transResolution(RCResolution.W176_H144), frameRate: transFrameRate(RCFrameRate.FPS_15) };

/**
 * 观众直播房间类
 * 处理：
 * 1、通知观众房间内 人员变更、资源变更
 * 2、观众订阅、取消订阅资源
 */
export default class RCAudienceLivingRoom {
  private readonly _service: RCMediaService

  /**
   * 主播列表
   */
  private _roomAnchorList: string[] = []

  /**
   * 合流、分流资源
   */
  private _roomRes: {[trackId: string]: IPublishedResource} = {}

  /**
   * 主播分流资源
   */
  private _roomAnchorRes: {[userId: string]: IPublishedResource[]} = {}

  /**
   * 合流、分流 remoteTracks
   */
  private _remoteTracks:{ [trackId: string]: RCRemoteTrack } = {}

  private _appListener: IAudienceRoomEventListener | null = null

  private readonly _pc: RCRTCPeerConnection

  private _subscribedList: ISubscribeAttr[] = []

  private _sessionId: string = ''

  private _destroyed: boolean = false

  /**
   * 北极星上报实例
   */
  protected readonly _polarisReport: PolarisReporter

  /**
   * 音量上报实例
   */
  private readonly _audioLevelReport: RCAudioLevelReport

  /**
   * cdn_uris 资源
   */
  private _CDNUris!: ICDNUris

  private _isPulling: boolean = false

  private _pullTime: number = 0

  private readonly _store: Store

  private _crtUserId!: string

  private readonly _reportMediaActionLogger: ReportMediaActionLogger

  /**
   * track 对应的 mediaServer 信息
   */
  private _trackMediaMap: {[trackId: string]: IMediaServerQualityData[] } = {}

  private readonly _logger: BasicLogger

  constructor(
    private readonly _context: RTCContext,
    private readonly _runtime: IRuntime,
    private readonly _initOptions: IRCRTCInitOptions,
    private readonly _roomId: string,
    private readonly _joinResData: {
      token: string,
      kvEntries: IServerRTCRoomEntry[]
    },
    readonly livingType: RCLivingType,
    protected readonly _clientSessionId: string = getUUID(),
  ) {
    this._logger = this._context.logger;
    this._crtUserId = this._context.getCurrentId();

    // 北极星数据
    this._polarisReport = new PolarisReporter(this._context, this._runtime, this._roomId, this, PolarisRole.Audience);
    this._reportMediaActionLogger = new ReportMediaActionLogger(this._logger, this._roomId, this._clientSessionId, this._crtUserId);
    this._service = new RCMediaService(this._runtime, this._context, this._initOptions.mediaServer, this._initOptions.timeout);

    const store = this._store = new Store(this._logger, _clientSessionId, _context.getAppkey(), _roomId, this._crtUserId, RTCMode.LIVE);

    const peer = this._pc = new RCRTCPeerConnection(this._logger, this._reTryExchange.bind(this), this._crtUserId, store, this._polarisReport, true);
    this._audioLevelReport = new RCAudioLevelReport(this._store, peer);
    peer.on(RCRTCPeerConnection.__INNER_AUDIOLEVEL_CHANGE__, this._audioLevelReport.audioLevelReport, this._audioLevelReport);

    this._polarisReport.sendR1();

    // 注册监听 rtc_ntf 信令数据，解码后拉取指定房间数据
    this._context.registerRTCSignalListener(async (buffer: Uint8Array) => {
      // 1. 拉取数据，非本房间可不拉取
      // 2. 拉取完成后调用原通知方法 this.singalDataChange(_roomId)
      const { time, type, roomId } = this._context.decodeRtcNotify(buffer);
      switch (type) {
        case 1:
          // RTC KV拉取
          this._startPull(roomId, time);
          break;
      }
    });

    // 解析服务返回的 KV 数据，并赋值房间内数据
    this._setInitData();
  }

  private async _startPull(roomId: string, timestamp: number) {
    if (this._isPulling) {
      return;
    }
    this._isPulling = true;
    const pulledUpTime = this._pullTime;
    if (pulledUpTime > timestamp) { // 已经拉取过，不再拉取
      this._isPulling = false;
      return;
    }

    const traceId = this._logger.createTraceId()!;
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_T, `pullTime: ${this._pullTime}`, traceId);

    const { code, data } = await this._context.pullRTCRoomEntry(roomId);
    if (code === ErrorCode.SUCCESS) {
      this._isPulling = false;
      this._pullTime = data!.syncTime || 0;
      const kvEntries = data?.kvEntries || [];
      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_R, `status: ${RCLoggerStatus.SUCCESSED}, kvEntries: ${JSON.stringify(kvEntries)}`, traceId);
      this.singalDataChange(kvEntries, roomId, traceId);
    }
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_R, `status: ${RCLoggerStatus.FAILED}, code: ${code}`, traceId);
  }

  /**
   * 解析服务端返回的 KV 数据，赋值 room 内数据
   */
  private _setInitData() {
    const {
      sessionId, remoteUserIds, remoteRTCUris, remoteMUCUris, remoteTracks, CDNUris,
    } = parseAudienceRoomData(this._roomId, this._joinResData.kvEntries, this._logger);
    /**
     * session 赋值
     */
    this._sessionId = sessionId;
    /**
     * 主播列表赋值
     */
    this._roomAnchorList = remoteUserIds;
    /**
     * RTC、MCU tracks 赋值
     */
    remoteTracks.forEach((track) => {
      this._remoteTracks[track.getTrackId()] = track;
    });
    /**
     * _CDNUris 赋值
     */
    this._CDNUris = CDNUris;

    /**
     * 房间内 RTC 资源赋值
     */
    remoteRTCUris.forEach((uri: IPublishedResource) => {
      const userId = uri.msid.split('_')[0];
      if (this._roomAnchorRes[userId]) {
        this._roomAnchorRes[userId].push(uri);
      } else {
        this._roomAnchorRes[userId] = [uri];
      }

      /**
       * 设置房间内 RTC 资源 ssrc 和 trackId map
       */
      const { ssrc, resourceId } = JSON.parse(uri.uri);
      this._store.setTrackIdSSRCMap(ssrc, resourceId);
    });

    /**
     * 房间内 RTC、MCU 资源赋值
     */
    remoteMUCUris.forEach((uri: IPublishedResource) => {
      const { mediaType, tag } = uri;
      const trackId = [this._roomId, tag, mediaType].join('_');
      this._roomRes[trackId] = uri;

      /**
       * 设置房间 MCU 资源 ssrc 和 trackId map
       */
      const { ssrc } = JSON.parse(uri.uri);
      this._store.setTrackIdSSRCMap(ssrc, trackId);
    });
    remoteRTCUris.forEach((uri: IPublishedResource) => {
      const trackId = getTrackId(uri);
      this._roomRes[trackId] = uri;
    });
  }

  protected _assertRoomDestroyed(): RCRTCCode | undefined {
    if (this._destroyed) {
      return RCRTCCode.ROOM_HAS_BEEN_DESTROYED;
    }
  }

  /**
   * @description 信令数据处理
   * @param roomId 数据对应的房间 Id
   * @param singalData 拉取到的数据
   * * key RC_ANCHOR_LIST value: 为主播 ID 集合
   * * key RC_RES_`userId` value: 为主播发布的资源
   * * key RC_RTC_SESSIONID value: sessionId
   * * key RC_CDN value: CDN 资源数据
   */
  private singalDataChange(singalData: IServerRTCRoomEntry[], roomId: string, traceId: string) {
    if (roomId !== this._roomId) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_SINGAL_DATA_CHANGE_P, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `roomId inconsistency -> params roomId:${roomId}, current roomId:${this._roomId}`,
      }), traceId);

      return;
    }

    const allMcuUris: IPublishedResource[] = [];

    const CDNUrisStr: string = singalData.filter((item) => item.key === 'RC_CDN')[0]?.value;
    CDNUrisStr && this._diffCDNUris(JSON.parse(JSON.parse(CDNUrisStr).cdn_uris)[0], traceId);

    singalData.forEach((data) => {
      const {
        key, value, timestamp, uid,
      } = data;
      const isResKey = key.indexOf('RC_RES_') !== -1;
      if (isResKey) {
        const serverUris = JSON.parse(value || '{}') as { 'uris': string, 'mcu_uris': string};
        const mcuUris = JSON.parse(serverUris.mcu_uris || '[]') as IPublishedResource[];
        const anchorUris = JSON.parse(serverUris.uris || '[]') as IPublishedResource[];
        allMcuUris.push(...mcuUris);
        // 处理主播发布的分流资源
        this._diffAnchorResource(anchorUris, uid, traceId);
        return;
      }
      // 处理主播列表
      if (key === 'RC_ANCHOR_LIST') {
        const anchorUserIds = JSON.parse(value || '[]') as string[];
        const { joinUserIds, leftUserIds } = this._diffAnchorList(anchorUserIds);
        if (joinUserIds.length > 0) {
          this._handleNewJoinedAnchor(joinUserIds, traceId);
        }
        if (leftUserIds.length > 0) {
          this._handleLeftedAnchor(leftUserIds, traceId);
        }
      }
    });
    // 处理直播间 MCU 合流资源资源
    this._diffRoomResource(allMcuUris, traceId);
  }

  /**
   * 计算加入离开的主播 ID 列表
   */
  private _diffAnchorList(serverRoomAllAnchor: string[]): {
    leftUserIds: string[],
    joinUserIds: string[]
  } {
    const joinUserIds = serverRoomAllAnchor.filter((userId) => this._roomAnchorList.indexOf(userId) < 0);
    const leftUserIds = this._roomAnchorList.filter((userId) => serverRoomAllAnchor.indexOf(userId) < 0);
    return {
      leftUserIds,
      joinUserIds,
    };
  }

  private _handleNewJoinedAnchor(list: string[], traceId: string) {
    // 更新 _roomAnchorList
    this._roomAnchorList.push(...list);
    // 触发 app 主播加入监听
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: 'onAnchorJoin',
      list,
    }), traceId);
    this._callAppListener('onAnchorJoin', list);
  }

  private async _handleLeftedAnchor(list: string[], traceId: string) {
    // 更新 _roomAnchorList
    this._roomAnchorList = this._roomAnchorList.filter((item) => !(list.indexOf(item) > -1));
    // 主播离开房间时，自动退订对方资源
    const tracks: RCRemoteTrack[] = [];
    list.forEach((userId) => {
      tracks.push(...this.getRemoteTracksByUserId(userId));
      delete this._roomAnchorRes[userId];
    });
    if (tracks.length) {
      await this.unsubscribe(tracks);
      tracks.forEach((item) => delete this._remoteTracks[item.getTrackId()]);
    }
    // 触发 app 主播离开监听
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: 'onAnchorLeave',
      list,
    }), traceId);
    this._callAppListener('onAnchorLeave', list);
  }

  /**
   * 计算新发布和取消发布的合流资源
   */
  private async _diffRoomResource(uris: IPublishedResource[], traceId: string) {
    const newPubTracks = [] as RCRemoteTrack[];
    const unpubTracks = [] as RCRemoteTrack[];

    // 若 uris 中有， this._remoteTracks 中没有，为新发布
    const remoteUriTrackIds: string[] = [];
    uris.forEach((uri) => {
      const serverResId = getTrackId(uri);
      const { userId, tag, mediaType } = parseTrackId(serverResId);
      const localTrackId = [this._roomId, tag, mediaType].join('_');

      /**
       * 设置房间 MCU 资源 ssrc 和 trackId map
       */
      const { ssrc } = JSON.parse(uri.uri);
      this._store.setTrackIdSSRCMap(ssrc, localTrackId);

      // 查看资源 ID 是否存在于当前房间的 remoteTracks
      if (!this._remoteTracks[localTrackId]) {
        // 置为新发布的 track
        const rTrack: RCRemoteTrack = mediaType === RCMediaType.AUDIO_ONLY
          ? new RCRemoteAudioTrack(this._logger, tag, '', this._roomId)
          : new RCRemoteVideoTrack(this._logger, tag, '', this._roomId);
        newPubTracks.push(rTrack);
        this._remoteTracks[localTrackId] = rTrack;
        this._roomRes[rTrack.getTrackId()] = uri;
      }
      remoteUriTrackIds.push(localTrackId);
    });
    // 若 this._remoteTracks 有，uris 中没有为取消发布
    Object.keys(this._remoteTracks).forEach((trackId) => {
      const isUnpubTrackId = remoteUriTrackIds.indexOf(trackId) < 0 && this._remoteTracks[trackId].isMCUTrack();
      if (isUnpubTrackId) {
        // 置为取消发布的 track
        unpubTracks.push(this._remoteTracks[trackId]);
      }
    });
    // 通知 APP 新发布的 tracks
    if (newPubTracks.length > 0) {
      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        eventType: 'onTrackPublish',
        trackIds: newPubTracks.map((track) => track.getTrackId()),
      }), traceId);
      this._callAppListener('onTrackPublish', newPubTracks);
    }

    if (unpubTracks.length > 0) {
      this._onUserUnpublish(unpubTracks, 'onTrackUnpublish', traceId);
    }
  }

  /**
   * 计算主播发布和取消发布的资源，以及资源的状态变更
  */
  private async _diffAnchorResource(uris: IPublishedResource[], userId: string, traceId: string) {
    // 当前资源清单
    const nowRes = this._roomAnchorRes[userId] || (this._roomAnchorRes[userId] = []);
    const { publishedList, unpublishedList, modifiedList } = diffPublishResources(nowRes, uris);

    // publishedList 包含当前房间未发布的资源，以及房间已存在资源的二次发布，uri 有变更
    if (publishedList.length) {
      const ids = nowRes.map(getTrackId);
      // 对方重新发布且己方已订阅的资源
      const subedTracks: RCRemoteTrack[] = [];
      const newTracks: RCRemoteTrack[] = [];
      publishedList.forEach((item) => {
        const resId = getTrackId(item);
        const index = ids.indexOf(resId);
        const { userId, tag, mediaType } = parseTrackId(resId);

        /**
         * 设置房间 RTC 资源 ssrc 和 trackId map
         */
        const { ssrc } = JSON.parse(item.uri);
        this._store.setTrackIdSSRCMap(ssrc, resId);

        if (index > -1) {
          nowRes[index] = item;
        } else {
          nowRes.push(item);
        }

        let rTrack: RCRemoteTrack = this._remoteTracks[resId];

        this._roomRes[resId] = item;
        // 二次发布资源，直接更新
        if (rTrack) {
          if (rTrack.isSubscribed()) {
            subedTracks.push(rTrack);
          }
        } else {
          rTrack = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(this._logger, tag, userId) : new RCRemoteVideoTrack(this._logger, tag, userId);
          this._remoteTracks[resId] = rTrack;
          newTracks.push(rTrack);
        }
        rTrack.__innerSetRemoteMuted(item.state === 0);
      });

      // 重新订阅二次发布资源
      if (subedTracks.length) {
        const trackIds = subedTracks.map((item) => item.getTrackId());
        const { code } = await push(() => this._subscribeHandle(subedTracks, true));
        if (code !== RCRTCCode.SUCCESS) {
          this._logger.error(RCLoggerTag.L_RESUB_REPUB_RES_R, JSON.stringify({
            status: RCLoggerStatus.FAILED,
            trackIds,
            code,
          }));
        }
      }

      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        eventType: 'onAnchorTrackPublish',
        trackIds: newTracks.map((track) => track.getTrackId()),
      }), traceId);
      this._callAppListener('onAnchorTrackPublish', newTracks);
    }

    if (unpublishedList.length) {
      const resIds = unpublishedList.map(getTrackId);
      for (let i = nowRes.length - 1; i >= 0; i -= 1) {
        const item = nowRes[i];
        if (resIds.includes(getTrackId(item))) {
          nowRes.splice(i, 1);
        }
      }
      const tracks = unpublishedList.map((item) => {
        const trackId = getTrackId(item);
        return this._remoteTracks[trackId];
      });
      await this._onUserUnpublish(tracks, 'onAnchorTrackUnpublish', traceId);
    }

    if (modifiedList.length) {
      const resIds = nowRes.map(getTrackId);
      for (let i = 0; i < modifiedList.length; i++) {
        const item = modifiedList[i];
        const id = getTrackId(item);
        // 更新资源 state
        const index = resIds.indexOf(id);
        nowRes[index].state = item.state;

        const rTrack = this._remoteTracks[id];
        rTrack.__innerSetRemoteMuted(item.state === 0);

        const eventType = rTrack.isAudioTrack() ? 'onAudioMuteChange' : 'onVideoMuteChange';

        this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          eventType,
          trackId: rTrack.getTrackId(),
        }), traceId);
        this._callAppListener(eventType, rTrack);
      }
    }
  }

  private async _onUserUnpublish(tracks: RCRemoteTrack[], eventName: 'onTrackUnpublish' | 'onAnchorTrackUnpublish', traceId: string) {
    // 需要替业务层取消订阅，业务层只需关注 UI 变化
    await this.unsubscribe(tracks);
    tracks.forEach((item) => {
      this._subscribedList = this._subscribedList.filter((sub) => sub.track.getTrackId() !== item.getTrackId());
      delete this._roomRes[item.getTrackId()];
      item.__innerDestroy();
      delete this._remoteTracks[item.getTrackId()];
    });
    // 通知 APP 取消发布的 tracks
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      eventType: eventName,
      trackIds: tracks.map((track) => track.getTrackId()),
    }), traceId);
    this._callAppListener(eventName, tracks);
  }

  private _callAppListener(eventType: keyof IAudienceRoomEventListener, ...attrs: any[]) {
    const handle = this._appListener?.[eventType] as Function;
    if (!handle) {
      return;
    }
    try {
      handle(...attrs);
    } catch (error) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify(error));
    }
  }

  /**
   * ice 断线后，尝试重新走 exchange
  */
  private async _reTryExchange() {
    push(async () => {
      const { reqBody } = await this._createSubscribeParams(this._subscribedList, {}, true);
      // 发送 /exchange 请求
      const resp = await this._exchangeHandle(reqBody);

      if (resp.code !== RCRTCCode.SUCCESS) {
        return { code: resp.code };
      }

      const { sdp: answer, resultCode } = resp.data!;
      if (resultCode !== RCRTCCode.SUCCESS) {
        return { code: resultCode };
      }

      // 请求成功，清除 ice 断线重连的定时器
      this._pc!.clearReTryExchangeTimer();

      const mcuSubList = this._subscribedList.filter((item) => item.track.isMCUTrack());
      if (mcuSubList.length > 0) {
        const mcuTrackId = mcuSubList[0].track.getTrackId();
        const sdpMsid = this._roomRes[mcuTrackId]?.msid;
        const currentMsid = [this._roomId, 'RongCloudRTC'].join('_');
        answer.sdp = answer.sdp.replace(new RegExp(sdpMsid, 'g'), currentMsid);
      }
      const resCode = await this._pc!.setRemoteAnswer(answer.sdp);
      if (resCode !== RCRTCCode.SUCCESS) {
        return { code: resCode };
      }
    }, 'audience-retry-exchange');
  }

  /**
   * 获取 subscribe 接口的请求体数据
   * @param subscribeList 订阅清单
   * @param publishedStreams 已发布流
   * @param iceRestart
   */
  protected async _createSubscribeParams(
    subscribeList: ISubscribeAttr[],
    publishedStreams: { [msid: string]: RCLocalMediaStream },
    iceRestart: boolean,
  ): Promise<{
    reqBody: IBroadcastSubReqBody,
    offer: RTCSessionDescriptionInit,
    dynamicBitrate: { min: number, max: number }
  }> {
    // createOffer
    const offer = await this._pc!.createOffer(iceRestart);

    // 提供给录像、MCU 的分辨率数据
    const extend: {
      resolutionInfo: Array<{ trackId: string, simulcast: RCStreamType, resolution: string }>
    } = {
      resolutionInfo: [],
    };

    // 动态码率
    const dynamicBitrate = { min: 0, max: 0 };

    Object.keys(publishedStreams).forEach((msid) => {
      const { mediaStream, tinyStream } = publishedStreams[msid];
      [mediaStream, tinyStream].forEach((stream, index) => {
        // 修改 SDP 内的 streamId
        const tempMsid = index === 1 ? [msid, 'tiny'].join('_') : msid;
        offer.sdp = offer.sdp?.replace(new RegExp(stream.id, 'g'), tempMsid);
        const videoTrack = stream.getVideoTracks()[0];
        if (!videoTrack) {
          return;
        }
        const isNormal = index === 0;
        const { width, height, frameRate } = isNormal ? getVideoTrackInfo(videoTrack) : tinyConf;
        // 统计分辨率数据
        extend.resolutionInfo.push({
          trackId: videoTrack.id,
          simulcast: isNormal ? RCStreamType.NORMAL : RCStreamType.TINY,
          resolution: `${width}x${height}`,
        });
        // 计算动态码率以备给 answer 使用
        const config = getNearestResolution(width, height);
        const multiple = getBitrateMultiple(frameRate);
        dynamicBitrate.min += (config.minBitrate * multiple);
        dynamicBitrate.max += (config.maxBitrate * multiple);
      });
    });

    const reqBody: IBroadcastSubReqBody = {
      sdp: offer,
      switchstream: false,
      newVersionFlag: true,
      subscribeList: subscribeList.map((item) => ({
        simulcast: item.subTiny ? RCStreamType.TINY : RCStreamType.NORMAL,
        resolution: '',
        uri: this._roomRes[item.track.getTrackId()].uri,
      })),
    };

    return { reqBody, dynamicBitrate, offer };
  }

  private async _subscribeHandle(
    tracks: (RCRemoteTrack | ISubscribeAttr)[],
    forceReq: boolean = false,
    isFromUserAction: boolean = false,
  ): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    const startTime = Date.now();
    const userAction = isFromUserAction ? RCRTCResourceAction.SUB : undefined;

    const traceId = this._logger.createTraceId()!;
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_SUBSCRIBE_T, JSON.stringify({
      trackIds: tracks.map(getTrackIdFromAttr),
      forceReq,
    }), traceId);

    const roomStatusCode = this._assertRoomDestroyed();
    if (roomStatusCode) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.ROOM_HAS_BEEN_DESTROYED,
        msg: 'room destroyed',
      }), traceId);

      userAction && this._reportMediaActionLogger.reportPubOrSubQualityData(userAction, startTime, tracks, RCRTCCode.ROOM_HAS_BEEN_DESTROYED);

      return { code: RCRTCCode.ROOM_HAS_BEEN_DESTROYED };
    }

    if (!validate('tracks', tracks, () => isArray(tracks) && tracks.length > 0 && tracks.every((item) => item instanceof RCRemoteTrack || item.track instanceof RCRemoteTrack), true)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> tracks',
      }), traceId);

      userAction && this._reportMediaActionLogger.reportPubOrSubQualityData(userAction, startTime, tracks, RCRTCCode.PARAMS_ERROR);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    // 发送上下行数据至北极星
    this._pc.__reportR3R4ToPolaris();
    this._pc.registerReportListener(this._reportListener);
    // 添加 peerConnection 事件
    this._addPeerCEvent();

    const crtSubList = this._subscribedList.map((item) => ({ ...item }));

    const attrs: ISubscribeAttr[] = tracks.map((item) => (item instanceof RCRemoteTrack ? { track: item } : item));

    let trackChanged = false;
    const R2TrackIds: string[] = [];
    attrs.forEach((item) => {
      const trackId = item.track.getTrackId();
      R2TrackIds.push(trackId);

      const crt = crtSubList.find((tmp) => tmp.track.getTrackId() === trackId);
      if (crt && crt.subTiny === item.subTiny) {
        return;
      }
      if (crt) {
        crt.subTiny = item.subTiny;
      } else {
        crtSubList.push(item);
      }
      trackChanged = true;
    });

    if (!trackChanged && !forceReq) {
      this._logger.warn(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        msg: 'repeat subscribe',
      }), traceId);

      return { code: RCRTCCode.SUCCESS };
    }

    // 北极星上报
    this._polarisReport.sendR2(R2Action.SUBSCRIBE, R2Status.BEGIN, R2TrackIds);

    return this._updateSubListHandle(crtSubList, true, traceId, startTime, userAction);
  }

  /**
   * 添加 peerConnection 事件
   */
  private _addPeerCEvent() {
    this._pc!.on(RCRTCPeerConnection.__INNER_EVENT_TRACK_READY__, (evt: RTCTrackEvent) => {
      const msid = evt.streams[0].id;
      const { track } = evt.receiver;
      const trackId = [msid, track.kind === 'audio' ? RCMediaType.AUDIO_ONLY : RCMediaType.VIDEO_ONLY].join('_');

      const rTrack: RCRemoteTrack | null = this._remoteTracks[trackId];
      if (!rTrack) {
        this._logger.warn(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_ONTRACKREADY_O, `cannot found RCRemoteTrack: ${track.id}`);
        return;
      }
      rTrack.__innerSetMediaStreamTrack(track);

      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        eventType: 'onTrackReady',
        trackId: rTrack.getTrackId(),
      }));
      this._callAppListener('onTrackReady', rTrack);
    });

    /**
     * 监听订阅成功至 ice 首次连接成功
     */
    this._pc!.once(RCRTCPeerConnection.__INNER_ICE_CONNECTED__, () => {
      this._reportMediaActionLogger.reportIceFirstConnectData('sub');
    }, this);

    this._pc!.on(RCRTCPeerConnection.__INNER_ICE_STATE_CHANGE__, (data) => {
      const { status, time, iceCandidatePair } = data;
      iceCandidatePair && this._reportMediaActionLogger.recordQualityIceStatusData(iceCandidatePair, status, time);
    });
  }

  private _getReqHeaders(): IRTCReqHeader {
    const userId = this._crtUserId;
    return {
      'App-Key': this._context.getAppkey(),
      RoomId: userId,
      Token: this._joinResData.token,
      RoomType: RTCMode.LIVE,
      UserId: userId,
      'Session-Id': this._sessionId,
      'Client-Session-Id': this._clientSessionId,
    };
  }

  private _exchangeHandle(body: IBroadcastSubReqBody) {
    return this._service.broadcastSubscribe(this._getReqHeaders(), body);
  }

  private async _updateSubListHandle(
    tracks: (RCRemoteTrack | ISubscribeAttr)[],
    forceReq: boolean = false,
    traceId: string,
    startTime: number,
    userAction?: RCRTCResourceAction,
  ): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_T, JSON.stringify({
      trackIds: tracks.map(getTrackIdFromAttr),
      forceReq,
    }), traceId);

    let attrs: ISubscribeAttr[] = tracks.map((item) => (item instanceof RCRemoteTrack ? { track: item } : { ...item }));

    // resourceId 去重，并做数据深拷贝
    const map: { [trackId: string]: boolean } = {};
    attrs = attrs.filter((res) => {
      const trackId = res.track.getTrackId();
      if (map[trackId]) {
        return false;
      }
      map[trackId] = true;
      return true;
    }).map((item) => ({ ...item }));

    const crtSubList = this._subscribedList.map((item) => ({ ...item }));

    if (!forceReq) {
      let changed = false;
      // 检查与现有订阅列表是否有差别
      attrs.forEach((item) => {
        const index = crtSubList.findIndex((tmp) => tmp.track.getTrackId() === item.track.getTrackId());
        // 新增订阅
        if (index === -1) {
          changed = true;
          return;
        }
        // 已存在的订阅内容，检测 tiny 是否有变更，同时从 crtSubList 中移除
        // 剩余未移除的内容为已取消订阅内容
        const crt = crtSubList.splice(index, 1)[0];
        if (crt.subTiny !== item.subTiny) {
          changed = true;
        }
      });

      // crtSubList 中剩余内容为取消订阅资源
      if (crtSubList.length) {
        changed = true;
      }
      if (!changed) {
        this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_R, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          msg: 'repeat subscribe',
        }), traceId);
        return { code: RCRTCCode.SUCCESS };
      }
    }
    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    this._pc!.clearReTryExchangeTimer();

    this._pc!.updateSubRemoteTracks(attrs.map((item) => item.track));

    // MediaServer 交互
    const { reqBody } = await this._createSubscribeParams(attrs, {}, false);
    const result = await this._exchangeHandle(reqBody);

    if (crtSubList.length) {
      // 取消订阅时，清除 parseRTCReport 模块中存储的数据
      const resourceIds: string[] = [];
      crtSubList.forEach((item) => {
        resourceIds.push(item.track.getTrackId());
      });
      this._pc!.reportParser?.clearLatestPacketsRecv(resourceIds);
    }

    /**
     * 存储每一个 track 对应的 mediaServer 请求信息
     */
    tracks.forEach((track) => {
      const trackId = track instanceof RCRemoteTrack ? track.getTrackId() : track.track.getTrackId();
      this._trackMediaMap[trackId] = result.qualityMsList || [];
    });

    if (result.code !== RCRTCCode.SUCCESS) {
      userAction && this._reportMediaActionLogger.reportPubOrSubQualityData(userAction, startTime, tracks, result.code, this._trackMediaMap);
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: result.code,
      }), traceId);
      return { code: result.code };
    }
    const {
      sdp: answer, resultCode, message, subscribedList,
    } = result.data!;
    if (resultCode !== RCRTCCode.SUCCESS) {
      userAction && this._reportMediaActionLogger.reportPubOrSubQualityData(userAction, startTime, tracks, resultCode, this._trackMediaMap);
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resultCode,
        msg: message,
      }), traceId);
      return { code: resultCode };
    }
    // 修改 answer.sdp 中 msid
    attrs.forEach((item) => {
      const { track } = item;
      if (track.isMCUTrack()) {
        const sdpMsid = this._roomRes[track.getTrackId()].msid;
        const { tag, userId: roomId } = parseTrackId(track.getTrackId());
        const currentMsid = [roomId, tag].join('_');
        answer.sdp = answer.sdp.replace(new RegExp(sdpMsid, 'g'), currentMsid);
      }
    });
    const resCode = await this._pc!.setRemoteAnswer(answer.sdp);

    userAction && this._reportMediaActionLogger.reportPubOrSubQualityData(userAction, startTime, tracks, resCode, this._trackMediaMap);

    if (resCode !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resCode,
        msg: 'set remote answer error',
      }), traceId);
      return { code: resCode };
    }

    // 获取真正订阅成功的资源
    const subSuccessTrackIds = subscribedList?.map((item) => `${item.msid}_${item.mediaType}`);
    const subSuccessList = attrs.filter((item) => {
      if (item.track.isMCUTrack()) {
        const serverTrackInfo = this._roomRes[item.track.getTrackId()];
        const sdpResourceId = `${serverTrackInfo.msid}_${serverTrackInfo.mediaType}`;
        return subSuccessTrackIds.includes(sdpResourceId);
      }
      return subSuccessTrackIds?.includes(item.track.getTrackId());
    });
    const afterReplaceTrackIds = subSuccessList?.map((item) => `${item.track.getTrackId()}`);
    const failedList = attrs.filter((item) => !afterReplaceTrackIds?.includes(item.track.getTrackId()));

    // 更新 remoteTrack.isSubscribed
    for (const trackId in this._remoteTracks) {
      const subed = subSuccessList.some((item) => item.track.getTrackId() === trackId);
      this._remoteTracks[trackId].__innerSetSubscribed(subed);
    }

    // 更新本地订阅关系
    this._subscribedList.splice(0, this._subscribedList.length, ...subSuccessList);

    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      subSuccessTrackIds,
      failedList,
    }), traceId);
    return failedList.length ? { code: RCRTCCode.SUCCESS, failedList } : { code: RCRTCCode.SUCCESS };
  }

  /**
   * 对比 cdn_uris 资源
   * @param newCDNUris 新的 cdn_uris 数据
   */
  private async _diffCDNUris(newCDNUris: ICDNUris, traceId: string) {
    /**
     * CDN 资源减少: 上次 CDNUris 中有 url，变更后无 url
     */
    if (this._CDNUris.url && !newCDNUris.url) {
      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        eventType: 'onCDNInfoDisable',
      }), traceId);
      this._callAppListener('onCDNInfoDisable');
      /**
       * 更新内存中存储的 cdn_uris 数据
       */
      this._CDNUris = newCDNUris;
      return;
    }

    /**
     * CDN 资源新增条件:
     * 内存中无 CDNUris 或
     * 上次 CDNUris 无 url，变更后有 url
     */
    if (!this._CDNUris || (!this._CDNUris.url && newCDNUris.url)) {
      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        eventType: 'onCDNInfoEnable',
        resolution: `W${newCDNUris.w}_H${newCDNUris.h}`,
        fps: `FPS_${newCDNUris.fps}`,
      }), traceId);
      this._callAppListener('onCDNInfoEnable', {
        resolution: `W${newCDNUris.w}_H${newCDNUris.h}`,
        fps: `FPS_${newCDNUris.fps}`,
      });
    }

    /**
     * CDN 资源变更: w、h、fps 其中一项变化
     */
    const isWChange = (this._CDNUris.w && newCDNUris.w && (this._CDNUris.w !== newCDNUris.w));
    const isHChange = (this._CDNUris.h && newCDNUris.h && (this._CDNUris.h !== newCDNUris.h));
    const isFpsChange = (this._CDNUris.fps && newCDNUris.fps && (this._CDNUris.fps !== newCDNUris.fps));
    if (isWChange || isHChange || isFpsChange) {
      this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        eventType: 'onCDNInfoChange',
        resolution: `W${newCDNUris.w}_H${newCDNUris.h}`,
        fps: `FPS_${newCDNUris.fps}`,
      }), traceId);
      this._callAppListener('onCDNInfoChange', {
        resolution: `W${newCDNUris.w}_H${newCDNUris.h}`,
        fps: `FPS_${newCDNUris.fps}`,
      });
    }

    /**
     * 更新内存中存储的 cdn_uris 数据
     */
    this._CDNUris = newCDNUris;
  }

  /**
   * 获取 CDN 资源对应的拉流地址
   * _CDNUris 无 url 时，说明未开启 CDN 推送
   * @returns CDNPlayUrl
   */
  private async _getCDNPlayUrl(params: ICDNPlayUrlReq, traceId: string): Promise<{ code: RCRTCCode, CDNPlayUrl?: string }> {
    const { w, h, fps } = params;
    const kind = this._initOptions.pullInnerCDNProtocol || RCInnerCDNPullKind.FLV;
    const useHttps = (this._initOptions.pullInnerCDNUseHttps === RCInnerCDNPullIsHttps.NOT_HTTPS) ? RCInnerCDNPullIsHttps.NOT_HTTPS : RCInnerCDNPullIsHttps.HTTPS;

    if (!this._CDNUris.url) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_GET_CDN_PLAY_URL_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.CDN_RESOURCE_IS_EMPTY,
        msg: 'cdn_uris url is empty, the anchor need to open or push CDN',
      }), traceId);
      return { code: RCRTCCode.CDN_RESOURCE_IS_EMPTY };
    }

    const headers: IMCUReqHeaders = {
      'App-Key': this._context.getAppkey(),
      Token: this._joinResData.token,
      RoomId: this.getRoomId(),
      UserId: this._crtUserId,
      SessionId: this.getSessionId(),
    };

    const paramsArr = [];
    // TIP: 扩散消息时带有 分辨率信息 { w, h, fps }
    const { w: urisWidth = 0, h: urisHeight = 1 } = this._CDNUris;
    // 如果是横屏 我们就正常展示
    if (urisWidth / urisHeight > 1) {
      w && paramsArr.push(`w=${w}`);
      h && paramsArr.push(`h=${h}`);
    } else {
    // 如果是竖屏则更换宽高展示
      w && paramsArr.push(`h=${w}`);
      h && paramsArr.push(`w=${h}`);
    }
    fps && paramsArr.push(`fps=${fps}`);
    paramsArr.push(`kind=${kind}`);
    paramsArr.push(`is_https=${useHttps}`);

    const paramsStr = paramsArr.join('&');
    let requestUrl = `${this._CDNUris.url}?`;
    paramsStr && (requestUrl += paramsStr);

    const { code, res } = await this._service.getCDNResourceInfo(headers, requestUrl, traceId);

    if (code !== RCRTCCode.SUCCESS) {
      return { code };
    }

    return {
      code,
      CDNPlayUrl: res?.data.pull_url,
    };
  }

  /**
   * 获取 CDN 资源对应的拉流地址
   * @returns CDNPlayUrl
   */
  public async getCDNPlayUrl(resolution?: RCResolution, fps?: RCFrameRate): Promise<{ code: RCRTCCode, CDNPlayUrl?: string }> {
    const traceId = this._logger.createTraceId()!;
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_GET_CDN_PLAY_URL_T, JSON.stringify({
      resolution,
      fps,
    }), traceId);

    if (resolution && !isValidResolution(resolution)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_GET_CDN_PLAY_URL_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> resolution',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    if (fps && !isValidFPS(fps)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_GET_CDN_PLAY_URL_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> fps',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const { width, height } = resolution ? transResolution(resolution) : { width: null, height: null };
    const fpsNum = fps ? transFrameRate(fps) : null;
    const params: ICDNPlayUrlReq = {};
    width && (params.w = width);
    height && (params.h = height);
    fpsNum && (params.fps = fpsNum);

    return this._getCDNPlayUrl(params, traceId);
  }

  /**
   * 订阅资源
   * @param tracks
   */
  public async subscribe(tracks: (RCRemoteTrack | ISubscribeAttr)[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    return push(() => this._subscribeHandle(tracks, false, true), 'audience-sub');
  }

  public async addSubscribeTask(tracks: (RCRemoteTrack | ISubscribeAttr)[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    // TODO: 重构RCAudienceLivingRoom, 使用队列处理
    return push(() => this._subscribeHandle(tracks, false, true), 'audience-sub');
  }

  private async __unsubscribe(tracks: RCRemoteTrack[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    const startTime = Date.now();
    const userAction = RCRTCResourceAction.UNSUB;

    const traceId = this._logger.createTraceId()!;
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UNSUBSCRIBE_T, JSON.stringify({
      trackIds: tracks.map((track) => track.getTrackId()),
    }), traceId);

    const roomStatusCode = this._assertRoomDestroyed();
    if (roomStatusCode) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UNSUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.ROOM_HAS_BEEN_DESTROYED,
        msg: 'room destroyed',
      }), traceId);

      return { code: RCRTCCode.ROOM_HAS_BEEN_DESTROYED };
    }

    if (!validate('tracks', tracks, () => isArray(tracks) && tracks.length > 0 && tracks.every((item) => item instanceof RCRemoteTrack), true)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_UNSUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> tracks',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }
    // 计算剩余订阅列表
    const crtSubList = this._subscribedList.map((item) => ({ ...item }))
      .filter((item) => tracks.every((track) => track.getTrackId() !== item.track.getTrackId()));

    // 北极星上报
    this._polarisReport.sendR2(R2Action.SUBSCRIBE, R2Status.END, tracks.map((item) => item.getTrackId()));

    return this._updateSubListHandle(crtSubList, false, traceId, startTime, userAction);
  }

  /**
   * 取消订阅资源
   * @param tracks
   */
  public async unsubscribe(tracks: RCRemoteTrack[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    return push(() => this.__unsubscribe(tracks), 'audience-unsub');
  }

  public async addUnsubscribeTask(tracks: RCRemoteTrack[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    // TODO: 重构RCAudienceLivingRoom, 使用队列处理
    return push(() => this.__unsubscribe(tracks), 'audience-unsub');
  }

  /**
   * 退出房间并销毁当前房间实例，退出后该房间的所有方法将不可用
   */
  public async __destroy(quitRoom: boolean) {
    if (this._destroyed) {
      return;
    }
    this._destroyed = true;

    // 退出 signal 房间
    if (quitRoom) {
      await this._context.quitLivingRoomAsAudience(this._roomId);
    }
    // 中断与 MediaServer 的连接
    await this._service.broadcastExit(this._getReqHeaders());

    // 销毁 pc 连接
    this._pc?.destroy();

    // 清空 onrtcdatachange 事件监听
    // this._context.onrtcdatachange = () => {}
    this._context.registerRTCSignalListener(undefined);
  }

  /**
   * 根据 trackId 获取房间内的远端资源
   * @param trackId
   */
  public getRemoteTrack(trackId: string): RCRemoteTrack {
    return this._remoteTracks[trackId];
  }

  /**
   * TODO 待优化
   * @param trackId
   */
  public getLocalTrack(trackId: string): RCRemoteTrack {
    return <RCRemoteTrack>{};
  }

  /**
   * 断线重连后处理逻辑, SDK 内部处理调用
   */
  public async __onReconnected() {
    /**
     * 重新加入房间后，从头拉取全量资源
     */
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_IM_RECONNECTED_T, undefined, traceId);

    const { code } = await this._context.joinLivingRoomAsAudience(this._roomId, RTCMode.LIVE);

    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_IM_RECONNECTED_R, `status: ${RCLoggerStatus.FAILED}`, traceId);
    }

    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_IM_RECONNECTED_R, `status: ${RCLoggerStatus.SUCCESSED}`, traceId);
  }

  /**
   * 观众房间事件注册
   * @param tag 参数描述
   */
  public registerRoomEventListener(listener: IAudienceRoomEventListener | null) {
    this._appListener = listener;
  }

  /**
   * 音量上报
   * @param handler 业务端传入的音量上报事件
   * @param _  - 参数已废弃
   */
  onAudioLevelChange(handler: IAudioLevelChangeHandler | null, _?: number) {
    this._logger.info(RCLoggerTag.L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O);
    this._audioLevelReport.onAudioLevelChange(handler);
  }

  private _reportListener: IRCRTCReportListener | null = null

  /**
   * 注册房间数据监控
   * @param listener
   */
  public registerReportListener(listener: IRCRTCReportListener | null) {
    this._reportListener = listener;
  }

  /**
   * 获取房间 Id
   */
  public getRoomId(): string {
    return this._roomId;
  }

  /**
   * 获取当前 userId
   */
  public getCrtUserId(): string {
    return this._crtUserId;
  }

  /**
   * 获取房间当前会话 Id，当房间内已无成员时房间会回收，重新加入时 sessionId 将更新
   */
  public getSessionId(): string {
    return this._sessionId;
  }

  /**
   * 获取远程主播用户列表
   */
  public getRemoteUserIds(): string[] {
    return this._roomAnchorList;
  }

  /**
   * 获取远端用户的资源列表
   * @param userId
   * @returns
   */
  public getRemoteTracksByUserId(userId: string): RCRemoteTrack[] {
    const tracks: RCRemoteTrack[] = [];
    for (const trackId in this._remoteTracks) {
      const track = this._remoteTracks[trackId];
      if (track.getUserId() === userId) {
        tracks.push(track);
      }
    }
    return tracks;
  }

  /**
   * 获取房间内所有已发布的远端资源列表, 包含合流资源
   * @returns
   */
  public getRemoteTracks(): RCRemoteTrack[] {
    const tracks: RCRemoteTrack[] = [];
    const mcuTracks: RCRemoteTrack[] = [];
    for (const id in this._remoteTracks) {
      // 合流资源最多两道
      if (mcuTracks.length === 2) break;
      const track = this._remoteTracks[id];
      if (track.isMCUTrack()) {
        mcuTracks.push(track);
      }
    }
    this._roomAnchorList.forEach((id) => {
      tracks.push(...this.getRemoteTracksByUserId(id));
    });
    return [...mcuTracks, ...tracks];
  }

  /**
   * 获取远端 RTC tracks
   */
  public getRemoteRTCTracks(): RCRemoteTrack[] {
    const tracks = [];
    for (const trackId in this._remoteTracks) {
      const track = this._remoteTracks[trackId];
      if (!track.isMCUTrack()) {
        tracks.push(track);
      }
    }
    return tracks;
  }

  /**
   * 获取远端 MCU tracks
   */
  public getRemoteMCUTracks(): RCRemoteTrack[] {
    const tracks = [];
    for (const trackId in this._remoteTracks) {
      const track = this._remoteTracks[trackId];
      if (track.isMCUTrack()) {
        tracks.push(track);
      }
    }
    return tracks;
  }

  /**
   * 获取房间内 CDN 信息
   */
  public getCDNInfo() {
    return this._CDNUris.w
      ? {
        resolution: `W${this._CDNUris.w}_H${this._CDNUris.h}` as RCResolution,
        fps: `FPS_${this._CDNUris.fps}` as RCFrameRate,
        CDNEnable: this._CDNUris.enableInnerCDN,
      }
      : {
        CDNEnable: false,
      };
  }

  public getClientSessionId() {
    return this._clientSessionId;
  }
}
