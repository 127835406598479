import {
  isNumber, isArray, isHttpUrl, notEmptyString, BasicLogger,
} from '@rongcloud/engine';
import {
  formatStreamId, transFrameRate, isValidFPS, isValidResolution, parseTrackId, transResolution,
} from '../../helper';
import { MCUConfigFlushCommand } from '../command/MCUConfigFlushCommand';
import { BackgroundPictureFillMode } from '../enums/BackgroundPictureFillMode';
import { MixLayoutMode } from '../enums/MixLayoutMode';
import { MixVideoRenderMode } from '../enums/MixVideoRenderMode';
import { RCFrameRate } from '../enums/RCFrameRate';
import { RCMediaType } from '../enums/RCMediaType';
import { RCMixInputFilterMode } from '../enums/RCMixInputFilterMode';
import { RCResolution } from '../enums/RCResolution';
import { RCRTCCode } from '../enums/RCRTCCode';

import { Invoker } from '../Invoker';
import {
  IMCUConfig, IMCUInputAudio, IMCUOutputConfig, IMCUOutputVideoConfig, IPictureAttrs,
} from '../service';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { CommandExecuteContext } from '../command/CommandExecuteContext';

const createMCUConfig = (): IMCUConfig => ({
  version: 2,
  mode: MixLayoutMode.SUSPENSION,
});

export default class RCMCUConfigBuilder {
  /**
   * mcu 配置数据，每次向服务器提交全量数据
   */
  private _values: IMCUConfig = createMCUConfig()

  private readonly _logger: BasicLogger;

  constructor(
    private _executeCtx: CommandExecuteContext,
    private _invoker: Invoker,
    /**
     * flush 提交回调
     */
    // private readonly _onFlush: (config: IMCUConfig) => Promise<{ code: RCRTCCode }>,
    /**
     * trackId 有效性验证方法
     */
    private readonly _isValidTrackId: (trackId: string) => boolean,
  ) {
    this._logger = this._executeCtx.logger;
  }

  /**
   * 设置合流后的主位置显示的视频流
   * @param videoTrackId 视频流资源 Id
   */
  setHostVideoTrack(videoTrackId: string): RCMCUConfigBuilder {
    if (!this._isValidTrackId(videoTrackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_HOST_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `videoTrackId: ${videoTrackId} is invalid`,
      }));

      return this;
    }

    const { mediaType, tag, userId } = parseTrackId(videoTrackId);
    if (mediaType !== RCMediaType.VIDEO_ONLY) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_HOST_VIDEO_TRACK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `kind of ${videoTrackId} is not video`,
      }));

      return this;
    }

    this._values.host_stream_id = formatStreamId(userId, tag);

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_HOST_VIDEO_TRACK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      videoTrackId,
    }));

    return this;
  }

  /**
   * 设置合流布局模式，当使用 `MixLayoutMode.CUSTOMIZE` 模式时，需自定义合流结构
   * @param mode
   * * `MixLayoutMode.CUSTOMIZE`: 自定义布局，需用户设置布局结构
   * * `MixLayoutMode.SUSPENSION`: 悬浮布局（默认）
   * * `MixLayoutMode.ADAPTATION`: 自适应布局
   */
  setMixLayoutMode(mode: MixLayoutMode): RCMCUConfigBuilder {
    const valid = [
      MixLayoutMode.CUSTOMIZE, MixLayoutMode.SUSPENSION, MixLayoutMode.ADAPTATION,
    ].includes(mode);

    if (!valid) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_MIX_LAYOUT_MODE_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        msg: `mode: ${mode} is invalid`,
      }));

      return this;
    }

    this._values.mode = mode;

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_MIX_LAYOUT_MODE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      mode,
    }));

    return this;
  }

  private _addOutputValue(key: keyof IMCUOutputConfig, value: any, subkey: keyof IMCUOutputVideoConfig = 'normal') {
    const output: IMCUOutputConfig = this._values.output || (this._values.output = { video: { normal: { width: 640, height: 480 } } });

    if (key === 'cdn') {
      output.cdn = value;
      return;
    }

    if (key === 'audio') {
      output.audio = { bitrate: value };
      return;
    }

    // video 修改
    const { video } = output;

    // 修改 video 编码配置
    if (subkey === 'normal' || subkey === 'tiny') {
      const videoConfig = video[subkey] || (video[subkey] = {});
      Object.assign(videoConfig, value);
      return;
    }
    // 修改背景色
    if (subkey === 'backgroundColor') {
      video.backgroundColor = value;
      return;
    }
    // 修改 renderMode
    if (subkey === 'exparams') {
      video.exparams = { renderMode: value };
      return;
    }
    // 增加/删除背景图，修改填充方式
    if (subkey === 'backgroundPicture') {
      const config = video.backgroundPicture || (video.backgroundPicture = {
        fillMode: BackgroundPictureFillMode.CROP,
        picture: [],
      });
      Object.assign(config, value);
    }
  }

  /**
   * 设置合流输出视频流的分辨率
   * @param resulution 有效值为 `RCResolution` 定义的枚举值
   */
  setOutputVideoResolution(resolution: RCResolution): RCMCUConfigBuilder {
    if (!isValidResolution(resolution)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_RESOLUTION_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> resolution: ${resolution}`,
      }));

      return this;
    }

    const { width, height } = transResolution(resolution);
    this._addOutputValue('video', { width, height }, 'normal');

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_RESOLUTION_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      resolution,
    }));

    return this;
  }

  /**
   * 设置合流输出视频流的帧率
   * @param fps 其有效值为 `RCFrameRate` 中定义的枚举值
   */
  setOutputVideoFPS(fps: RCFrameRate): RCMCUConfigBuilder {
    if (!isValidFPS(fps)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_FPS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> fps: ${fps}`,
      }));

      return this;
    }

    const fpsNum = transFrameRate(fps);
    this._addOutputValue('video', { fps: fpsNum }, 'normal');

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_FPS_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      fps,
    }));

    return this;
  }

  /**
   * 设置合流输出视频流的码率（不推荐主动修改）
   * @param bitrate
   */
  setOutputVideoBitrate(bitrate: number): RCMCUConfigBuilder {
    if (!isNumber(bitrate) || bitrate <= 0) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_BITRATE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> bitrate: ${bitrate}`,
      }));
      return this;
    }

    this._addOutputValue('video', { bitrate }, 'normal');
    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_BITRATE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      bitrate,
    }));

    return this;
  }

  /**
   * 设置合流后输出视频流小流的分辨率
   * @param resulution 有效值为 `RCResolution` 定义的枚举值
   */
  setOutputTinyVideoResolution(resolution: RCResolution): RCMCUConfigBuilder {
    if (!isValidResolution(resolution)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_RESOLUTION_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> resolution: ${resolution}`,
      }));

      return this;
    }

    const { width, height } = transResolution(resolution);
    this._addOutputValue('video', { width, height }, 'tiny');

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_RESOLUTION_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      resolution,
    }));

    return this;
  }

  /**
   * 设置合流输出视频流小流的帧率
   * @param fps 其有效值为 `RCFrameRate` 中定义的枚举值
   */
  setOutputTinyVideoFPS(fps: RCFrameRate): RCMCUConfigBuilder {
    if (!isValidFPS(fps)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_FPS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> fps: ${fps}`,
      }));

      return this;
    }

    const fpsNum = transFrameRate(fps);
    this._addOutputValue('video', { fps: fpsNum }, 'tiny');

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_FPS_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      fps,
    }));

    return this;
  }

  /**
   * 设置合流输出视频流小流的码率（不推荐主动修改）
   * @param bitrate
   */
  setOutputTinyVideoBitrate(bitrate: number): RCMCUConfigBuilder {
    if (!isNumber(bitrate) || bitrate <= 0) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_BITRATE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> bitrate: ${bitrate}`,
      }));

      return this;
    }

    this._addOutputValue('video', { bitrate }, 'tiny');
    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_BITRATE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      bitrate,
    }));

    return this;
  }

  /**
   * 设置合流后的视频流渲染方式
   * @param renderMode
   */
  setOutputVideoRenderMode(renderMode: MixVideoRenderMode): RCMCUConfigBuilder {
    if (![MixVideoRenderMode.CROP, MixVideoRenderMode.WHOLE].includes(renderMode)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_RENDER_MODE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> renderMode: ${renderMode}`,
      }));

      return this;
    }

    this._addOutputValue('video', renderMode, 'exparams');
    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_RENDER_MODE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      renderMode,
    }));

    return this;
  }

  /**
   * 设置合流后音频流的编码参数（不推荐主动修改）
   * @param bitrate 音频码率
   */
  setOutputAudioBitrate(bitrate: number): RCMCUConfigBuilder {
    if (isNumber(bitrate) && bitrate > 0) {
      this._addOutputValue('audio', bitrate);
      this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_AUDIO_BITRATE_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        bitrate,
      }));
      return this;
    }

    this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_AUDIO_BITRATE_O, JSON.stringify({
      status: RCLoggerStatus.FAILED,
      msg: `params error -> bitrate: ${bitrate}`,
    }));

    return this;
  }

  /**
   * 设置合流后的视频流的背景色，默认为 `0x000000`
   * @param color 颜色参数，为 16 进制标识法，如 '0x000000'
   */
  setOutputBackgroundColor(color: string): RCMCUConfigBuilder {
    if (!/^0x[a-fA-F0-9]{6}$/.test(color)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_COLOR_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> color: ${color}`,
      }));

      return this;
    }

    this._addOutputValue('video', color, 'backgroundColor');
    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_COLOR_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      color,
    }));

    return this;
  }

  /**
   * 向合流后的视频流中增加背景图片
   * @param uri 图片资源的完整下载地址
   * @param x 相对于整体画布的起始位置 x 坐标（百分比），有效值 `0.0` - `1.0`
   * @param y 相对于整体画布的起始位置 y 坐标（百分比），有效值 `0.0` - `1.0`
   * @param w 相对于整体画布的宽（百分比），有效值 `0.0` - `1.0`
   * @param h 相对于整体画布的高（百分比），有效值 `0.0` - `1.0`
   */
  addOutputBackgroundPicture(uri: string, x: number, y: number, w: number, h: number): RCMCUConfigBuilder {
    if (!isHttpUrl(uri)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> uri: ${uri}`,
      }));

      return this;
    }
    if ([x, y, w, h].some((item) => !isNumber(item) || item < 0 || item > 1)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params of (x, y, w, h) error -> x: ${x}, y: ${y}, w: ${w}, h: ${h}`,
      }));

      return this;
    }

    const pictures: IPictureAttrs[] = this._values.output?.video?.backgroundPicture?.picture || [];
    pictures.push({
      uri, w, h, x, y,
    });
    this._addOutputValue('video', { picture: pictures }, 'backgroundPicture');

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      uri,
      x,
      y,
      w,
      h,
    }));

    return this;
  }

  /**
   * 移除对合流后的视频流中添加的指定背景图片
   * @param uri
   */
  removeOutputBackgroundPicture(uri: string): RCMCUConfigBuilder {
    if (!isHttpUrl(uri)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_OUTPUT_BACKGROUND_PICTURE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> uri: ${uri}`,
      }));

      return this;
    }

    let pictures = this._values.output?.video?.backgroundPicture?.picture;
    if (pictures) {
      pictures = pictures.filter((item) => item.uri !== uri);
      this._addOutputValue('video', { pictures }, 'backgroundPicture');
    }

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_OUTPUT_BACKGROUND_PICTURE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      uri,
    }));

    return this;
  }

  /**
   * 清理对合流后的视频流中添加的所有背景图片
   */
  clearOutputBackgroundPicture(): RCMCUConfigBuilder {
    this._addOutputValue('video', { pictures: [] }, 'backgroundPicture');

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_CLEAR_OUTPUT_BACKGROUND_PICTURE_O);

    return this;
  }

  /**
   * 设置合流后的视频流中添加的背景图片的填充方式：
   * 1. 按比例裁剪
   * 2. 不裁剪，按比例压缩
   * @param fillMode
   */
  setOutputBackgroundPictureFillMode(fillMode: BackgroundPictureFillMode): RCMCUConfigBuilder {
    if (![BackgroundPictureFillMode.CROP, BackgroundPictureFillMode.WHOLE].includes(fillMode)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_FILL_MODE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> fillMode: ${fillMode}`,
      }));

      return this;
    }

    this._addOutputValue('video', { fillMode }, 'backgroundPicture');
    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_FILL_MODE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      fillMode,
    }));

    return this;
  }

  /**
   * 设置直播 CDN 旁路推流地址，最多支持 5 个推流地址
   * @param urls 地址列表
   */
  addPublishStreamUrls(urls: string[]): RCMCUConfigBuilder {
    const regexp = /^rtmp:\/\/.+/;
    const invalid = !isArray(urls) || urls.length === 0 || urls.some((url) => !regexp.test(url));
    if (invalid) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_FILL_MODE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> urls: ${urls.join(',')}`,
      }));

      return this;
    }

    const cdns = this._values.output?.cdn?.concat() || [];
    let changed = false;

    urls.forEach((url) => {
      if (cdns.some((item) => item.pushurl === url)) {
        return;
      }
      changed = true;
      cdns.push({ pushurl: url });
    });

    if (cdns.length > 5) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_FILL_MODE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> publish stream urls no more than 5, length: ${urls.length}`,
      }));

      return this;
    }

    if (changed) {
      this._addOutputValue('cdn', cdns);
    }

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_PUBLISH_STREAM_URLS_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      urls,
    }));

    return this;
  }

  /**
   * 移除直播 CDN 旁路推流地址
   * @param urls
   */
  removePublishStreamUrls(urls: string[]): RCMCUConfigBuilder {
    const regexp = /^rtmp:\/\/.+/;
    const invalid = !isArray(urls) || urls.length === 0 || urls.some((url) => !regexp.test(url));
    if (invalid) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_PUBLISH_STREAM_URLS_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> urls: ${urls.join(',')}`,
      }));

      return this;
    }

    const cdns = this._values.output?.cdn?.concat() || [];

    for (let i = cdns.length - 1; i >= 0; i -= 1) {
      const { pushurl } = cdns[i];
      const index = urls.indexOf(pushurl);
      if (index >= 0) {
        urls.splice(index, 1);
        cdns.splice(i, 1);
      }
    }

    this._addOutputValue('cdn', cdns);

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_PUBLISH_STREAM_URLS_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      urls,
    }));

    return this;
  }

  /**
   * 清理已添加的 CDN 旁路推流地址
   */
  clearPublishStreamUrls(): RCMCUConfigBuilder {
    this._addOutputValue('cdn', []);

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_CLEAR_PUBLISH_STREAM_URLS_O);

    return this;
  }

  /**
   * 在自定义布局中增加视频流配置
   * @param trackId 资源 Id
   * @param x 在画布中的坐标 x
   * @param y 在画布中的坐标 y
   * @param width 分辨率宽度
   * @param height 分辨率高度
   */
  addCustomizeLayoutVideo(trackId: string, x: number, y: number, width: number, height: number): RCMCUConfigBuilder {
    if (!notEmptyString(trackId) || !this._isValidTrackId(trackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> trackId: ${trackId}`,
      }));

      return this;
    }
    if (!isNumber(x) || !isNumber(y)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params x or y is error -> x: ${x}, y: ${y}`,
      }));

      return this;
    }
    if ([width, height].some((value) => !isNumber(value) || value < 0)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params width or height error -> width: ${width}, height: ${height}`,
      }));

      return this;
    }
    const { userId, tag, mediaType } = parseTrackId(trackId);
    if (mediaType !== RCMediaType.VIDEO_ONLY) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `type of trackId: ${trackId} is not video`,
      }));

      return this;
    }

    const streamId = formatStreamId(userId, tag);
    const input = this._values.input || (this._values.input = {});
    const video = input.video || (input.video = []);

    // 重复配置项忽略
    if (video.some((item) => item.height === height && item.width === width
      && item.stream_id === streamId && item.user_id === userId
      && item.x === x && item.y === y)) {
      this._logger.warn(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'Duplicate configuration item place ignored',
      }));

      return this;
    }
    video.push({
      user_id: userId,
      stream_id: streamId,
      x,
      y,
      width,
      height,
    });

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId,
      x,
      y,
      width,
      height,
    }));

    return this;
  }

  /**
   * 移除自定义布局中的视频流配置
   * @param trackId
   */
  removeCustomizeLayoutVideo(trackId: string): RCMCUConfigBuilder {
    if (!this._isValidTrackId(trackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> trackId: ${trackId}`,
      }));

      return this;
    }

    const { userId, tag, mediaType } = parseTrackId(trackId);
    if (mediaType !== RCMediaType.VIDEO_ONLY) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `type of trackId: ${trackId} is not video`,
      }));

      return this;
    }

    const streamId = formatStreamId(userId, tag);
    const { input } = this._values;
    if (input?.video && input.video.length > 0) {
      input.video = input.video.filter((item) => item.stream_id === streamId);
    }

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_LAYOUT_VIDEO_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId,
    }));

    return this;
  }

  /**
   * 清除已添加的自定义布局中的视频流配置
   */
  clearCustomizeLayoutVideo() : RCMCUConfigBuilder {
    const { input } = this._values;
    delete input?.video;

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_CLEAR_CUSTOMIZE_LAYOUT_VIDEO_O);

    return this;
  }

  /**
   * 覆盖设置合流媒体中的音频流
   * @param trackIds 音频流 trackId 数组，当数组长度为 0 时，则合流媒体中将无音频输出
   * @returns
   */
  setCustomizeInputAudio(trackIds: string[]): RCMCUConfigBuilder {
    if (trackIds.some((id) => this._isValidTrackId(id))) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `trackIds contain invalid item -> trackIds: ${trackIds.join(',')}`,
      }));

      return this;
    }

    const input = this._values.input || (this._values.input = {});
    input.audio = trackIds.map((id) => {
      const { userId, tag } = parseTrackId(id);
      return {
        stream_id: formatStreamId(userId, tag),
        user_id: userId,
      };
    });
    this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_INPUT_VIDEO_ALL;

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_SET_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackIds,
    }));

    return this;
  }

  /**
   * 向既有的音频流合流配置中增加一道音频流
   * @param trackId 音频 trackId
   * @since v5.3.7
   */
  addCustomizeInputAudio(trackId: string) : RCMCUConfigBuilder {
    if (!this._isValidTrackId(trackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> trackId: ${trackId}`,
      }));

      return this;
    }

    const input = this._values.input || (this._values.input = {});
    const audio = input.audio || (input.audio = []);
    const { userId, tag } = parseTrackId(trackId);
    const streamId = formatStreamId(userId, tag);

    // 去重
    if (audio.some((item) => item.stream_id === streamId && item.user_id === userId)) {
      this._logger.warn(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `trackId: ${trackId} is exist`,
      }));

      return this;
    }

    audio.push({ user_id: userId, stream_id: streamId });
    this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_INPUT_VIDEO_ALL;

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId,
    }));

    return this;
  }

  /**
   * 从既有的音频流合流配置中删除一道音频流
   * @param trackId 音频对应的 trackId
   * @since v5.3.7
   */
  removeCustomizeInputAudio(trackId: string) : RCMCUConfigBuilder {
    /**
     * 校验参数
     */
    if (!this._isValidTrackId(trackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `params error -> trackId: ${trackId}`,
      }));

      return this;
    }

    const { input } = this._values;
    if (!input?.audio || input.audio.length === 0) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `type of trackId: ${trackId} is not audio`,
      }));

      return this;
    }

    const { userId, tag } = parseTrackId(trackId);
    const streamId = formatStreamId(userId, tag);

    input.audio = input.audio.filter((item: IMCUInputAudio) => userId !== item.user_id || streamId !== item.stream_id);

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_INPUT_AUDIO_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      trackId,
    }));

    return this;
  }

  /**
   * 清除音频流合流配置，恢复房间内的全音频流合流输出
   * @since v5.3.7
   */
  clearCustomizeInputAudio() : RCMCUConfigBuilder {
    const { input } = this._values;
    input?.audio && delete input.audio;

    // 清除后，按默认全部音频都被混入设置 inputFilterMode
    this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_VIDEO_ALL;

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_CLEAR_CUSTOMIZE_INPUT_AUDIO_O);

    return this;
  }

  /**
   * 给单道流添加水印
   * @param trackId 资源 Id
   * @param uri 水印图片的地址，需注意图片需要是 png 格式
   * @param x 相对于整体画布的起始位置 x 坐标（百分比），有效值 `0.0` - `1.0`
   * @param y 相对于整体画布的起始位置 y 坐标（百分比），有效值 `0.0` - `1.0`
   * @param width 相对于整体画布的宽（百分比），有效值 `0.0` - `1.0`
   * @param height 相对于整体画布的高（百分比），有效值 `0.0` - `1.0`
   * @description 注意，参数中 x + width 不得大于 1，y + height 不得大于 1，否则调用 flush() 时会提示 46020 错误
   */
  addPictureWaterMark(trackId: string, uri: string, x: number, y: number, w: number, h: number): RCMCUConfigBuilder {
    if (!this._isValidTrackId(trackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_SINGLE_WATER_MARK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED, msg: `params error -> trackId: ${trackId}`,
      }));

      return this;
    }

    if (!isHttpUrl(uri)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_SINGLE_WATER_MARK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED, msg: `params error -> uri: ${uri}`,
      }));

      return this;
    }

    if ([x, y, w, h].some((item) => !isNumber(item) || item < 0 || item > 1) || x + w > 1 || y + h > 1) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_SINGLE_WATER_MARK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED, msg: `some attrs of (x, y, w, h) is invalid -> x: ${x}, y: ${y}, w: ${w}, h: ${h}`,
      }));
      return this;
    }

    const { userId, tag } = parseTrackId(trackId);
    const streamId = formatStreamId(userId, tag);
    const waterMark = this._values.waterMark || (this._values.waterMark = { enable: 'on', singleScreen: [] });
    const { singleScreen } = waterMark;

    const target = singleScreen.filter((item) => item.streamId === streamId)[0];

    target
      ? target.picture.push({
        uri, w, h, x, y,
      })
      : singleScreen.push({
        streamId,
        picture: [{
          uri, w, h, x, y,
        }],
      });

    this._values.waterMark.singleScreen = singleScreen;

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_ADD_SINGLE_WATER_MARK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED, trackId, uri, x, y, w, h, singleScreen,
    }));

    return this;
  }

  /**
   * 删除所有水印
   */
  clearPictureWaterMark(): RCMCUConfigBuilder {
    const waterMark = this._values.waterMark || (this._values.waterMark = { enable: 'on', singleScreen: [] });
    waterMark.singleScreen = [];
    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_CLEAR_OUTPUT_BACKGROUND_PICTURE_O);
    return this;
  }

  /**
 * 移除对合流后的某个视频流中添加的指定水印图片
 * @param uri
 */
  removePictureWaterMark(trackId:string, uri: string): RCMCUConfigBuilder {
    if (!notEmptyString(trackId) || !this._isValidTrackId(trackId)) {
      this._logger.error(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_SINGLE_WATER_MARK_O, JSON.stringify({
        status: RCLoggerStatus.FAILED, msg: `params error -> trackId: ${trackId}`,
      }));

      return this;
    }
    const { userId, tag } = parseTrackId(trackId);
    const streamId = formatStreamId(userId, tag);
    const waterMark = this._values.waterMark || (this._values.waterMark = { enable: 'on', singleScreen: [] });
    const { singleScreen } = waterMark;

    singleScreen.forEach((item) => {
      if (item.streamId === streamId) {
        item.picture = item.picture.filter((picture) => picture.uri !== uri);
      }
    });

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_REMOVE_SINGLE_WATER_MARK_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED, trackId, uri,
    }));

    return this;
  }

  /**
   * 设置 MCU 混流配置
   * @param videoList 视频输入混流列表，为 null 代表视频全混流，为空集合代表视频全不混流，否则按照输入列表进行混流
   * @param audioList 音频输入混流列表，为 null 代表音频全混流，为空集合代表音频全不混流，否则按照输入列表进行混流
   */
  // setMixInputFilterByStreams (videoList?: ICustomInputVideo[], audioList?: string[]) : RCMCUConfigBuilder {
  //   // 校验参数
  //   // this._validateInputVideoParams()

  //   // 参数都无值时，视为全合入
  //   if (!videoList && !audioList) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_VIDEO_ALL
  //     return this
  //   }

  //   // 参数都为 []，为都不合入
  //   if (!videoList?.length && !audioList?.length) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_VIDEO_NO
  //     return this
  //   }

  //   // audioList 无值，videoList 为 [] 时
  //   if (!audioList && !videoList?.length) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_ALL_VIDEO_NO
  //     return this
  //   }

  //   // videoList 无值，audioList 为 [] 时
  //   if (!videoList && !audioList?.length) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_NO_VIDEO_ALL
  //     return this
  //   }

  //   // videoList 和 audioList 数组长度都不为空时
  //   if (videoList?.length && audioList?.length) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_VIDEO_INPUT
  //   }

  //   // videoList 数组长度不为空，audioList 不传时
  //   if (videoList?.length && !audioList) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_ALL_VIDEO_INPUT
  //   }

  //   // videoList 数组长度不为空，audioList 为 []
  //   if (videoList?.length && !audioList?.length) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_NO_VIDEO_INPUT
  //   }

  //   // audioList 数组长度不为空，videoList 不传时
  //   if (audioList?.length && !videoList) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_INPUT_VIDEO_ALL
  //   }

  //   // audioList 数组长度不为空，videoList 为 []
  //   if (audioList?.length && !videoList?.length) {
  //     this._values.inputFilterMode = RCMixInputFilterMode.AUDIO_INPUT_VIDEO_NO
  //   }

  //   // 设置 input 中的 video、audio
  //   videoList?.forEach((item) => {
  //     const { trackId, x, y, width, height } = item
  //     this.addCustomizeLayoutVideo(trackId, x, y, width, height)
  //   })

  //   audioList?.length && this.setCustomInputAudio(audioList)

  //   return this
  // }

  /**
   * 设置 MCU 混流配置
   * @param roomIds 混流房间列表
   * @param mediaType 混流媒体类型
   * @param isAppend 是否为增量混流
   * * true 为增量混流
   * * false 为全量覆盖混流
   */
  // setMixInputFilterByRoomIds (roomIds: string[], mediaType: RCMediaType, isAppend: boolean) : RCMCUConfigBuilder {
  //   this._values.mixRooms = roomIds
  //   switch (mediaType) {
  //     case RCMediaType.AUDIO_VIDEO:
  //       this._values.inputFilterMode = isAppend ? RCMixInputFilterMode.ROOM_AUDIO_VIDEO_APPEND : RCMixInputFilterMode.ROOM_AUDIO_VIDEO_NOT_APPEND
  //       break
  //     case RCMediaType.AUDIO_ONLY:
  //       this._values.inputFilterMode = isAppend ? RCMixInputFilterMode.ROOM_AUDIO_APPEND : RCMixInputFilterMode.ROOM_AUDIO_NOT_APPEND
  //       break
  //     case RCMediaType.VIDEO_ONLY:
  //       this._values.inputFilterMode = isAppend ? RCMixInputFilterMode.ROOM_VIDEO_APPEND : RCMixInputFilterMode.ROOM_VIDEO_NOT_APPEND
  //       break
  //   }
  //   return this
  // }

  /**
   * 重置所有合流配置
   * @since v5.3.7
   * @returns
   */
  reset(): RCMCUConfigBuilder {
    this._values = createMCUConfig();

    this._logger.info(RCLoggerTag.L_MCU_CONFIG_BUILDER_RESET_O);

    return this;
  }

  /**
   * 使已修改的配置生效，在调用该方法前，所有数据只会对本地配置进行修改，不会产生实际效果
   * @param reset 调用完成后清空当前配置记录，默认为 `true`（v5.3.7 版本开始启用）
   * @returns
   */
  async flush(reset: boolean = true): Promise<{ code: RCRTCCode }> {
    const config: IMCUConfig = JSON.parse(JSON.stringify(this._values));
    const { code } = await this._invoker.push(new MCUConfigFlushCommand(config, this._values));
    if (reset) this._values = createMCUConfig();

    return { code };
  }

  __innerGetValues(): IMCUConfig {
    return JSON.parse(JSON.stringify(this._values));
  }
}
