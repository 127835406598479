import { BasicLogger, ILogger } from '@rongcloud/engine';
import { browserInfo, getVideoTrackInfo } from '../../../helper';
import { RCStreamType } from '../../enums/inner/RCStreamType';
import { RCRTCCode } from '../../enums/RCRTCCode';
import { RCLocalTrack } from '../../tracks/RCLocalTrack';
import { RCRemoteTrack } from '../../tracks/RCRemoteTrack';
import IStatParser from '../stat-parser/IStatParser';
import ChromeStatParser from '../stat-parser/ChromeStatParser';
import FirefoxStatParser from '../stat-parser/FirefoxStatParser';
import SafariStatParser from '../stat-parser/SafariStatParser';
import { RCLoggerStatus, RCLoggerTag } from '../../enums/RCLoggerTag';
import ASdpBuilder from './ASdpBuilder';
import { ReadableStore } from '../../Store';

export type ISdpSemantics = 'plan-b' | 'unified-plan'

export enum SdpSemantics {
  PLANB = 'plan-b',
  UNIFIEDPLAN = 'unified-plan'
}

export enum RtpTransceiverDirection {
  SENDONLY = 'sendonly',
  RECVONLY = 'recvonly',
  INACTIVE = 'inactive'
}

export interface IOfferInfo {
  type: 'offer',
  sdp: string,
  semantics: ISdpSemantics
}

export type OutboundVideoInfo = { trackId: string, simulcast: RCStreamType, resolution: string }

export abstract class ASdpStrategy {
  private static _sdpSemantics: ISdpSemantics

  /**
   * 设置指定的 SDP 协议版本
   * @param sdpSemantics 优先版本
   */
  static setSdpSemantics(sdpSemantics: ISdpSemantics, logger: ILogger) {
    const { browser, version, supportsUnifiedPlan } = browserInfo;
    logger.info(RCLoggerTag.L_A_SDP_STRATEGY_SET_SDP_SEMANTICS_O, JSON.stringify({
      sdpSemantics,
      supportsUnifiedPlan,
      browser,
      version,
    }));

    // 在明确不支持 unified-plan 的版本中使用 plan-b
    if (!supportsUnifiedPlan) {
      this._sdpSemantics = 'plan-b';
      return;
    }

    // 明确支持 unified-plan 的版本中，保留 chrome 浏览器 72-92 版本之间的调试能力
    if (/chrome/i.test(browser)) {
      /**
       * 浏览器模拟移动端时，version 为 null
       */
      if (!version) {
        this._sdpSemantics = 'unified-plan';
        return;
      }
      // chrome 72 - 92 之间的版本可以通过传参的方式定义使用的 SDP 协议版本以便于测试
      // this._sdpSemantics = version! > 92 ? 'unified-plan' : (version! < 72 ? 'plan-b' : sdpSemantics);
      if (version! > 92) {
        this._sdpSemantics = 'unified-plan';
      } else if (version! < 72) {
        this._sdpSemantics = 'plan-b';
      } else {
        this._sdpSemantics = sdpSemantics;
      }
      return;
    }

    if (/firefox/i.test(browser)) {
      this._sdpSemantics = 'unified-plan';
      return;
    }

    if (/safari/i.test(browser)) {
      this._sdpSemantics = version! < 12 ? 'plan-b' : 'unified-plan';
      return;
    }

    this._sdpSemantics = 'unified-plan';
  }

  /**
   * 获取使用的 SDP 协议版本
   */
  static getSdpSemantics(): ISdpSemantics {
    return ASdpStrategy._sdpSemantics;
  }

  protected _outboundStreams: { [msid: string]: MediaStream } = {}

  constructor(
    protected readonly _logger: BasicLogger,
    protected readonly _peer: RTCPeerConnection,
  ) {
  }

  getOutboundVideoInfo(): OutboundVideoInfo[] {
    const result: OutboundVideoInfo[] = [];
    for (const msid in this._outboundStreams) {
      const stream = this._outboundStreams[msid];
      const videoTrack = stream.getVideoTracks()[0];
      if (!videoTrack) {
        continue;
      }

      const isTiny = /_tiny$/.test(msid);
      const { width, height } = getVideoTrackInfo(videoTrack);
      result.push({
        trackId: videoTrack.id,
        simulcast: isTiny ? RCStreamType.TINY : RCStreamType.NORMAL,
        resolution: `${width}x${height}`,
      });
    }
    return result;
  }

  async setRemoteAnswer(sdp: string): Promise<RCRTCCode> {
    // 过滤行末的空格，服务可能产生空格数据
    sdp = ASdpBuilder.trimBlankLine(sdp);

    if (
      this._peer.connectionState === 'disconnected'
      || this._peer.connectionState === 'closed'
    ) {
      this._logger.info(RCLoggerTag.L_A_SDP_STRATEGY_SET_REMOTE_ANSWER_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `peer.connectionState is ${this._peer.connectionState}`,
      }));
      return RCRTCCode.SET_REMOTE_DESCRIPTION_FAILED;
    }

    // if (this._peer.signalingState !== 'have-local-offer') {
    //   // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/signalingState
    //   this._logger.info(RCLoggerTag.L_A_SDP_STRATEGY_SET_REMOTE_ANSWER_O, JSON.stringify({
    //     status: RCLoggerStatus.FAILED,
    //     msg: `peer.signalingState is ${this._peer.signalingState}`,
    //   }));
    //   return RCRTCCode.SET_REMOTE_DESCRIPTION_FAILED;
    // }

    try {
      await this._peer.setRemoteDescription({ type: 'answer', sdp });
    } catch (error) {
      this._logger.info(RCLoggerTag.L_A_SDP_STRATEGY_SET_REMOTE_ANSWER_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: error,
      }));

      return RCRTCCode.SET_REMOTE_DESCRIPTION_FAILED;
    }

    this._logger.info(RCLoggerTag.L_A_SDP_STRATEGY_SET_REMOTE_ANSWER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      sdp,
    }));

    return RCRTCCode.SUCCESS;
  }

  abstract updateRecvTransceiverMap (trackId: string, transceiver: RTCRtpTransceiver): void

  abstract setBitrate (max: number, min: number, start?: number): void

  abstract addLocalTrack (track: RCLocalTrack): void

  abstract removeLocalTrack (track: RCLocalTrack): void

  abstract updateSubRemoteTracks (remoteTracks: RCRemoteTrack[]): void

  abstract createOffer (iceRestart: boolean): Promise<IOfferInfo>

  getStatParsr(rtcPeerConn: RTCPeerConnection, sdpSemantics: string, currentUserId: string, store: ReadableStore): IStatParser | null {
    if (/chrome/i.test(browserInfo.browser)) {
      return new ChromeStatParser(this, rtcPeerConn, this._logger, sdpSemantics, currentUserId, store);
    }
    if (/Firefox/i.test(browserInfo.browser)) {
      return new FirefoxStatParser(this, rtcPeerConn, this._logger, sdpSemantics, currentUserId, store);
    }
    if (/Safari/i.test(browserInfo.browser)) {
      return new SafariStatParser(this, rtcPeerConn, this._logger, sdpSemantics, currentUserId, store);
    }
    return null;
  }

  /**
   * 根据 mid 获取 trackId，unified-plan 重写该实现
   * @param mid
   */
  public getTrackIdByMid(mid: string): string | null {
    return null;
  }

  protected resetSdp(sdp: string): string {
    return ASdpBuilder.trimBlankLine(sdp);
  }
}
