import {
  BasicLogger, ErrorCode, IRuntime, isString,
} from '@rongcloud/engine';
import {
  IRCRTCReportListener, IRCRTCTrackEventListener, IRCRTCInitOptions, IResource,
} from './interfaces';
import RCMediaService from './service/RCMediaService';
import RCRTCPeerConnection from './webrtc/RCRTCPeerConnection';
import { getUUID, IBroadcastSubReqBody, IRTCReqHeader } from './service';
import { isIllegalConnection, parseTrackId } from '../helper';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from './tracks/RCRemoteTrack';
import { RCLivingType } from './enums/RCLivingType';
import { RCRTCCode } from './enums/RCRTCCode';
import { RCMediaType } from './enums/RCMediaType';
import { RCStreamType } from './enums/inner/RCStreamType';
import { push } from '../async-task-queue';
import { RCLoggerStatus, RCLoggerTag } from './enums/RCLoggerTag';
import { RTCContext } from './codec/RTCContext';
import { RTCMode } from './enums/RTCMode';
import { getSsrcByStreamIdFromSdp } from './command/helper';
import { Store } from './Store';
/**
 * 直播观众客户端
 */
export default class RCAudienceClient {
  private _pc!: RCRTCPeerConnection | null

  private _service: RCMediaService

  /**
   * RTCToken
   */
  private _rtcToken: string = ''

  /**
   * 已订阅的资源信息
   */
  private _liveUrl: string = ''

  /**
   * 已订阅的远端流
   */
  private readonly _subTracks: RCRemoteTrack[] = []

  /**
   * 订阅的远端资源
   */
  private _subscribedList: IResource[] = []

  /**
   * 客户端传入的数据上报事件
   */
  private _reportListener!: IRCRTCReportListener | null

  private readonly _clientSessionId: string = getUUID()

  private _store!: Store

  private _crtUserId!: string

  private readonly _logger: BasicLogger

  constructor(
    private readonly _context: RTCContext,
    runtime: IRuntime,
    _initOption: IRCRTCInitOptions,
  ) {
    this._crtUserId = this._context.getCurrentId();
    this._service = new RCMediaService(runtime, _context, _initOption.mediaServer);
    this._logger = this._context.logger;

    /**
     * 利用 store 存储数据
     */
    this._store = new Store(this._logger, this._clientSessionId, this._context.getAppkey(), this._crtUserId, this._crtUserId, RTCMode.LIVE);
  }

  private async _getReqHeaders(livingType?: RCLivingType): Promise<{
    code: RCRTCCode,
    headers?: IRTCReqHeader
  }> {
    // 直播观众端 RoomId 与 UserId 保持一致
    const roomId = this._crtUserId;

    // 取 rtcToken
    if (!this._rtcToken) {
      const { code, data } = await this._context.getRTCToken(roomId, RTCMode.LIVE, livingType);
      if (code !== ErrorCode.SUCCESS) {
        return { code: RCRTCCode.SIGNAL_ERROR };
      }
      this._rtcToken = data!.rtcToken;
    }

    return {
      code: RCRTCCode.SUCCESS,
      headers: {
        'App-Key': this._context.getAppkey(),
        UserId: this._crtUserId,
        RoomId: roomId,
        RoomType: RTCMode.LIVE,
        Token: this._rtcToken,
        'Client-Session-Id': this._clientSessionId,
      },
    };
  }

  private _clearSubscribeInfo() {
    this._liveUrl = '';
    this._livingType = null;
    this._mediaType = null;
    this._subTiny = false;
    this._subTracks.length = 0;
    this._pc?.destroy();
    this._pc = null;
  }

  private _livingType: RCLivingType | null = null

  private _mediaType: RCMediaType | null = null

  private _subTiny: boolean = false

  // `subscribe` 方法调用是否来源于 ice 断线重连
  private _fromRetry: boolean = false

  private async _reTryExchange() {
    this._fromRetry = true;
    const { code } = await this.subscribe(this._liveUrl, this._livingType!, this._mediaType!, this._subTiny);

    if (code === RCRTCCode.SUCCESS) {
      this._pc?.clearReTryExchangeTimer();
    }
  }

  /**
   * 直播观众订阅主播资源，直播观众端无需加入房间
   * @param liveUrl 直播资源地址
   * @param livingType 直播类型，有效值为音频、音视频
   * @param mediaType 订阅资源类型，其有效值为 `RCMediaType` 的枚举值
   * @param subTiny 当值为 `true` 时将订阅小流，否则订阅大流。默认值为 `false`
   */
  async subscribe(liveUrl: string, livingType: RCLivingType, mediaType: RCMediaType, subTiny: boolean = false): Promise<{ code: RCRTCCode, tracks: RCRemoteTrack[] }> {
    return push(() => this.__subscribe(liveUrl, livingType, mediaType, subTiny), 'audience-client-sub');
  }

  private async __subscribe(liveUrl: string, livingType: RCLivingType, mediaType: RCMediaType, subTiny: boolean = false): Promise<{ code: RCRTCCode, tracks: RCRemoteTrack[] }> {
    this._logger.info(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_T, JSON.stringify({
      liveUrl,
      livingType,
      mediaType,
      subTiny,
    }));

    const tracks: RCRemoteTrack[] = [];

    if (isIllegalConnection(this._context.getNaviInfo()!)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PACKAGE_ENVIRONMENT_ERROR,
        msg: 'package environment error',
      }));

      return { code: RCRTCCode.PACKAGE_ENVIRONMENT_ERROR, tracks };
    }
    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    !this._fromRetry && this._pc?.clearReTryExchangeTimer();
    this._fromRetry = false;

    if (!isString(liveUrl)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: `params error -> liveUrl: ${liveUrl}`,
      }));

      return { code: RCRTCCode.PARAMS_ERROR, tracks };
    }
    if (![RCLivingType.AUDIO, RCLivingType.VIDEO].includes(livingType)) {
      this._logger.error(`livingType is invalid: ${livingType}`);

      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: `params error -> livingType: ${livingType}`,
      }));

      return { code: RCRTCCode.PARAMS_ERROR, tracks };
    }
    if (![RCMediaType.AUDIO_ONLY, RCMediaType.VIDEO_ONLY, RCMediaType.AUDIO_VIDEO].includes(mediaType)) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: `params error -> -> mediaType: ${mediaType}`,
      }));

      return { code: RCRTCCode.PARAMS_ERROR, tracks };
    }

    // 允许观众动态切换大小流订阅，或重复订阅同一资源
    if (this._liveUrl && this._liveUrl !== liveUrl) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.BROADCAST_SUB_LIST_NOT_EMPTY,
        msg: 'repeat subscribe',
      }));

      return { code: RCRTCCode.BROADCAST_SUB_LIST_NOT_EMPTY, tracks };
    }

    /**
     * 创建 peerConnection，完成注册
     */
    if (!this._pc) {
      this._pc = new RCRTCPeerConnection(this._logger, this._reTryExchange.bind(this), this._crtUserId, this._store, undefined, false);
      this._pc.on(RCRTCPeerConnection.__INNER_EVENT_TRACK_READY__, this._onTrackReady, this);
      this._pc.registerReportListener(this._reportListener);

      // 发送上下行数据至北极星
      this._pc.__reportR3R4ToPolaris();
    }

    // 暂存，避免同步栈内并发调用，若订阅失败需清理
    this._liveUrl = liveUrl;
    // 构建 http req headers
    const { code, headers } = await this._getReqHeaders(livingType);
    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: 'getRTCToken failed',
      }));

      return { code, tracks };
    }

    // 直播观众订阅的是合流后的数据，并不存在流的归属问题，此处直接以虚拟生成的合流 id 替代 userId
    const mcuId = `rc_mcu_${Date.now()}`;
    const tag = 'RongCloudRTC';

    if (this._subTracks.length === 0) {
      // 观众端单次只能订阅一个 liveUrl, 订阅后再次重复订阅只是修改订阅参数
      // 重复订阅后之前订阅的流可直接根据参数变动，无需重新添加 transceiver
      this._subTracks.push(new RCRemoteAudioTrack(this._logger, tag, mcuId), new RCRemoteVideoTrack(this._logger, tag, mcuId));
      this._pc.updateSubRemoteTracks(this._subTracks.slice());
    }

    const offer = await this._pc.createOffer(true);
    const body: IBroadcastSubReqBody = {
      sdp: offer,
      liveUrl,
      mediaType,
      simulcast: subTiny ? RCStreamType.TINY : RCStreamType.NORMAL,
      switchstream: false,
      // switchstream: !!this._initOption.autoSwitchStream
    };

    const resp = await this._service.broadcastSubscribe(headers!, body);

    if (resp.code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: resp.code,
      }));

      return { code: resp.code, tracks };
    }

    const data = resp.data!;
    if (data.resultCode !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: data.resultCode,
        msg: data.message,
      }));

      return { code: data.resultCode, tracks };
    }

    this._livingType = livingType;
    this._mediaType = mediaType;
    this._subTiny = subTiny;

    const { sdp: answer, subscribedList } = data;
    this._subscribedList = subscribedList;
    const readyTracks: RCRemoteTrack[] = [];
    subscribedList.forEach((item) => {
      const { mediaType } = item;
      const rTrack: RCRemoteTrack = this._subTracks[mediaType];
      readyTracks.push(rTrack);
      // 直播观众订阅的流为合流数据，不存在单独禁用的问题
      rTrack.__innerSetRemoteMuted(true);
    });

    // 无需等待 setRemoteAnswer 完成，直接返回，避免业务层拿到 tracks 之前先获取了 onTrackReady 通知
    this._pc.setRemoteAnswer(answer.sdp);

    readyTracks && this._updateTrackIdSSRCMap(answer.sdp, readyTracks);

    // 缓存订阅资源的ssrc和msid
    // AbstractStatParser.formatSourceIdMapFromSdp(answer.sdp, 'subscribe');

    this._logger.info(RCLoggerTag.L_AUDIENCE_CLIENT_SUBSCRIBE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      liveUrl,
    }));

    return { code: RCRTCCode.SUCCESS, tracks: readyTracks };
  }

  /**
   * 处理订阅流的 ssrc 和 trackId map
   */
  private _updateTrackIdSSRCMap(answerSdp: string, tracks: RCRemoteTrack[]) {
    tracks.forEach((track) => {
      const trackId = track.getTrackId();
      const { mediaType } = parseTrackId(trackId);
      const { msid } = this._subscribedList.filter((reource) => reource.mediaType === mediaType)[0];
      const ssrc = getSsrcByStreamIdFromSdp(answerSdp, msid, mediaType);
      this._store.setTrackIdSSRCMap(Number(ssrc), trackId);
    });
  }

  /**
   * 取消订阅主播资源
   * @param liveUrl
   */
  async unsubscribe(): Promise<{ code: RCRTCCode }> {
    return push(() => this.__unsubscribe(), 'audience-client-unsub');
  }

  private async __unsubscribe(): Promise<{ code: RCRTCCode }> {
    this._logger.info(RCLoggerTag.L_AUDIENCE_CLIENT_UNSUBSCRIBE_T, JSON.stringify({
      liveUrl: this._liveUrl,
    }));

    // 客户端主动调用 api 发请求时，清除 ice 断线重连的定时器
    this._pc?.clearReTryExchangeTimer();

    if (!this._rtcToken || !this._liveUrl) {
      this._logger.warn(RCLoggerTag.L_AUDIENCE_CLIENT_UNSUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: '',
        msg: 'Address does not exist, without unsubscribe',
      }));

      return { code: RCRTCCode.SUCCESS };
    }

    // 无需验 code，rtcToken 无值的情况已提前校验，不存在重新拿 token 的可能性
    const { headers } = await this._getReqHeaders();
    const { code } = await this._service.broadcastExit(headers!);

    if (code !== RCRTCCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_UNSUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }));
    } else {
      this._logger.info(RCLoggerTag.L_AUDIENCE_CLIENT_UNSUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        liveUrl: this._liveUrl,
      }));

      this._subscribedList = [];
    }

    this._clearSubscribeInfo();
    return { code };
  }

  /**
   * 注册房间数据监控
   * @param listener
   * @description 该方法暂仅支持 Chrome 浏览器
   */
  registerReportListener(listener: IRCRTCReportListener | null) {
    this._reportListener = listener;
  }

  private _appListener: IRCRTCTrackEventListener | null = null

  /**
   * 注册流事件监听，多次注册会导致后者覆盖前者，可以通过使用 `registerTrackEventListener(null)` 取消注册
   * @param listener
   */
  registerTrackEventListener(listener: IRCRTCTrackEventListener | null) {
    this._appListener = listener;
  }

  private _onTrackReady(evt: RTCTrackEvent) {
    const { track } = evt.receiver;
    const mediaType = track.kind === 'audio' ? RCMediaType.AUDIO_ONLY : RCMediaType.VIDEO_ONLY;

    const rTrack = this._subTracks[mediaType];
    rTrack.__innerSetMediaStreamTrack(track);

    try {
      this._logger.info(RCLoggerTag.L_AUDIENCE_CLIENT_CALL_ONTRACKREADY_O, `trackId: ${rTrack.getTrackId()}`);
      this._appListener?.onTrackReady?.(rTrack);
    } catch (error) {
      this._logger.error(RCLoggerTag.L_AUDIENCE_CLIENT_CALL_ONTRACKREADY_O, JSON.stringify(error));
    }
  }
}
