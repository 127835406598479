import { diffPublishResources, getTrackId, parseTrackId } from '../../helper';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCMediaType } from '../enums/RCMediaType';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RTCMode } from '../enums/RTCMode';
import { IPublishedResource } from '../interfaces';
import { Invoker } from '../Invoker';
import { ICDNUris } from '../service';
import { Store } from '../Store';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from '../tracks/RCRemoteTrack';
import { BaseCommand, CommandPriority, ICommandResult } from './BaseCommand';
import { CommandEvent, CommandExecuteContext } from './CommandExecuteContext';
import { OnRemoteUserUnpubCommand } from './OnRemoteUserUnpubCommand';
import { UpdateSubscribeListCommand } from './UpdateSubscribeListCommand';

export type ResourceMsgContent = {
  /**
   * 旧版本兼容参数，当收到非 `RTCMessageName.TOTAL_CONTENT_RESOURCE` 时：
   * * ignore 值为 `true` 表示该消息由 signal server 向旧版本 RTCLib 提供的兼容消息，无需处理
   * * 否则认为该消息是由旧版本 RTCLib 主动发出的增量变更消息，需要处理
   */
  ignore?: boolean,
  /**
   * 发布到房间内的资源列表，`RTCMessageName.TOTAL_CONTENT_RESOURCE` 消息携带全量数据，否则为增量数据
   */
  uris: IPublishedResource[]
  // eslint-disable-next-line camelcase
  cdn_uris?: ICDNUris[]
}

export class ParseRemoteResCommand extends BaseCommand<void> {
  constructor(
    private msgContent: ResourceMsgContent,
    private messageType: RCRTCMessageType.PUBLISH | RCRTCMessageType.UNPUBLISH | RCRTCMessageType.MODIFY | RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
    private senderId: string,
    /**
     * 是否更新房间数据
     * 如果不更新，代表需合并处理房间数据列表
     * 此时只更新增量数据，对比完增量数据后，更新房间数据，再和原来的房间数据对比，一次抛出事件
     */
    // private isUpdateFullRoomStatus: boolean = true,
    private traceId: string,
    private sentTime?: number,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.PARSE_REMOTERES;
  }

  get priority(): CommandPriority {
    return CommandPriority.NORMAL;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult> {
    const { logger, reportMediaActionLogger } = executeCtx;
    const { uris } = this.msgContent;

    const publishedList: IPublishedResource[] = [];
    const unpublishedList: IPublishedResource[] = [];
    const modifiedList: IPublishedResource[] = [];

    let parseData: {
      publishedList: IPublishedResource[],
      unpublishedList: IPublishedResource[]
      modifiedList: IPublishedResource[]
    };

    const userId = this.senderId;
    const { messageType } = this;

    /**
     * 房间内没有某个人时，不再处理此人的资源数据
     */
    if (!store.getRemoteUserIds().includes(userId)) {
      return { code: RCRTCCode.SUCCESS };
    }

    // 当前资源清单
    const nowResources = store.getResourcesByUserId(userId) || [];

    switch (messageType) {
      case RCRTCMessageType.MODIFY:
        modifiedList.push(...uris);
        break;
      case RCRTCMessageType.PUBLISH:
        publishedList.push(...uris);
        break;
      case RCRTCMessageType.UNPUBLISH:
        unpublishedList.push(...uris);
        break;
      case RCRTCMessageType.TOTAL_CONTENT_RESOURCE:
        // 比对本地资源，找出被移除资源、新增资源、被修改资源
        parseData = diffPublishResources(nowResources, uris);
        publishedList.push(...parseData.publishedList);
        unpublishedList.push(...parseData.unpublishedList);
        modifiedList.push(...parseData.modifiedList);
        break;
    }

    if (publishedList.length > 0) {
      // published 资源包含当前房间已存在资源二次发布，uri 有变更
      const ids = nowResources.map(getTrackId);
      // 对方重新发布且己方已订阅的资源
      const subedTracks: RCRemoteTrack[] = [];
      const newTracks: RCRemoteTrack[] = [];
      publishedList.forEach((item) => {
        const resourceId = getTrackId(item);
        const index = ids.indexOf(resourceId);
        const { userId, tag, mediaType } = parseTrackId(resourceId);

        if (index > -1) {
          nowResources[index] = item;
        } else {
          nowResources.push(item);
        }

        let rTrack = store.getRemoteTrack(resourceId);

        // 二次发布的资源，直接更新
        if (rTrack) {
          if (rTrack.isSubscribed()) {
            subedTracks.push(rTrack);
          }
        } else {
          rTrack = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(logger, tag, userId) : new RCRemoteVideoTrack(logger, tag, userId);
          store.addRemoteTrack(rTrack);
          newTracks.push(rTrack);
        }
        rTrack.__innerSetRemoteMuted(item.state === 0);
      });

      // 重新订阅二次发布资源
      if (subedTracks.length) {
        const trackIds = subedTracks.map((item) => item.getTrackId());
        const { code } = await new UpdateSubscribeListCommand(subedTracks, true, this.traceId).execute(executeCtx, store, invoker);
        if (code !== RCRTCCode.SUCCESS) {
          logger.error(RCLoggerTag.L_RESUB_REPUB_RES_R, JSON.stringify({
            status: RCLoggerStatus.FAILED,
            trackIds,
            code,
          }), this.traceId);
        }
      }

      executeCtx.emit(CommandEvent.TRACKS_PUBLISH, newTracks);

      reportMediaActionLogger.reportQualityRecvPubMsgData(this.sentTime!, this.senderId);
    }

    if (unpublishedList.length > 0) {
      const resIds = unpublishedList.map(getTrackId);
      for (let i = nowResources.length - 1; i >= 0; i -= 1) {
        const item = nowResources[i];
        if (resIds.includes(getTrackId(item))) {
          nowResources.splice(i, 1);
        }
      }
      const tracks = unpublishedList.map((item) => {
        const trackId = getTrackId(item);
        return store.getRemoteTrack(trackId)!;
      });
      await new OnRemoteUserUnpubCommand(tracks).execute(executeCtx, store, invoker);
    }

    if (modifiedList.length > 0) {
      const resIds = nowResources.map(getTrackId);
      for (let i = 0; i < modifiedList.length; i += 1) {
        const item = modifiedList[i];
        const id = getTrackId(item);
        // 更新资源 state
        const index = resIds.indexOf(id);
        nowResources[index].state = item.state;

        const rTrack = store.getRemoteTrack(id)!;
        rTrack.__innerSetRemoteMuted(item.state === 0);
        rTrack.isAudioTrack() ? executeCtx.emit(CommandEvent.AUDIO_MUTE_CHANGE, rTrack) : executeCtx.emit(CommandEvent.VIDEO_MUTE_CHANGE, rTrack);
      }
    }

    // 更新房间内 resource
    store.setResourcesByUserId(userId, nowResources);

    if (store.roomMode !== RTCMode.LIVE) {
      return { code: RCRTCCode.SUCCESS };
    }

    const content = this.msgContent;
    if (!content.cdn_uris) {
      return { code: RCRTCCode.SUCCESS };
    }

    // 给业务层抛 CDN 状态
    const changed = store.getCDNUris()?.enableInnerCDN !== content.cdn_uris[0].enableInnerCDN;
    // 更新 _CDNUris
    store.setCDNUris(content.cdn_uris[0]);

    if (changed) {
      executeCtx.emit(CommandEvent.CDN_ENABLE_CHANGE, !!store.getCDNUris()?.enableInnerCDN);
    }

    return { code: RCRTCCode.SUCCESS };
  }
}
