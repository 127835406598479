export enum RCLoggerTag {
  /**
   * install rtcPlugin
   */
  L_INDEX_INSTALL_RTC_PLUGIN_O = 'L-index_install_rtc_plugin-O',
  /**
   * 连接状态变更
   */
  L_RTC_CLIENT_IM_CONNECTION_STATE_S = 'L-rtc_client_im_connection_state-S',
  /**
   * 主动断开
   */
  L_RTC_CLIENT_IM_DISCONNECT_S = 'L-rtc_client_im_disconnect-S',
  /**
   * 消息监听
   */
  L_RTC_CLIENT_MESSAGE_O = 'L-rtc_client_message-O',
  /**
   * 销毁实例
   */
  L_RTC_CLIENT_DESTROY_S = 'L-rtc_client_destroy-S',
  /**
   * 加入RTC房间任务
   */
  L_RTC_CLIENT_JOIN_RTC_ROOM_T = 'L-rtc_client_join_rtc_room-T',
  /**
   * 加入RTC房间结果
   */
  L_RTC_CLIENT_JOIN_RTC_ROOM_R = 'L-rtc_client_join_rtc_room-R',
  /**
   * 加入Living房间任务
   */
  L_RTC_CLIENT_JOIN_LIVING_ROOM_T = 'L-rtc_client_join_living_room-T',
  /**
   * 加入Living房间结果
   */
  L_RTC_CLIENT_JOIN_LIVING_ROOM_R = 'L-rtc_client_join_living_room-R',
  /**
   * 获取audience实例
   */
  L_RTC_CLIENT_GET_AUDIENCE_CLIENT_O = 'L-rtc_client_get_audience_client-O',
  /**
   * 离开房间任务
   */
  L_RTC_CLIENT_LEAVE_ROOM_T = 'L-rtc_client_leave_room-T',
  /**
   * 离开房间结果
   */
  L_RTC_CLIENT_LEAVE_ROOM_R = 'L-rtc_client_leave_room-R',
  /**
   * 创建麦克风音频轨道
   */
  L_RTC_CLIENT_CREATE_MICROPHONE_AUDIO_TRACK_O = 'L-rtc_client_create_microphone_audio_track-O',
  /**
   * 创建摄像头视频轨道
   */
  L_RTC_CLIENT_CREATE_CAMERA_VIDEO_TRACK_O = 'L-rtc_client_create_camera_video_track-O',
  /**
   * 创建麦克风和摄像头的音视频轨道
   */
  L_RTC_CLIENT_CREATE_MICROPHONE_AND_CAMERA_TRACKS_O = 'L-rtc_client_create_microphone_and_camera_tracks-O',
  /**
   * 创建屏幕视频轨道
   */
  L_RTC_CLIENT_CREATE_SCREEN_VIDEO_TRACK_O = 'L-rtc_client_create_screen_video_track-O',
  /**
   * 创建包含音频的屏幕共享轨道
   */
  L_RTC_CLIENT_CREATE_SCREEN_VIDEO_AND_AUDIO_TRACKS_O = 'L-rtc_client_create_screen_video_and_audio_tracks-O',
  /**
   * 创建本地音频轨道
   */
  L_RTC_CLIENT_CREATE_LOCAL_AUDIO_TRACK_O = 'L-rtc_client_create_local_audio_track-O',
  /**
   * 创建本地视频轨道
   */
  L_RTC_CLIENT_CREATE_LOCAL_VIDEO_TRACK_O = 'L-rtc_client_create_local_video_track-O',
  /**
   * 创建本地文件流
   */
  L_RTC_CLIENT_CREATE_LOCAL_FILE_TRACKS_O = 'L-rtc_client_create_local_file_tracks-O',
  /**
   * 创建本地流
   */
  L_RTC_CLIENT_CREATE_LOCAL_TRACKS_O = 'L-rtc_client_create_local_tracks-O',
  /**
   * 以观众身份加入房间的任务
   */
  L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_T = 'L-rtc_client_join_living_room_as_audience-T',
  /**
   * 以观众身份加入房间的结果
   */
  L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R = 'L-rtc_client_join_living_room_as_audience_R',
  /**
   * 以观众身份离开房间的任务
   */
  L_RTC_CLIENT_LEAVE_LIVING_ROOM_AS_AUDIENCE_T = 'L-rtc_client_leave_living_room_as_audience-T',
  /**
   * 以观众身份离开房间的结果
   */
  L_RTC_CLIENT_LEAVE_LIVING_ROOM_AS_AUDIENCE_R = 'L-rtc_client_leave_living_room_as_audience-R',
  /**
   * 观众升级到主播的任务
   */
  L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_T = 'L-rtc_client_upgrade_to_anchor_room-T',
  /**
   * 观众升级到主播的结果
   */
  L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_R = 'L-rtc_client_upgrade_to_anchor_room-R',
  /**
   * 主播降级到观众的任务
   */
  L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_T = 'L-rtc_client_downgrade_to_audience_room-T',
  /**
   * 主播降级到观众的结果
   */
  L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_R = 'L-rtc_client_downgrade_to_audience_room-R',
  /**
   * 业务层获取房间信息的动作、结果
   */
  L_RTC_CLIENT_GET_JOINED_ROOM_INFO_T = 'L-rtc_client_get_joined_room_info-T',
  L_RTC_CLIENT_GET_JOINED_ROOM_INFO_R = 'L-rtc_client_get_joined_room_info-R',
  /**
   * 观众不加房间触发应用层 onTrackReady 事件
   */
  L_AUDIENCE_CLIENT_CALL_ONTRACKREADY_O = 'L-audinence_client_call_onTrackReady-O',
  /**
   * 观众订阅资源任务
   */
  L_AUDIENCE_CLIENT_SUBSCRIBE_T = 'L-audinence_client_subscribe-T',
  /**
   * 观众订阅资源结果
   */
  L_AUDIENCE_CLIENT_SUBSCRIBE_R = 'L-audinence_client_subscribe-R',
  /**
   * 观众取消订阅资源任务
   */
  L_AUDIENCE_CLIENT_UNSUBSCRIBE_T = 'L-audinence_client_unsubscribe-T',
  /**
   * 观众端取消订阅资源结果
   */
  L_AUDIENCE_CLIENT_UNSUBSCRIBE_R = 'L-audinence_client_unsubscribe-R',
  /**
   * rtcPing开始
   */
  L_PINGER_START_O = 'L-pinger_start-O',
  /**
   * rtcPing结束
   */
  L_PINGER_STOP_O = 'L-pinger_stop-O',
  /**
   * send rtcPing
   */
  L_PINGER_SEND_RTCPING_T = 'L-pinger_send_rtcping-T',
  /**
   * send rtcping result
   */
  L_PINGER_SEND_RTCPING_R = 'L-pinger_send_rtcping-R',
  /**
   * 发送消息的任务
   */
  L_ABSTRACT_ROOM_SEND_MESSAGE_T = 'L-abstract_room_send_message-T',
  /**
   * 发送消息的结果
   */
  L_ABSTRACT_ROOM_SEND_MESSAGE_R = 'L-abstract_room_send_message-R',
  /**
   * 设置房间属性的任务
   */
  L_ABSTRACT_ROOM_SET_ROOM_ATTRIBUTE_T = 'L-abstract_room_set_room_attribute-T',
  /**
   * 设置房间属性的结果
   */
  L_ABSTRACT_ROOM_SET_ROOM_ATTRIBUTE_R = 'L-abstract_room_set_room_attribute_R',
  /**
   * 删除房间属性的任务
   */
  L_ABSTRACT_ROOM_DELETE_ROOM_ATTRIBUTE_T = 'L-abstract_room_delete_room_attribute_T',
  /**
   * 删除房间属性的结果
   */
  L_ABSTRACT_ROOM_DELETE_ROOM_ATTRIBUTE_R = 'L-abstract_room_delete_room_attribute_R',
  /**
   * 获取房间属性的任务
   */
  L_ABSTRACT_ROOM_GET_ROOM_ATTRIBUTE_T = 'L-abstract_room_get_room_attribute_T',
  /**
   * 获取房间属性的结果
   */
   L_ABSTRACT_ROOM_GET_ROOM_ATTRIBUTE_R = 'L-abstract_room_get_room_attribute_R',
  /**
   * 房间发布资源的任务
   */
  L_ABSTRACT_ROOM_PUBLISH_T = 'L-abstract_room_publish-T',
  /**
   * 房间发布资源结果
   */
  L_ABSTRACT_ROOM_PUBLISH_R = 'L-abstract_room_publish-R',
  /**
   * 发布资源过程中关键打点和每一个 peer 发布失败的打点
   */
  L_ABSTRACT_ROOM_PUBLISH_P = 'L-abstract_room_publish-P',
  /**
   * 房间取消发布资源的任务
   */
  L_ABSTRACT_ROOM_UNPUBLISH_T = 'L-abstract_room_unpublish-T',
  /**
   * 房间取消发布资源结果
   */
  L_ABSTRACT_ROOM_UNPUBLISH_R = 'L-abstract_room_unpublish-R',
  /**
   * 取消发布资源过程中每一个 peer 取消发布失败的打点
   */
  L_ABSTRACT_ROOM_UNPUBLISH_P = 'L-abstract_room_unpublish-P',
  /**
   * 房间订阅资源任务
   */
  L_ABSTRACT_ROOM_SUBSCRIBE_T = 'L-abstract_room_subscribe-T',
  /**
   * 房间订阅资源结果
   */
  L_ABSTRACT_ROOM_SUBSCRIBE_R = 'L-abstract_room_subscribe-R',
  /**
   * 房间取消订阅资源的任务
   */
  L_ABSTRACT_ROOM_UNSUBSCRIBE_T = 'L-abstract_room_unsubscribe-T',
  /**
   * 房间取消订阅资源的结果
   */
  L_ABSTRACT_ROOM_UNSUBSCRIBE_R = 'L-abstract_room_unsubscribe-R',
  /**
   * 客户主动调取消订阅
   */
  L_APP_CALL_UNSUBSCRIBE_O = 'L-app_call_unsubscribe-O',
  /**
   * 远端用户离开引起的取消订阅
   */
  L_USER_LEFT_UNSUBSCRIBE_O = 'L-user_left_unsubscribe-O',
  /**
   * 远端取消发布引起的取消订阅
   */
  L_RES_UNPUB_UNSUBSCRIBE_O = 'L-res_unpub_unsubscribe-O',
  /**
   * 房间注册监听事件
   */
  L_ABSTRACT_ROOM_REGISTER_ROOM_EVENT_LISTENER_O = 'L-abstract_room_register_room_event_listener-O',
  /**
   * 房间注册质量数据上报事件
   */
  L_ABSTRACT_ROOM_REGISTER_REPORT_LISTENER_O = 'L-abstract_room_register_report_listener-O',
  /**
   * 注册音量变更事件
   */
  L_ABSTRACT_ROOM_AUDIO_LEVEL_O = 'L-abstract_room_audio_level-O',
  /**
   * 房间重连的任务
   */
  L_ABSTRACT_ROOM_IM_RECONNECTED_T = 'L-abstract_room_im_reconnected-T',
  /**
   * 房间重连的结果
   */
  L_ABSTRACT_ROOM_IM_RECONNECTED_R = 'L-abstract_room_im_reconnected-R',
  /**
   * 监听器事件触发
   */
  L_ABSTRACT_ROOM_CALL_APP_LISTENER_O = 'L-abstract_room_call_app_listener-O',
  /**
   * 房间设置 bitrate
   */
  L_ABSTRACT_ROOM_SETBITRATE_O = 'L-abstract_room_setBitrate-O',
  /**
   * 房间内 onTrackReady 事件触发
   */
  L_ABSTRACT_ROOM_ONTRACKREADY_O = 'L-abstract_room_onTrackReady-O',
  /**
   * 房间内消息处理
   */
  L_ABSTRACT_ROOM_RECEIVE_MESSAGE_O = 'L-abstract_room_receive_message-O',
  /**
   * 房间内人员被踢出
   */
  L_ABSTRACT_ROOM_ONKICKOFF_O = 'L-abstract_room_onKickoff-O',
  /**
   * livingroom重连的任务
   */
  L_LIVING_ROOM_RECONNECTED_T = 'L-living_room_reconnected-T',
  /**
   * livingroom重连的结果
   */
  L_LIVING_ROOM_RECONNECTED_R = 'L-living_room_reconnected-R',
  /**
   * livingroom更改cdn状态的任务
   */
  L_LIVING_ROOM_ENABLE_INNER_CDN_T = 'L-living_room_enable_inner_cdn-T',
  /**
   * livingroom更改cdn状态的结果
   */
  L_LIVING_ROOM_ENABLE_INNER_CDN_R = 'L-living_room_enable_inner_cdn-R',
  /**
   * livingroom 扩散 cdn 内容的任务
   */
  L_LIVING_ROOM_SPREAD_CDN_INFO_T = 'L-living_room_spread_cdn_info-T',
  /**
   * livingroom 扩散 cdn 内容的结果
   */
  L_LIVING_ROOM_SPREAD_CDN_INFO_R = 'L-living_room_spread_cdn_info-R',
  /**
   * livingroom 设置房间 cdn 信息任务和结果
   */
  L_LIVING_ROOM_SET_ROOM_CDN_INFO_T = 'L-living_room_set_room_cdn_info-T',
  L_LIVING_ROOM_SET_ROOM_CDN_INFO_R = 'L-living_room_set_room_cdn_info-R',
  /**
   * livingroom加入pk房间的任务
   */
  L_LIVING_ROOM_JOINED_PK_ROOM_T = 'L-living_room_joined_pk_room-T',
  /**
   * livingroom加入pk房间的结果
   */
  L_LIVING_ROOM_JOINED_PK_ROOM_R = 'L-living_room_joined_pk_room-R',
  /**
   * livingroom离开pk房间的任务
   */
  L_LIVING_ROOM_LEAVE_PK_ROOM_T = 'L-living_room_leave_pk_room-T',
  /**
   * livingroom离开pk房间的结果
   */
  L_LIVING_ROOM_LEAVE_PK_ROOM_R = 'L-living_room_leave_pk_room-R',
  /**
   * 主动离开 pk 房间
   */
  L_APP_CALL_LEAVE_PK_ROOM_O = 'L-app_call_leave_pk_room-O',
  /**
   * 结束连麦触发离开 pk 房间
   */
   L_QUIT_PK_LEAVE_PK_ROOM_O = 'L-quit_pk_leave_pk_room-O',
  /**
   * livingroom 获取 pk 房间 handler
   */
  L_LIVING_ROOM_GET_ROOM_PK_HANDLER_O = 'L-living_room_get_room_pk_handler-O',
  /**
   * livingroom 获取 getMCUConfigBuilder
   */
  L_LIVING_ROOM_GET_MCU_CONFIG_BUILDER_O = 'L-living_room_get_mcu_config_builder-O',
  /**
   * livingroom离开全部pk房间
   */
  L_LIVING_ROOM_QUIT_ALL_PK_ROOM_O = 'L-living_room_quit_all_pk_room-O',
  /**
   * livingpkhandler 注册pk房间监听事件
   */
  L_LIVING_PK_HANDLER_REGISTER_ROOM_PK_EVENT_LISTENER_O = 'L-living_pk_handler_register_room_pk_event_listener-O',
  /**
   * livingpkhandler 注册事件执行
   */
   L_LIVING_PK_HANDLER_CALL_APP_LISTENER_O = 'L-living_pk_handler_call_app_listener-O',
  /**
   * livingpkhandler发起跨房间连麦请求的任务
   */
  L_LIVING_PK_HANDLER_REQUEST_JOIN_OTHER_ROOM_T = 'L-living_pk_handler_request_join_other_room-T',
  /**
   * livingpkhandler发起跨房间连麦请求的结果
   */
  L_LIVING_PK_HANDLER_REQUEST_JOIN_OTHER_ROOM_R = 'L-living_pk_handler_request_join_other_room-R',
  /**
   * livingpkhandler取消跨房间连麦请求的任务
   */
  L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_T = 'L-living_pk_handler_cancel_request_join_other_room-T',
  /**
   * livingpkhandler取消跨房间连麦请求的结果
   */
  L_LIVING_PK_HANDLER_CANCEL_REQUEST_JOIN_OTHER_ROOM_R = 'L-living_pk_handler_cancel_request_join_other_room-R',
  /**
   * livingpkhandler响应跨房间连麦请求的任务
   */
  L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_T = 'L-living_pk_handler_response_join_other_room-T',
  /**
   * livingpkhandler响应跨房间连麦请求的结果
   */
  L_LIVING_PK_HANDLER_RESPONSE_JOIN_OTHER_ROOM_R = 'L-living_pk_handler_response_join_other_room-R',
  /**
   * livingpkhandler加入连麦房间的任务
   */
  L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_T = 'L-living_pk_handler_join_other_room-T',
  /**
   * livingpkhandler加入连麦房间的结果
   */
  L_LIVING_PK_HANDLER_JOIN_OTHER_ROOM_R = 'L-living_pk_handler_join_other_room-R',
  /**
   * livingpkhandler离开连麦房间的任务
   */
  L_LIVING_PK_HANDLER_LEAVE_OTHER_ROOM_T = 'L-living_pk_handler_leave_other_room-T',
  /**
   * livingpkhandler离开连麦房间的结果
   */
  L_LIVING_PK_HANDLER_LEAVE_OTHER_ROOM_R = 'L-living_pk_handler_leave_other_room-R',
  /**
   * 结束连麦的执行和结果
   */
  L_QUIT_PK_T = 'L-quit_pk-T',
  L_QUIT_PK_R = 'L-quit_pk-R',
  /**
   * ExchangeWithPushOtherRoomCommand 执行和结果
   */
  L_EXCHANGE_WITH_PUSHOTHERROOM_T = 'L-exchange_with_pushotherroom-T',
  L_EXCHANGE_WITH_PUSHOTHERROOM_R = 'L-exchange_with_pushotherroom-R',
  /**
   * AudienceLivingRoom 触发业务层事件
   */
  L_AUDIENCE_LIVING_ROOM_CALL_APP_LISTENER_O = 'L-audience_living_room_call_app_listener-O',
  /**
   * AudienceLivingRoom 拉 kvEntries
   */
  L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_O = 'L-audience_living_room_pull_kvEntries-O',
  L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_T = 'L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_T',
  L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_R = 'L_AUDIENCE_LIVING_ROOM_PULL_KVENTRIES_R',
  /**
   * AudienceLivingRoom信令数据变化的结果处理过程
   */
  L_AUDIENCE_LIVING_ROOM_SINGAL_DATA_CHANGE_P = 'L-audience_living_room_singal_data_change-P',
  /**
   * AudienceLivingRoom获取cdn播放地址的任务
   */
  L_AUDIENCE_LIVING_ROOM_GET_CDN_PLAY_URL_T = 'L-audience_living_room_get_cdn_play_url-T',
  /**
   * AudienceLivingRoom获取cdn播放地址的结果
   */
  L_AUDIENCE_LIVING_ROOM_GET_CDN_PLAY_URL_R = 'L-audience_living_room_get_cdn_play_url-R',
  /**
   * AudienceLivingRoom订阅资源的任务
   */
  L_AUDIENCE_LIVING_ROOM_SUBSCRIBE_T = 'L-audience_living_room_subscribe-T',
  /**
   * AudienceLivingRoom订阅资源的结果
   */
  L_AUDIENCE_LIVING_ROOM_SUBSCRIBE_R = 'L-audience_living_room_subscribe-R',
  /**
   * AudienceLivingRoom 取消订阅资源的任务
   */
  L_AUDIENCE_LIVING_ROOM_UNSUBSCRIBE_T = 'L-audience_living_room_unsubscribe-T',
  /**
   * AudienceLivingRoom 取消订阅资源的结果
   */
  L_AUDIENCE_LIVING_ROOM_UNSUBSCRIBE_R = 'L-audience_living_room_unsubscribe-R',
  /**
   * AudienceLivingRoom updateSubscribeList 执行和结果
   */
  L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_T = 'L-audience_living_room_update_subscribeList-T',
  L_AUDIENCE_LIVING_ROOM_UPDATE_SUBSCRIBELIST_R = 'L-audience_living_room_update_subscribeList-R',
  /**
   * AudienceLivingRoom 内部 onTrackReady 触发
   */
  L_AUDIENCE_LIVING_ROOM_ONTRACKREADY_O = 'L-audience_living_room_onTrackReady-O',
  /**
   * AudienceLivingRoom 内部 im 重连
   */
  L_AUDIENCE_LIVING_ROOM_IM_RECONNECTED_R = 'L-audience_living_room_im_reconnected-R',
  L_AUDIENCE_LIVING_ROOM_IM_RECONNECTED_T = 'L-audience_living_room_im_reconnected-T',
  /**
   * MCUConfigBuilder设置音频轨道
   */
  L_MCU_CONFIG_BUILDER_SET_HOST_VIDEO_TRACK_O = 'L-mcu_config_builder_set_host_video_track-O',
  /**
   * MCUConfigBuilder设置混合排版模式
   */
  L_MCU_CONFIG_BUILDER_SET_MIX_LAYOUT_MODE_O = 'L-mcu_config_builder_set_mix_layout_mode-O',
  /**
   * MCUConfigBuilder设置输出视频
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_RESOLUTION_O = 'L-mcu_config_builder_set_output_video_resolution-O',
  /**
   * MCUConfigBuilder设置输出视频fps
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_FPS_O = 'L-mcu_config_builder_set_output_video_fps-O',
  /**
   * MCUConfigBuilder设置输出视频bitrate
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_BITRATE_O = 'L-mcu_config_builder_set_output_video_bitrate-O',
  /**
   * MCUConfigBuilder设置输出小流视频resolution
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_RESOLUTION_O = 'L-mcu_config_builder_set_output_tiny_video_resolution-O',
  /**
   * MCUConfigBuilder设置输出小流视频fps
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_FPS_O = 'L-mcu_config_builder_set_output_tiny_video_fps-O',
  /**
   * MCUConfigBuilder设置输出小流视频bitrate
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_TINY_VIDEO_BITRATE_O = 'L-mcu_config_builder_set_output_tiny_video_bitrate-O',
  /**
   * MCUConfigBuilder设置输出视频的渲染模式
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_VIDEO_RENDER_MODE_O = 'L-mcu_config_builder_set_output_video_render_mode-O',
  /**
   * MCUConfigBuilder设置输出音频bitrate
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_AUDIO_BITRATE_O = 'L-mcu_config_builder_set_output_audio_bitrate-O',
  /**
   * MCUConfigBuilder设置背景颜色
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_COLOR_O = 'L-mcu_config_builder_set_output_background_color-O',
  /**
   * MCUConfigBuilder设置背景图片
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_O = 'L-mcu_config_builder_add_output_background_picture-O',
  /**
   * MCUConfigBuilder删除背景图片
   */
  L_MCU_CONFIG_BUILDER_REMOVE_OUTPUT_BACKGROUND_PICTURE_O = 'L-mcu_config_builder_remove_output_background_picture-O',
  /**
   * MCUConfigBuilder清除背景图片
   */
  L_MCU_CONFIG_BUILDER_CLEAR_OUTPUT_BACKGROUND_PICTURE_O = 'L-mcu_config_builder_clear_output_background_picture-O',
  /**
   * MCUConfigBuilder设置背景图片填充模式
   */
  L_MCU_CONFIG_BUILDER_SET_OUTPUT_BACKGROUND_PICTURE_FILL_MODE_O = 'L-mcu_config_builder_set_output_background_picture-fill-mode-O',
  /**
   * MCUConfigBuilder设置直播CDN旁路推流地址
   */
  L_MCU_CONFIG_BUILDER_ADD_PUBLISH_STREAM_URLS_O = 'L-mcu_config_builder_add_publish_stream_urls-O',
  /**
   * MCUConfigBuilder移除直播CDN旁路推流地址
   */
  L_MCU_CONFIG_BUILDER_REMOVE_PUBLISH_STREAM_URLS_O = 'L-mcu_config_builder_remove_publish_stream_urls-O',
  /**
   * MCUConfigBuilder清除直播CDN旁路推流地址
   */
  L_MCU_CONFIG_BUILDER_CLEAR_PUBLISH_STREAM_URLS_O = 'L-mcu_config_builder_clear_publish_stream_urls-O',
  /**
   * MCUConfigBuilder添加自定义布局视频
   */
  L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_LAYOUT_VIDEO_O = 'L-mcu_config_builder_add_customize_layout_video-O',
  /**
   * MCUConfigBuilder移除自定义布局视频
   */
  L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_LAYOUT_VIDEO_O = 'L-mcu_config_builder_remove_customize_layout_video-O',
  /**
   * MCUConfigBuilder清空自定义布局视频
   */
  L_MCU_CONFIG_BUILDER_CLEAR_CUSTOMIZE_LAYOUT_VIDEO_O = 'L-mcu_config_builder_clear_customize_layout_video-O',
  /**
   * MCUConfigBuilder设置自定义输入音频
   */
  L_MCU_CONFIG_BUILDER_SET_CUSTOMIZE_INPUT_AUDIO_O = 'L-mcu_config_builder_set_customize_input_audio-O',
  /**
   * MCUConfigBuilder添加自定义输入音频
   */
  L_MCU_CONFIG_BUILDER_ADD_CUSTOMIZE_INPUT_AUDIO_O = 'L-mcu_config_builder_add_customize_input_audio-O',
  /**
   * MCUConfigBuilder移除自定义输入音频
   */
  L_MCU_CONFIG_BUILDER_REMOVE_CUSTOMIZE_INPUT_AUDIO_O = 'L-mcu_config_builder_remove_customize_input_audio-O',
  /**
   * MCUConfigBuilder清除自定义输入音频
   */
  L_MCU_CONFIG_BUILDER_CLEAR_CUSTOMIZE_INPUT_AUDIO_O = 'L-mcu_config_builder_clear_customize_input_audio-O',
  /**
   * MCUConfigBuilder重置
   */
  L_MCU_CONFIG_BUILDER_RESET_O = 'L-mcu_config_builder_reset-O',
  /**
   * MCUConfigBuilder设置单到流水印
   */
  L_MCU_CONFIG_BUILDER_ADD_SINGLE_WATER_MARK_O = 'L-mcu_config_builder_add_single_water_mark-O',
  /**
   * MCUConfigBuilder移除单到流水印
   */
  L_MCU_CONFIG_BUILDER_REMOVE_SINGLE_WATER_MARK_O = 'L-mcu_config_builder_remove_single_water_mark-O',
  /**
   * MCUConfigBuilder移除单到流水印
   */
  L_MCU_CONFIG_BUILDER_CLEAR_SINGLE_WATER_MARK_O = 'L-mcu_config_builder_clear_single_water_mark-O',
  /**
   * MediaService设置mcu配置的任务
   */
  L_MEDIA_SERVICE_SET_MCU_CONFIG_T = 'L-media_service_set_mcu_config-T',
  /**
   * MediaService设置mcu配置的结果
   */
  L_MEDIA_SERVICE_SET_MCU_CONFIG_R = 'L-media_service_set_mcu_config-R',
  /**
   * MediaService获取CDN资源内容的任务
   */
  L_MEDIA_SERVICE_GET_CDN_RESOURCE_INFO_T = 'L-media_service_get_cnd_resource_info-T',
  /**
   * MediaService获取CDN资源内容的结果
   */
  L_MEDIA_SERVICE_GET_CDN_RESOURCE_INFO_R = 'L-media_service_get_cnd_resource_info-R',
  /**
   * MediaService 使用的 msList 重新获取、排序
   */
  L_MEDIA_SERVICE_MSLIST_CHANGE_O = 'L-media_service_msList_change-O',
  /**
   * MediaService 解析 navi callVoip info 数据
   */
  L_MEDIA_SERVICE_PARSE_NAVI_CALLVOIP_INFO_O = 'L-media_service_parse_navi_callvoip_info-O',
  /**
   * MediaService发起请求的任务
   */
  L_MEDIA_SERVICE_REQUEST_T = 'L-media_service_request-T',
  /**
   * MediaService发起请求的结果
   */
  L_MEDIA_SERVICE_REQUEST_R = 'L-media_service_request-R',
  /**
   * LocalTrack销毁
   */
  L_LOCAL_TRACK_DESTROY_O = 'L-loacl_track_destroy-O',
  /**
   * LocalTrack设置bitrate
   */
  L_LOCAL_TRACK_SET_BITRATE_O = 'L-loacl_track_set_bitrate-O',
  /**
   * LocalTrack 设置禁用/启用动作
   */
  L_LOCAL_TRACK_SET_LOCAL_MUTED_O = 'L-loacl_track_set_local_muted-O',
  /**
   * LocalTrack 设置禁用/启用动作扩散动作、结果
   */
  L_LOCAL_TRACK_SET_LOCAL_MUTED_SPREAD_T = 'L-loacl_track_set_local_muted_spread-T',
  L_LOCAL_TRACK_SET_LOCAL_MUTED_SPREAD_R = 'L-loacl_track_set_local_muted_spread-R',
  /**
   * Track播放的动作
   */
  L_TRACK_PLAY_O = 'L-track_play-O',
  /**
   * Track播放出现错误
   */
  L_TRACK_PLAY_E = 'L-track_play-E',
  /**
   * Track加载开始
   */
  L_TRACK_LOAD_START_O = 'L-track_load_start-O',
  /**
   * Track时长发生变化
   */
  L_TRACK_DURATION_CHANGE_O = 'L-track_duration_change-O',
  /**
   * Track元数据加载
   */
  L_TRACK_LOADED_META_DATA_O = 'L-track_loaded_meta_data-O',
  /**
   * Track首帧加载完成
   */
  L_TRACK_LOADED_DATA_O = 'L-track_loaded_data-O',
  /**
   * Track中止
   */
   L_TRACK_ABORT_O = 'L-track_abort-O',
  /**
   * track 音量变化
   */
  L_TRACK_VOLUME_CHANGE_O = 'L-track_volume_change-O',
  /**
   * Track可以播放
   */
  L_TRACK_CANPLAY_O = 'L-track_canplay-O',
  /**
   * AbstractStatParser格式化状态
   */
  L_ABSTRACT_STAT_PARSER_FORMAT_RTC_STATE_REPORT_O = 'L-abstract_stat_parser_format_rtc_state_report-O',
  /**
   * ASdpStrategy设置semantics
   */
  L_A_SDP_STRATEGY_SET_SDP_SEMANTICS_O = 'L-a_sdp_strategy_set_sdp_semantics-O',
  /**
   * ASdpStrategy设置远端回答
   */
  L_A_SDP_STRATEGY_SET_REMOTE_ANSWER_O = 'L-a_sdp_strategy_set_remote_answer-O',
  /**
   * RTCPeerConnection设置本地轨道
   */
  L_RTC_PEER_CONNECTION_ADD_LOCAL_TRACK_O = 'L-rtc_peer_connection_add_local_track-O',
  /**
   * RTCPeerConnection ICE 连接状态改变
   */
  L_RTC_PEER_CONNECTION_ICE_CONNECTION_STATE_S = 'L-rtc_peer_connection_ice_connection_state-S',
  /**
   * RTCPeerConnection ICE 连接状态改变上报
   */
  L_RTC_PEER_CONNECTION_ICE_CONNECTION_STATE_REPORT_R = 'L-rtc_peer_connection_ice_connection_state_report-R',
  /**
   * RTCPeerConnection 的 connection连接状态改变
   */
  L_RTC_PEER_CONNECTION_CONNECTION_STATE_S = 'L-rtc_peer_connection_connection_state-S',
  /**
   * RTCPeerConnection设置bitrate
   */
  L_RTC_PEER_CONNECTION_SET_BITRATE_O = 'L-rtc_peer_connection_set_bitrate-O',
  /**
   * RTCPeerConnection创建offer
   */
  L_RTC_PEER_CONNECTION_CREATE_OFFER_O = 'L-rtc_peer_connection_create_offer-O',
  /**
   * RTCPeerConnection删除本地指定轨道
   */
  L_RTC_PEER_CONNECTION_REMOVE_LOCAL_TRACK_BY_ID_O = 'L-rtc_peer_connection_remove_local_track_by_id-O',
  /**
   * RTCPeerConnection删除全部本地轨道
   */
  L_RTC_PEER_CONNECTION_REMOVE_ALL_LOCAL_TRACK_O = 'L-rtc_peer_connection_remove_all_local_track-O',
  /**
   * RTCPeerConnection删除本地轨道
   */
  L_RTC_PEER_CONNECTION_REMOVE_LOCAL_TRACK_O = 'L-rtc_peer_connection_remove_local_track-O',
  /**
   * RTCPeerConnection销毁
   */
  L_RTC_PEER_CONNECTION_DESTROY_O = 'L-rtc_peer_connection_destroy-O',
  /**
   * 设置码率出错，设置的码率值未在推荐列表
   */
  L_RTC_SET_RECOMMEND_BITRATE_O = 'L_rtc_set_recommend_bitrate_O',
  /**
   * 3A 降噪
   */
   L_RTC_3ANOISE_NODE_O = 'L_RTC_AAAnoiseNode_O',
  /**
   * 3A 降噪
   */
   L_RTC_3ANOISE_NODE_E = 'L_RTC_AAAnoiseNode_E',
  /**
   * 3A 降噪
   */
   L_RTC_3ANOISE_NODE_STOP_O = 'L_RTC_AAAnoiseNode_Stop_O',
  /**
   * 加入跨appkey互通房间的任务
   */
  L_RTC_CLIENT_JOIN_CROSS_RTC_ROOM_T = 'L_rtc_client_join_cross_rtc_room_T',
  /**
   * 加入跨appkey互通房间的结果
   */
  L_RTC_CLIENT_JOIN_CROSS_RTC_ROOM_R = 'L_rtc_client_join_cross_rtc_room_R',
  /**
   * 连通率相关日志-加入房间
   */
  L_QS_JOIN_ROOM_S = 'RTC-joinRoomRtc-S',
  /**
   * 连通率相关日志-订阅
   */
  L_QS_SUBSCRIBE_S = 'RTC-subscribe-S',
  /**
   * 连通率相关日志-取消订阅
   */
  L_QS_UNSUBSCRIBE_S = 'RTC-unsubscribe-S',
  /**
   * 连通率相关日志-发布资源
   */
  L_QS_PUBLISH_S = 'RTC-publishRtc-S',
  /**
   * 连通率相关日志-取消发布资源
   */
  L_QS_UNPUBLISH_S = 'RTC-unpublishRtc-S',
  /**
   * 连通率相关日志-ice状态变化
   */
  L_QS_ICE_STATUS_S = 'RTC-iceStatus-S',
  /**
   * 连通率相关日志-异常退出
   */
  L_QS_KICKED_S = 'RTC-forcedOffline-S',
  /**
   * 连通率相关日志-发布ice状态变化
   */
  L_QS_PUB_ICE_STATUS_S = 'RTC-pubIceConnected-S',
  /**
   * 连通率相关日志-订阅ice状态变化
   */
  L_QS_SUB_ICE_STATUS_S = 'RTC-subIceConnected-S',
  /**
   * 连通率相关日志-对端接收首次发布资源
   */
  L_QS_RECV_PUB_MSG_S = 'RTC-recvPubMsg-S',
  /**
   * 设置编码器默认值
   */
  L_SET_ENCODER_PREFERENCES_S = 'RTC-set_Encoder_Preferences-S',
  /**
   * 下消息通知拉取房间状态
   */
  L_RTCNTF_PULL_ROOM_STATUS_O = 'L-rtcntf_pull_room_status-O',
  /**
   * rtcping 返回的 version 比内存中的大，拉取房间状态
   */
  L_RTCPING_PULL_ROOM_STATUS_O = 'L-rtcping_pull_room_status-O',
  /**
   * 房间状态通知拉取行为
   */
  L_PULL_ROOM_STATUS_T = 'L-pull_room_status-T',
  /**
   * 房间状态通知拉取结果
   */
  L_PULL_ROOM_STATUS_R = 'L-pull_room_status-R',
  /**
   * 旧版日志
   */
  L_OLD_DAILY_RECORD_O = 'L-old_daily_record-O',
  /**
   * 远端用户加入房间后的本地存储的用户资源列表
   */
  L_REMOTE_USER_JOINED_RESOURCES_O = 'L-remote_user_joined_resources-O',
  /**
   * 远端用户在房间中状态变化的回调
   */
  L_REMOTE_USER_STATE_HANDLE_O = 'L-remote_user_state_handle-O',
  /**
   * UpdateSubscribeListCommand 任务
   */
  L_UPDATE_SUBSCRIBELIST_COMMAND_T = 'L-update_subscribeList_command-T',
  /**
   * UpdateSubscribeListCommand 执行过程中的关键打点
   */
  L_UPDATE_SUBSCRIBELIST_COMMAND_P = 'L-update_subscribeList_command-P',
  /**
   * UpdateSubscribeListCommand 任务执行结果
   */
  L_UPDATE_SUBSCRIBELIST_COMMAND_R = 'L-update_subscribeList_command-R',
  /**
   * navi 变更
   */
  L_RTC_CLIENT_NAVI_CHANGE_O = 'L-rtc_client_navi_change-O',
  /**
   * UnpublishPrevCommand 任务和结果
   */
  L_UNPUBLISH_PREV_COMMAND_T = 'L-unpublish_prev_command-T',
  L_UNPUBLISH_PREV_COMMAND_R = 'L-unpublish_prev_command-R',
  /**
   * RetryExchangeCommand 任务
   * RetryExchange 之后执行扩散动作和结果
   */
  L_RETRY_EXCHANGE_COMMAND_T = 'L-retry_exchange_command-T',
  L_RETRY_EXCHANGE_SPREAD_T = 'L-retry_exchange_spread-T',
  L_RETRY_EXCHANGE_SPREAD_R = 'L-retry_exchange_spread-R',
  /**
   * ParseUserStateCommand 的结果
   */
  L_PARSE_USERSTATE_COMMAND_R = 'L-parse_userstate_command-R',
  /**
   * resub 二次发布的资源结果
   */
  L_RESUB_REPUB_RES_R = 'L-resub_repub_res-R',
  /**
   * 解析房间 uris 数据结果
   */
  L_PARSE_ROOMDATA_URIS_R = 'L-parse_roomdata_uris-R',
  /**
   * 设置音频约束
   */
  L_SET_AUDIO_CONSTRAINTS_O = 'L-set_audio_constraints-O',
  /**
   * 方法不可用
   */
  L_FUNCTION_IS_UNAVAILABLE = 'L-function_is_unavailable',
  /*
   * 取消订阅本地清除的订阅关系列表
   */
  L_CLEAR_UNSUBSCRIBE_LIST_O = 'L-clear_unsubscribe_list-O',
  /**
   * 队列任务插入
   */
  L_INVOKER_PUSH_O = 'L-invoker_push-O',
  /**
   * 队列任务开始执行
   */
  L_INVOKER_EXECUTE_T = 'L-invoker_execute-T',
  /**
   * 队列任务执行结束
   */
  L_INVOKER_EXECUTE_R = 'L-invoker_execute-R',
  /**
   * 销毁队列事件
   */
  L_INVOKER_DESTROY_O = 'L-invoker_destroy-R',
  /** 解析 navi 配置 voipCallInfo 失败 */
  L_PARSE_VOIP_SERVER_CONF_E = 'L_PARSE_VOIP_SERVER_CONF_E',
  /** 获取流失败 */
  L_GET_MEDIA_STREAM_E = 'L_GET_MEDIA_STREAM_E',
}

export enum RCLoggerStatus {
  /**
   * 失败
   */
  FAILED = 'fail',
  /**
   * 成功
   */
  SUCCESSED = 'success',
  /**
   * 超时
   */
  TIMEOUT = 'timeout',
  /**
   * 信息
   */
  INFO = 'info',
}
