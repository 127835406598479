import {
  ConversationType, ErrorCode, IReceivedMessage,
  IPromiseResult, KVString, IRuntime, assert, isNumber, EventEmitter, BasicLogger,
} from '@rongcloud/engine';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';

import {
  getTrackId, parseTrackId,
} from '../../helper';

import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import {
  IPublishAttrs, IRCRTCReportListener, IRoomEventListener, ISubscribeAttr, IRCRTCInitOptions,
  IAudioLevelChangeHandler, IRCRTCKickContent, IPubSuccessRes,
} from '../interfaces';
import Pinger from './Pinger';
import {
  getUUID, IPushOtherRooms, RCMediaService,
} from '../service';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';

import { RCLocalTrack, RCLocalVideoTrack } from '../tracks/RCLocalTrack';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from '../tracks/RCRemoteTrack';
import { RCMediaType } from '../enums/RCMediaType';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RCLivingType } from '../enums/RCLivingType';
import RCAudioLevelReport from './RCAudioLevelReport';
import { RCRTCPingResult } from '../enums/RCRTCPingResult';
import { RCRTCLiveRole } from '../enums/RCRTCLiveRole';
import { IOnRecvPKMsg } from './RCLivingPKHandler';

import { Invoker } from '../Invoker';
import { ReadableStore, Store } from '../Store';
import { JoinRoomCommand } from '../command/JoinRoomCommand';
import { IParseUserStateRes, ParseUserStateCommand } from '../command/ParseUserStateCommand';
import { ParseRemoteResCommand, ResourceMsgContent } from '../command/ParseRemoteResCommand';
import { SubscribeCommand } from '../command/SubscribeCommand';
import { UpdateSubscribeListCommand } from '../command/UpdateSubscribeListCommand';
import { UnsubscribeCommand } from '../command/UnsubscribeCommand';
import { PublishCommand } from '../command/PublishCommand';
import { UnpublishCommand } from '../command/UnpublishCommand';
import { LocalTrackMuteCommand } from '../command/LocalTrackMuteCommand';
import { RetryExchangeCommand } from '../command/RetryExchangeCommand';
import { OnSignalReconnectedCommand } from '../command/OnSignalReconnectedCommand';

import { RTCContext } from '../codec/RTCContext';
import { RTCMode } from '../enums/RTCMode';
import { RTCJoinType } from '../enums/RTCJoinType';
import { RTCApiType } from '../enums/inner/RTCApiType';
import { IRTCUserData, IJoinRTCRoomData } from '../codec/interface';
import { PullRTCRoomStatusCommand } from '../command/PullRTCRoomStatusCommand';
import ReportMediaActionLogger from '../logger/QualityLogger';
import { CommandEvent, CommandExecuteContext } from '../command/CommandExecuteContext';
import PolarisReporter from '../PolarisReporter';

export const RCAbstractRoomEvent = {
  /** 通知 RTCClient 清空房间引用 */
  LEAVE: 'evt-leave',
};

/**
 * 房间抽象基类
 */
export default abstract class RCAbstractRoom extends EventEmitter {
  /**
   * 房间保活 rtcPing
   */
  private readonly _pinger: Pinger

  /**
   * 北极星上报实例
   */
  protected readonly _polarisReport: PolarisReporter

  /**
   * 音量上报实例
   */
  private readonly _audioLevelReport: RCAudioLevelReport

  protected readonly _peerConnection: RCRTCPeerConnection

  protected readonly _invoker: Invoker

  protected readonly _store: ReadableStore

  private readonly _reportMediaActionLogger: ReportMediaActionLogger

  protected readonly _logger: BasicLogger;

  protected readonly _executeCtx: CommandExecuteContext;

  /**
   * RCRTCRoom 类的构造函数。
   * @param {RTCContext} _context - RTC上下文，
   * @param {IRuntime} _runtime - 运行时
   * @param {string} _roomId - 房间号
   * @param {RTCMode} _roomMode - RTC模式，
   * @param {RCMediaService} _service - RCMediaService
   * @param {IRCRTCInitOptions} _initOptions - IRCRTCInitOptions
   * @param {boolean} [isUpgrade] - 是否是升级，如果是升级则不会创建新的peerConnection
   * @param {boolean} [isMainRoom] - 无论是主房间，主房间都是用户创建的房间，子房间是用户创建的房间。
   * @param {string} _clientSessionId - 客户端会话 ID，用于标识客户端。
   */
  constructor(
    protected readonly _context: RTCContext,
    protected readonly _runtime: IRuntime,
    readonly _roomId: string,
    _roomMode: RTCMode,
    protected readonly _service: RCMediaService,
    protected readonly _initOptions: IRCRTCInitOptions,
    isUpgrade?: boolean,
    isMainRoom?: boolean,
    protected readonly _clientSessionId: string = getUUID(),
  ) {
    super();

    const logger = this._logger = this._context.logger;
    this._reportMediaActionLogger = new ReportMediaActionLogger(logger, this._roomId, this._clientSessionId, this._context.getCurrentId());
    this._polarisReport = new PolarisReporter(this._context, this._runtime, this._roomId, this);

    const currentId = this._context.getCurrentId();

    // 初始化但不直接持有引用，仅持有 readable 引用，避免数据竞争
    const store = new Store(logger, _clientSessionId, this._context.getAppkey(), _roomId, currentId, _roomMode, isUpgrade, isMainRoom);
    const peer = this._peerConnection = new RCRTCPeerConnection(logger, this._reTryExchange.bind(this), currentId, store, this._polarisReport, false);
    this._setPeerConnectionListener();

    // 初始化事务执行上下文
    const executeContext = this._executeCtx = new CommandExecuteContext(
      logger,
      this._service,
      _context,
      _runtime,
      peer,
      store,
      this._polarisReport,
      this._reportMediaActionLogger,
    );
    executeContext.getPushOtherRooms = () => this._getPushOtherRoomsParams();
    this.setCommandEventListener();

    this._invoker = new Invoker(executeContext, store);
    this._store = this._invoker.store;

    this._audioLevelReport = new RCAudioLevelReport(this._store, peer);
    peer.on(RCRTCPeerConnection.__INNER_AUDIOLEVEL_CHANGE__, this._audioLevelReport.audioLevelReport, this._audioLevelReport);

    this._pinger = new Pinger(this._context, this._roomId, _roomMode, this._initOptions.pingGap);
    this._pinger.onFailed = this._kickoff.bind(this);
    this._pinger.onPingResult = this._handlePingResult.bind(this);

    // 注册监听 rtc_ntf 信令数据，收到通知时拉取房间数据
    this._context.registerRTCSignalListener(async (buffer: Uint8Array) => {
      const {
        time, type, roomId,
      } = this._context.decodeRtcNotify(buffer);
      // 房间销毁后直接生吞发过来的事件
      if (this._invoker.isDestroyed()) {
        this._logger.info(RCLoggerTag.L_RTCNTF_PULL_ROOM_STATUS_O, JSON.stringify({
          isDestroyed: true,
          type,
          time,
          roomId,
        }));
        return;
      }
      switch (type) {
        case 2:
          if (roomId !== this._roomId) {
            return;
          }
          // 拉取房间数据
          // eslint-disable-next-line no-case-declarations
          const tracId = this._logger.createTraceId();
          this._logger.info(RCLoggerTag.L_RTCNTF_PULL_ROOM_STATUS_O, null, tracId);
          this._startPullRTCRoomStatus(roomId, tracId!, time);
          break;
      }
    });
  }

  /**
   * 注册 RCRTCPeerConnection 事件监听器
   */
  private _setPeerConnectionListener() {
    const peer = this._peerConnection;
    peer.on(RCRTCPeerConnection.__INNER_EVENT_TRACK_READY__, this._onTrackReady, this);

    peer.on(RCLocalTrack.__INNER_EVENT_MUTED_CHANGE__, this._onLocalTrackMuted, this);
    peer.on(RCLocalTrack.__INNER_EVENT_DESTROY__, this._onLocalTrackDestroied, this);

    // 仅首次连接上报
    peer.once(RCRTCPeerConnection.__INNER_ICE_CONNECTED__, (isPub: boolean) => {
      this._reportMediaActionLogger.reportIceFirstConnectData(isPub ? 'pub' : 'sub');
    }, this);

    peer.on(RCRTCPeerConnection.__INNER_ICE_STATE_CHANGE__, (data) => {
      const { status, time, iceCandidatePair } = data;
      iceCandidatePair && this._reportMediaActionLogger.recordQualityIceStatusData(iceCandidatePair, status, time);
    });
  }

  /** 注册命令执行中的事件监听器 */
  private setCommandEventListener(): void {
    const ctx = this._executeCtx;
    ctx.on(CommandEvent.PEER_CONNECTION_CLOSED_BY_EXCEPTION, this._rtcpeerClosed, this);
    ctx.on(CommandEvent.USER_JOIN, this._callAppListener.bind(this, 'onUserJoin'), this);
    ctx.on(CommandEvent.USER_LEAVE, this._callAppListener.bind(this, 'onUserLeave'), this);
    ctx.on(CommandEvent.TRACKS_PUBLISH, this._onTrackPublish, this);
    ctx.on(CommandEvent.TRACKS_UNPUBLISH, this._onTrackUnpublish, this);
    ctx.on(CommandEvent.AUDIO_MUTE_CHANGE, this._onAudioMuteChange, this);
    ctx.on(CommandEvent.VIDEO_MUTE_CHANGE, this._onVideoMuteChange, this);
    ctx.on(CommandEvent.CDN_ENABLE_CHANGE, this._callAppListener.bind(this, 'onCDNEnableChange'), this);
    ctx.on(CommandEvent.USER_STATE_CHANGE, this._dealUserAppListener, this);
  }

  /**
   * 拉取房间数据
   * @param roomId 房间 id
   */
  private async _startPullRTCRoomStatus(roomId: string, tracId: string, targetPullVersion: number) {
    await this._invoker.push(new PullRTCRoomStatusCommand(roomId, tracId, this._context, targetPullVersion));
  }

  async __innerInit(mode: RTCMode, joinType?: RTCJoinType, livingType?: RCLivingType, innerUserDatas?: IRTCUserData, outerUserDatas?: IRTCUserData, traceId?: string): Promise<{ code: RCRTCCode | ErrorCode, data?: IJoinRTCRoomData }> {
    const { code, data } = await this._invoker.push(new JoinRoomCommand(this._roomId, mode, joinType, livingType, innerUserDatas, outerUserDatas, traceId));
    if (code !== RCRTCCode.SUCCESS) {
      return { code };
    }

    // 服务下发的 rtcping 超时时间，单位为秒
    this._initWithRoomData(data!.offlineKickTime);

    return { code, data };
  }

  protected _initWithRoomData(offlineKickTime: number) {
    // 开始心跳，心跳失败时主动退出房间
    this._pinger.start(offlineKickTime * 1000);
    this._polarisReport.sendR1();
  }

  private _handlePingResult(result: RCRTCPingResult, dataVersion?: number) {
    if (result === RCRTCPingResult.SUCCESS) {
      const oldVersion = this._store.getRoomStatusVersion();
      // 本地 version 小于 rtcping 返回的 version，需拉取房间最新数据
      if (dataVersion! > oldVersion && this._store.getSupportNtf()) {
        const traceId = this._logger.createTraceId();
        this._logger.info(RCLoggerTag.L_RTCPING_PULL_ROOM_STATUS_O, JSON.stringify({
          dataVersion,
        }), traceId);
        this._startPullRTCRoomStatus(this._roomId, traceId!, dataVersion!);
      }
    }
    this._callAppListener('onPing', result);
  }

  /**
   * 设置房间上行资源的总码率配置
   * @deprecated use RCLocalTrack.setBitrate instead of setBitrate
   * @description
   * * 自 v5.1.0 版本开始，推荐使用 `RCLocalTrack.setBitrate` 对不同流分别指定码率。
   * * 该方法仅在 SDP `plan-b` 协议下（Chrome 92 与 Safari 11 之前的版本）有效。
   * @param max 音视频发送码率上限，不可小于 200 且不可小于 `min`
   * @param min 音视频发送码率下限，默认值为 1，且不可小于 1，不可大于 `max`
   * @param start 起始码率，默认为码率上限的 70%
   */
  public setBitrate(max: number, min: number, start?: number) {
    this._logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_SETBITRATE_O, '`RCAbstractRoom.setBitrate` will be deprecated, use `RCLocalTrack.setBitrate` instead.');

    assert('max', max, (value) => isNumber(value) && value > Math.max(min || 1, 200), true);
    assert('min', min, (value) => isNumber(value) && value >= 1 && (isNumber(max) ? value < max! : true), true);
    assert('start', start, (value) => isNumber(value) && value > min && value <= max);

    this._peerConnection.setBitrate(min, max, start);
  }

  private _onTrackReady(evt: RTCTrackEvent) {
    const msid = evt.streams[0].id;
    const { track } = evt.receiver;
    const trackId = [msid, track.kind === 'audio' ? RCMediaType.AUDIO_ONLY : RCMediaType.VIDEO_ONLY].join('_');

    const rTrack = this._store.getRemoteTrack(trackId);
    if (!rTrack) {
      this._logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_ONTRACKREADY_O, `cannot found remote track ${track.id}`);
      return;
    }
    rTrack.__innerSetMediaStreamTrack(track);
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      eventType: 'onTrackReady',
      trackId: rTrack.getTrackId(),
    }));

    this._callAppListener('onTrackReady', rTrack);
  }

  protected _callAppListener(eventType: keyof IRoomEventListener, ...attrs: any[]) {
    const handle = this._appListener?.[eventType] as Function;
    if (!handle) {
      return;
    }
    try {
      handle(...attrs);
    } catch (error: any) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, error?.stack);
    }
  }

  private _onTrackUnpublish(tracks: RCRemoteTrack[]) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      eventType: 'onTrackUnpublish',
      trackIds: tracks?.map((track) => track.getTrackId()),
    }));
    this._callAppListener('onTrackUnpublish', tracks);
  }

  public __parseInnerMessage(message: IReceivedMessage, traceId: string) {
    const { targetId: roomId, conversationType } = message;

    // 为 RTC 消息，但不属于当前房间的不处理
    if (roomId !== this._roomId) {
      this._logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_RECEIVE_MESSAGE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        message: `roomId is different, msg roomId-> ${roomId}, this._roomId-> ${this._roomId} `,
      }), traceId);
      return true;
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_RECEIVE_MESSAGE_O, `recv inner msg -> message: ${JSON.stringify(message)} | roomid: ${this._roomId}`, traceId);

    const { content } = message;

    switch (message.messageType) {
      case RCRTCMessageType.KICK:
        this._kickoff(true, content as IRCRTCKickContent);
        break;
      case RCRTCMessageType.STATE:
        this._stateHandle(content, traceId);
        break;
      case RCRTCMessageType.MODIFY:
      case RCRTCMessageType.PUBLISH:
      case RCRTCMessageType.UNPUBLISH:
      case RCRTCMessageType.TOTAL_CONTENT_RESOURCE:
        this._resourceHandle(content, message.messageType, message.senderUserId, message.sentTime, traceId);
        break;
      case RCRTCMessageType.ROOM_NOTIFY:
        this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          eventType: 'onRoomAttributeChange',
          messageType: message.messageType,
          messageContent: message.content,
        }));
        this._callAppListener('onRoomAttributeChange', message.messageType, message.content);
        break;
      case RCRTCMessageType.USER_NOTIFY:
        this._logger.warn(RCLoggerTag.L_OLD_DAILY_RECORD_O, `TODO: ${RCRTCMessageType.USER_NOTIFY}`);
        break;
      case RCRTCMessageType.PK_INVITE:
      case RCRTCMessageType.PK_CANCEL_INVITE:
      case RCRTCMessageType.PK_INVITE_TIMEOUT:
      case RCRTCMessageType.PK_INVITE_ANSWER:
      case RCRTCMessageType.PK_END:
        this._onRecvPKMsg && this._onRecvPKMsg(message);
        break;
      case RCRTCMessageType.OTHER_ROOM_OFFLINE:
        // TODO 处理加入的 PK 房间断线情况
        break;
      default:
        this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          eventType: 'onMessageReceive',
          messageType: message.messageType,
          messageContent: message.content,
          senderUserId: message.senderUserId,
          messageUId: message.messageUId,
        }));
        this._callAppListener('onMessageReceive', message.messageType, content, message.senderUserId, message.messageUId);
        break;
    }
    return true;
  }

  /**
   * 被踢出房间通知
   * @param byServer
   * * 当值为 false 时，说明本端 rtcPing 超时
   * * 当值为 true 时，说明本端收到被踢出房间通知
   */
  private async _kickoff(byServer: boolean, content?: IRCRTCKickContent) {
    if (this._invoker.isDestroyed()) {
      return;
    }

    this._logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_ONKICKOFF_O, `onKickOff -> byServer: ${byServer}, kickContent: ${JSON.stringify(content)}`);
    this.emit(RCAbstractRoomEvent.LEAVE);
    this._leaveHandle(!byServer);
    // 扩展字段，备注用户为什么被踢出房间
    let kickExtra;
    let kickType;
    if (byServer) {
      (content?.users || []).forEach((item) => {
        if (item.userId === this._context.getCurrentId()) {
          kickType = item.type;
          kickExtra = item.kickExtra;
          // 连通率相关埋点-异常踢出上报
          this._reportMediaActionLogger.reportQualityKickedData(kickType);
        }
      });
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      eventType: 'onKickOff',
      byServer,
      kickType,
      kickExtra,
    }));
    this._callAppListener('onKickOff', byServer, kickType, kickExtra);
  }

  private _rtcpeerClosed() {
    this.emit(RCAbstractRoomEvent.LEAVE);
    this._leaveHandle(true);
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, 'onRTCPeerConnectionCloseByException');
    this._callAppListener('onRTCPeerConnectionCloseByException');
  }

  /**
   * 处理资源变更事件
   * @param content
   * @param messageType 消息类型
   * @param userId 消息发送者
   */
  protected async _resourceHandle(
    content: ResourceMsgContent,
    messageType: RCRTCMessageType.PUBLISH | RCRTCMessageType.UNPUBLISH | RCRTCMessageType.MODIFY | RCRTCMessageType.TOTAL_CONTENT_RESOURCE,
    userId: string,
    sentTime: number,
    traceId: string,
  ) {
    const { uris, ignore } = content;

    // 内置 CDN 为自动时，先收到 cdn_uris，无 uris 时，不用再对比资源
    if (ignore || !uris) {
      return;
    }

    this._invoker.push(new ParseRemoteResCommand(
      content,
      messageType,
      userId,
      traceId,
      sentTime,
    ));
  }

  private _onTrackPublish(tracks: RCRemoteTrack[], senderId?: string, sentTime?: number) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      eventType: 'onTrackPublish',
      trackIds: tracks?.map((track) => track.getTrackId()),
    }));
    this._callAppListener('onTrackPublish', tracks);
  }

  /**
   * 处理 `RCRTCMessageType.STATE` 消息
   * @param content
   */
  private async _stateHandle(content: {
    users: {
      userId: string,
      /**
       * 状态值，其中
       * * `0`: 进入房间
       * * `1`: 退出房间
       * * `2`: 用户离线，当作离开房间处理
       */
      state: '0' | '1' | '2',
      /**
       * 角色切换类型，0 代表非切换身份发生的加入、退出房间行为
       */
      switchRoleType: RCRTCLiveRole | 0,
      /**
       * 加房间的身份标识，保存主房间 roomId
       */
      extra?: {[key: string]: string}
    }[]
  }, traceId: string) {
    const res = await this._invoker.push(new ParseUserStateCommand(content, traceId));
    const userData: IParseUserStateRes = res.data!;
    this._dealUserAppListener(userData);
  }

  /**
   * 处理人员应用层事件
   */
  private _dealUserAppListener(userData: IParseUserStateRes) {
    const {
      left, joined, upgrade, downgrade,
    } = userData;

    /**
     * 通知业务层
     * 有 onSwitchRole 监听时，按 switchRole 分“角色切换、加入房间、退出房间”抛出
     */
    if (this._appListener?.onSwitchRole) {
      upgrade?.forEach((userId) => {
        this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          eventType: 'onSwitchRole',
          userId,
          role: RCRTCLiveRole.ANCHOR,
        }));
        this._callAppListener('onSwitchRole', userId, RCRTCLiveRole.ANCHOR);
      });
      downgrade?.forEach((userId) => {
        this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          eventType: 'onSwitchRole',
          userId,
          role: RCRTCLiveRole.AUDIENCE,
        }));
        this._callAppListener('onSwitchRole', userId, RCRTCLiveRole.AUDIENCE);
      });

      if (joined.length > 0) {
        this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          eventType: 'onUserJoin',
          joined,
        }));
        this._callAppListener('onUserJoin', joined);
      }
      if (left.length > 0) {
        this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          eventType: 'onUserLeave',
          left,
        }));
        this._callAppListener('onUserLeave', left);
      }
      return;
    }

    const allJoined = [...joined, ...upgrade];
    const allLeft = [...left, ...downgrade];

    /**
     * 通知业务层
     * 无 onSwitchRole 监听时，统一按“加入房间、退出房间”抛出
     */
    if (allJoined.length > 0) {
      this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        eventType: 'onUserJoin',
        allJoined,
      }));
      this._callAppListener('onUserJoin', allJoined);
    }
    if (allLeft.length > 0) {
      allLeft.length && this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        eventType: 'onUserLeave',
        allLeft,
      }));
      this._callAppListener('onUserLeave', allLeft);
    }
  }

  /**
   * 获取房间 Id
   */
  public getRoomId(): string {
    return this._roomId;
  }

  /**
   * 获取当前 userId
   */
  public getCrtUserId(): string {
    return this._context.getCurrentId();
  }

  /**
   * 获取远程用户列表，不包含当前用户
   */
  public getRemoteUserIds(): string[] {
    return this._store.getRemoteUserIds();
  }

  /**
   * 获取所有房间已发布的远端资源列表
   * @returns
   */
  public getRemoteTracks(): RCRemoteTrack[] {
    const tracks: RCRemoteTrack[] = [];
    this.getRemoteUserIds().forEach((id) => {
      tracks.push(...this.getRemoteTracksByUserId(id));
    });
    return tracks;
  }

  /**
   * 获取远端用户的资源列表
   * @param userId
   * @returns
   */
  public getRemoteTracksByUserId(userId: string): RCRemoteTrack[] {
    return this._store.getRemoteTracksByUserId(userId);
  }

  /**
   * 获取房间当前会话 Id，当房间内已无成员时房间会回收，重新加入时 sessionId 将更新
   */
  public getSessionId(): string {
    return this._store.getSessionId();
  }

  /**
   * 向房间内发消息
   * @param name 消息名称
   * @param content 消息内容
   */
  public async sendMessage(name: string, content: any): Promise<{ code: RCRTCCode }> {
    const tracId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_SEND_MESSAGE_T, JSON.stringify({
      name,
      content,
    }), tracId);

    // 该接口只能用于发状态消息
    const { code } = await this._context.sendMessage(ConversationType.RTC_ROOM, this._roomId, {
      messageType: name,
      content,
      isStatusMessage: true,
    });
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_SEND_MESSAGE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), tracId);

      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_SEND_MESSAGE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      name,
      content,
    }), tracId);

    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 设置房间属性
   * @param key 属性名
   * @param value 属性值
   * @param message 是否在设置属性的时候携带消息内容，传空则不往房间中发送消息
   * @param isInner RTC 业务内部使用参数，用户忽略
   */
  public async setRoomAttribute(
    key: string,
    value: string,
    message?: {
      name: string,
      content: string
    },
    isInner: boolean = false,
  ): Promise<{ code: RCRTCCode }> {
    const tracId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_SET_ROOM_ATTRIBUTE_T, JSON.stringify({
      roomId: this._roomId,
      key,
      value,
      message,
      isInner,
    }), tracId);

    const code = await this._context.setRTCData(this._roomId, key, value, isInner, RTCApiType.ROOM, message);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_SET_ROOM_ATTRIBUTE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), tracId);

      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_SET_ROOM_ATTRIBUTE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomId: this._roomId,
      key,
      value,
      message,
      isInner,
    }), tracId);

    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 删除房间属性
   * @param keys 待删除的属性名数组
   * @param message 是否在删除属性的时候携带消息内容，传空则不往房间中发送消息
   * @param isInner RTC 业务内部使用参数，用户忽略
   */
  public async deleteRoomAttributes(
    keys: string[],
    message?: {
      name: string,
      content: string
    },
    isInner: boolean = false,
  ): Promise<{ code: RCRTCCode }> {
    const tracId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_DELETE_ROOM_ATTRIBUTE_T, JSON.stringify({
      roomId: this._roomId,
      keys,
      message,
      isInner,
    }), tracId);

    const code = await this._context.removeRTCData(this._roomId, keys, isInner, RTCApiType.ROOM, message);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_DELETE_ROOM_ATTRIBUTE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), tracId);

      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_DELETE_ROOM_ATTRIBUTE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomId: this._roomId,
      keys,
      message,
      isInner,
    }), tracId);

    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 获取房间属性
   * @param keys 要查询的属性名数组，当数组长度为空时，取所有已设置的 kv 值
   * @param isInner RTC 业务内部使用参数，用户忽略
   */
  public async getRoomAttributes(keys: string[] = [], isInner: boolean = false): Promise<{ code: RCRTCCode, data?: KVString }> {
    const tracId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_GET_ROOM_ATTRIBUTE_T, JSON.stringify({
      roomId: this._roomId,
      keys,
      isInner,
    }), tracId);

    const { code, data } = await this._context.getRTCData(this._roomId, keys, isInner, RTCApiType.ROOM);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_ABSTRACT_ROOM_GET_ROOM_ATTRIBUTE_R, `GetRoomAttributes Failed: ${code}`, tracId);
      return { code: RCRTCCode.SIGNAL_ERROR };
    }

    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_DELETE_ROOM_ATTRIBUTE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      data,
    }), tracId);

    return { code: RCRTCCode.SUCCESS, data };
  }

  /**
   * 设置当前用户属性（暂不开放）
   * @param key 属性名
   * @param value 属性值
   * @param message 是否在设置属性的时候携带消息内容，传空则不往房间中发送消息
   */
  private _setUserAttributeValue(
    key: string,
    value: string,
    message?: {
      name: string,
      content: string
    },
  ): Promise<number> {
    return this._context.setRTCData(this._roomId, key, value, false, RTCApiType.PERSON, message);
  }

  /**
   * 删除当前用户属性（暂不开放）
   * @param keys 待删除的属性名数组
   * @param message 是否在删除属性的时候携带消息内容，传空则不往房间中发送消息
   */
  private _deleteUserAttributes(
    keys: string[],
    message?: {
      name: string,
      content: string
    },
  ): Promise<number> {
    return this._context.removeRTCData(this._roomId, keys, false, RTCApiType.PERSON, message);
  }

  /**
   * 获取当前用户属性（暂不开放）
   * @param keys 要查询的属性名数组
   */
  private _getUserAttributes(keys: string[]): IPromiseResult<KVString> {
    return this._context.getRTCData(this._roomId, keys, false, RTCApiType.PERSON);
  }

  /**
   * 查询房间是否已销毁
   */
  public isDestroyed(): boolean {
    return this._invoker.isDestroyed();
  }

  /**
   * 退出并销毁当前房间实例，退出后该房间的所有方法将不可用
   */
  public __destroy(quitRoom: boolean): Promise<void> {
    return this._leaveHandle(quitRoom);
  }

  /**
   * 退出房间之前禁用所有远端资源，避免退出动作耗时过长，
   * 导致在未完全退出的过程中仍能听到房间内的声音问题
   */
  private _muteRemoteTracksBeforeQuit() {
    const remoteTracks = Object.values(this._store.getRemoteTracks());
    if (!remoteTracks.length) {
      return;
    }
    remoteTracks.forEach((track) => track.mute());
  }

  private async _leaveHandle(quitRoom: boolean) {
    if (this._invoker.isDestroyed()) {
      return;
    }

    // 清空待执行队列，直接通知房间已销毁
    this._invoker.destroy();

    // 静默所有远端音视频
    this._muteRemoteTracksBeforeQuit();

    if (quitRoom) {
      // 退出 signal 房间
      await this._context.quitRTCRoom(this._roomId);
    }

    // 退出主房间时，退出所有 PK 房间
    this._store.isMainRoom && await this._quitAllPKRoom();

    // 停止 rtcPing 计时
    this._pinger.stop();

    // 中断 peerConnection 与 MediaServer 的 UDP 连接
    await this._service.exit(this._store.getRTCRequestHeader());

    /**
     * 释放 PCManager 上的资源
     */
    this._releasePCManager();
  }

  /**
   * 释放 PCManager 上的资源
   */
  private _releasePCManager() {
    const pc = this._peerConnection;
    // 移除 pc 上的 track，销毁 pc 连接
    pc.removeAllLocalTrack();
    pc.destroy();
  }

  private _onLocalTrackDestroied(localTrack: RCLocalTrack) {
    // 本地流已销毁，需取消发布此流(排除小流)
    const isTinyVideoTrack = (localTrack instanceof RCLocalVideoTrack) && localTrack.__isTiny();
    if (!isTinyVideoTrack) {
      this.unpublish([localTrack]);
    }
  }

  /**
   * 本端流状态修改，需通知房间内其他成员
   * @param localTrack
   */
  private async _onLocalTrackMuted(localTrack: RCLocalTrack, resolve: (code: RCRTCCode) => void) {
    const { code } = await this._invoker.push(new LocalTrackMuteCommand(localTrack));
    resolve(code);
  }

  /**
   * 增量发布资源，若发布的资源 tag 及媒体类型重复，后者将覆盖前者进行发布。
   * @param tracks 待发布的 RCLocalTrack 实例
   * @returns
   */
  async publish(tracks: (RCLocalTrack | IPublishAttrs)[]): Promise<IPubSuccessRes> {
    return this._invoker.push(new PublishCommand(tracks));
  }

  /**
   * 获取跨房间连麦需携带参数 pushOtherRooms 的值
   */
  protected _getPushOtherRoomsParams(): IPushOtherRooms[] {
    return [];
  }

  /**
   * ice 断线后，尝试重新走 exchange
   */
  protected async _reTryExchange() {
    this._invoker.push(new RetryExchangeCommand());
  }

  /**
   * 增量取消资源发布，若相应资源中存在小流资源，则同时取消发布
   * @param tracks 取消发布的 RCLocalTrack 列表
   */
  async unpublish(tracks: RCLocalTrack[]): Promise<IPubSuccessRes> {
    return this._invoker.push(new UnpublishCommand(tracks));
  }

  /**
   * resourceId 有效性验证
   * @param resourceId
   */
  protected _isValidResourceId(resourceId: string): boolean {
    const { userId } = parseTrackId(resourceId);
    return !!this._store.getResourcesByUserId(userId)?.find((item) => getTrackId(item) === resourceId);
  }

  /**
   * 订阅资源
   * @param tracks
   */
  public async subscribe(tracks: (RCRemoteTrack | ISubscribeAttr)[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    return this._invoker.push(new SubscribeCommand(tracks, undefined, undefined));
  }

  /**
   * 取消订阅资源
   * @param tracks 预取消远端资源
   */
  public async unsubscribe(tracks: RCRemoteTrack[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    const traceId = this._logger.createTraceId()!;
    this._logger.info(RCLoggerTag.L_APP_CALL_UNSUBSCRIBE_O, undefined, traceId);
    return this._invoker.push(new UnsubscribeCommand(tracks, traceId));
  }

  /**
   * 强制修改订阅列表，仅订阅数组中的资源，取消订阅其他已订阅资源。
   * 当参数为 `[]` 时，意味着不再订阅任何资源
   * @param tracks 变更的资源列表
   */
  public async updateSubList(tracks: (RCRemoteTrack | ISubscribeAttr)[]): Promise<{ code: RCRTCCode, failedList?: ISubscribeAttr[] }> {
    return this._invoker.push(new UpdateSubscribeListCommand(tracks, true, this._logger.createTraceId()));
  }

  /**
   * 获取已发布的本地资源
   * @param trackId
   * @returns
   */
  public getLocalTrack(trackId: string): RCLocalTrack | null {
    return this._peerConnection.getLocalTrack(trackId);
  }

  /**
   * 获取所有已发布的资源
   */
  public getLocalTracks(): RCLocalTrack[] {
    return this._peerConnection.getLocalTracks();
  }

  /**
   * 根据 trackId 获取房间内的远端资源
   * @param trackId
   * @returns
   */
  public getRemoteTrack(trackId: string): RCRemoteAudioTrack | RCRemoteVideoTrack | null {
    return this._store.getRemoteTrack(trackId) || null;
  }

  /**
   * 获取当前已经订阅的全量资源
   * returns subscribedTracks ISubscribeAttr[]
   */
  public get subscribedTracks(): ISubscribeAttr[] {
    // 浅Copy 获取已经订阅的列表
    return this._store.getSubscribedList().slice();
  }

  private _appListener: IRoomEventListener | null = null

  /**
   * 注册事件监听器，多次注册会导致后者覆盖前者，可以通过使用 `registerRoomEventListener(null)` 取消注册
   * @param listener
   */
  registerRoomEventListener(listener: IRoomEventListener | null) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_REGISTER_ROOM_EVENT_LISTENER_O, listener ? Object.keys(listener).join(',') : null);
    this._appListener = listener;
  }

  /**
   * 注册房间数据监控
   * @param listener
   * @description 该方法暂仅支持 Chrome 浏览器
   */
  registerReportListener(listener: IRCRTCReportListener | null) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_REGISTER_REPORT_LISTENER_O, listener ? Object.keys(listener).join(',') : null);
    this._peerConnection.registerReportListener(listener);
  }

  /**
   * 音量上报
   * @param handler 音量事件监听函数
   * @param _ - 参数已废弃，SDK 默认以每秒一次进行回调~~上报时间间隔~~
   */
  onAudioLevelChange(handler: IAudioLevelChangeHandler | null, _?: number) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_AUDIO_LEVEL_O);
    this._audioLevelReport.onAudioLevelChange(handler);
  }

  /**
   * 断线重连后尝试补发断线过程中的通知信息
   */
  __onReconnected(livingType?: RCLivingType) {
    this._invoker.push(new OnSignalReconnectedCommand(livingType));
  }

  private _onAudioMuteChange(audioTrack: RCRemoteAudioTrack) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      eventType: 'onAudioMuteChange',
      trackId: audioTrack.getTrackId(),
    }));
    this._callAppListener('onAudioMuteChange', audioTrack);
  }

  private _onVideoMuteChange(videoTrack: RCRemoteVideoTrack) {
    this._logger.info(RCLoggerTag.L_ABSTRACT_ROOM_CALL_APP_LISTENER_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      eventType: 'onVideoMuteChange',
      trackId: videoTrack.getTrackId(),
    }));
    this._callAppListener('onVideoMuteChange', videoTrack);
  }

  /**
   * 存储连麦监听事件
   */
  private _onRecvPKMsg: IOnRecvPKMsg | null = null

  /**
   * 注册 PK 业务监听方法
   */
  protected _registerPKMsgListener(listener: IOnRecvPKMsg | null) {
    this._onRecvPKMsg = listener;
  }

  /**
   * 退出 PK 房间
   */
  protected _quitAllPKRoom() {}

  public getClientSessionId() {
    return this._store.clientSessionId;
  }
}
