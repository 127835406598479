export enum RCKickReason {
  /**
   * Server 主动踢（掉 Server API 踢出）
   */
  SERVER_KICK = 1,
  /**
   * 其他设备登陆后，本端被踢
   */
  OTHER_KICK = 2
}
