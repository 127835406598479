import { isNull } from '../../helper';

export const getBitrate = (dTime: number, dBytes: number): number => {
  // 码率计算：字节数差比时间差，单位 kbps
  // const bitrate = Math.round(dBytes * 8 / 1000 / (dTime / 1000))
  const bitrate = Math.round(dBytes * 8 / dTime);
  return bitrate;
};

/**
 * 限制浮点数小数位的有效位数
 * @param value
 * @param fractionDigits 小数点后保留的有效数字位数
 */
export const fixed = (value: number, fractionDigits: number = 2): number => {
  // return parseFloat(value.toFixed(fractionDigits))
  const rate = 10 ** fractionDigits;
  return Math.round(value * rate) / rate;
};

/**
 * 处理音量 * 100，并向下取整
 */
export const handleAudioLevel = (audioLevel: number, factor: number = 2): number => {
  // if (audioLevel > 1) {
  //   audioLevel /= 32767;
  // }
  (audioLevel > 1) && (audioLevel = 1);
  const rate = 10 ** factor;
  return Math.round(audioLevel * rate);
};
/**
 * 计算丢包率
 * @param packetsLost 当前统计丢包总数
 * @param packets 当前统计总包数
 * @param prePacketsLost 前次统计丢包总数
 * @param prePackets 前次统计总包数
 */
export const getPacketsLostRate = (packetsLost: number, packets: number, prePacketsLost?: number, prePackets?: number) => {
  // 总包数为 0 时，无丢包率
  if (packets === 0) {
    return 0;
  }
  // 没有前次统计记录，当次的丢包可直接计算丢包率
  if (!prePacketsLost || !prePackets) {
    return fixed(packetsLost / packets, 3);
  }

  const dPackets = packets - prePackets;
  if (dPackets === 0) {
    // 总包数无变更，不存在丢包概念
    return 0;
  }
  const dPacketsLost = packetsLost - prePacketsLost;
  /**
   * 每秒发送总码率(bitrateSend)、每秒接收总码率(bitrateRecv)、丢包率(packetsLostRate)计算依赖本地存储的前一次数据
   * 但订阅关系发生变化时，存在 bytesSent 从 0 开始算的情况，会导致计算出的 bitrateSend 为负数（此时丢包数没有从 0 开始算）
   * 鉴于存在一系列超出预期范围的情况，bitrateSend、bitrateRecv、packetsLostRate 为负数时，给业务层抛 0
   */
  if (dPacketsLost < 0 || dPackets < 0) {
    return 0;
  }
  return fixed(dPacketsLost / dPackets, 3);
};

export const senderHasRemoteData = (sender: {[key: string]: any}) : boolean => {
  const { jitter, rtt, packetsLostRate } = sender;
  if (isNull(jitter) && isNull(rtt) && packetsLostRate === 0) {
    return false;
  }
  return true;
};

export const senderHasMediaData = (sender: {[key: string]: any}) : boolean => {
  const { frameWidth, frameHeight } = sender;
  if (isNull(frameWidth) && isNull(frameHeight)) {
    return false;
  }
  return true;
};
