import {
  isArray, validate,
} from '@rongcloud/engine';
import { getTrackIdFromAttr } from '../../helper';
import { R2Action } from '../enums/inner/R2Action';
import { R2Status } from '../enums/inner/R2Status';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { ISubscribeAttr } from '../interfaces';
import { Invoker } from '../Invoker';
import { Store } from '../Store';
import { RCRemoteTrack } from '../tracks/RCRemoteTrack';
import { BaseCommand, ICommandResult } from './BaseCommand';
import { UpdateSubscribeListCommand } from './UpdateSubscribeListCommand';
import { RCCommandKind } from '../enums/RCCommandKind';
import { RCRTCResourceAction } from '../logger/IQualityReportData';
import { CommandExecuteContext } from './CommandExecuteContext';

export class UnsubscribeCommand extends BaseCommand<ISubscribeAttr[]> {
  constructor(
    private tracks: RCRemoteTrack[],
    private traceId: string,
  ) {
    super();
  }

  get kind(): RCCommandKind {
    return RCCommandKind.UNSUBSCRIBE;
  }

  async execute(executeCtx: CommandExecuteContext, store: Store, invoker: Invoker): Promise<ICommandResult<ISubscribeAttr[]>> {
    const { logger, polarisReport } = executeCtx;
    const { tracks } = this;
    const { roomId } = store;

    logger.info(RCLoggerTag.L_ABSTRACT_ROOM_UNSUBSCRIBE_T, JSON.stringify({
      unSubscribeTrackIds: tracks.map(getTrackIdFromAttr),
    }), this.traceId);

    if (!validate('tracks', tracks, () => isArray(tracks) && tracks.length > 0 && tracks.every((item) => item instanceof RCRemoteTrack), true)) {
      logger.warn(RCLoggerTag.L_ABSTRACT_ROOM_UNSUBSCRIBE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> tracks',
      }), this.traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    // 计算剩余订阅列表
    const crtSubList = store.getSubscribedList()
      .map((item) => ({ ...item }))
      .filter((item) => tracks.every((track) => track.getTrackId() !== item.track.getTrackId()));

    // 北极星上报
    polarisReport.sendR2(R2Action.SUBSCRIBE, R2Status.END, tracks.map((item) => item.getTrackId()));

    return new UpdateSubscribeListCommand(crtSubList, false, this.traceId, RCRTCResourceAction.UNSUB, this.tracks).execute(executeCtx, store, invoker);
  }
}
