import {
  ErrorCode, ILogger, IRTCUsers, RTCMode,
} from '@rongcloud/engine';
import {
  deepCopyResources, diffPublishResources, getTrackId, parseRoomData, parseTrackId,
} from '../../helper';
import { RCRTCMessageType } from '../enums/inner/RCRTCMessageType';
import { RCStreamType } from '../enums/inner/RCStreamType';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RCRTCCode } from '../enums/RCRTCCode';
import { RTCApiType } from '../enums/inner/RTCApiType';
import {
  IPublishedResource, IPullRTCRoomUsersData, IRoomStatus, ISubscribeAttr, RoomData,
} from '../interfaces';
import { ICDNUris, IExchangeReqBody } from '../service';
import { ReadableStore, Store } from '../Store';
import RCRTCPeerConnection from '../webrtc/RCRTCPeerConnection';
import { RTCContext } from '../codec/RTCContext';
import { RCRemoteAudioTrack, RCRemoteTrack, RCRemoteVideoTrack } from '../tracks/RCRemoteTrack';
import { OnRemoteUserUnpubCommand } from './OnRemoteUserUnpubCommand';
import { Invoker } from '../Invoker';
import { RCMediaType } from '../enums/RCMediaType';
import { UnsubscribeCommand } from './UnsubscribeCommand';
import { CommandEvent, CommandExecuteContext } from './CommandExecuteContext';

/**
 * 获取 exchange 接口的请求体数据
 * @param subscribeList 订阅清单
 * @param iceRestart
 * @param pc RCRTCPeerConnection 实例
 * @param store: Store
 */
export async function createExchangeParams(subscribeList: ISubscribeAttr[], iceRestart: boolean, pc: RCRTCPeerConnection, store: ReadableStore): Promise<IExchangeReqBody> {
  const offer = await pc.createOffer(iceRestart);
  const reqBody: IExchangeReqBody = {
    sdp: offer,
    extend: JSON.stringify({
      resolutionInfo: pc.getOutboundVideoInfo(),
    }),
    subscribeList: subscribeList.filter((item) => {
      const trackId = item.track.getTrackId();
      const { userId } = parseTrackId(trackId);
      const res = store.getResourcesByUserId(userId);
      if (!res) {
        return false;
      }
      const isInclude = res.filter((item) => trackId === `${item.msid}_${item.mediaType}`).length;
      return isInclude;
    }).map((item) => ({
      simulcast: item.subTiny ? RCStreamType.TINY : RCStreamType.NORMAL,
      resolution: '',
      // uri: this._getResourceById(item.track.getTrackId())!.uri
      uri: store.getPublishedResourceByTrackId(item.track.getTrackId())!.uri,
    })),
    switchstream: false,
    // switchstream: !!this._initOptions.autoSwitchStream
  };
  return reqBody;
}

/**
 * 扩散 cdn_uris 资源
 */
async function spreadCDNInfo(context: RTCContext, roomId: string, CDNUris: ICDNUris): Promise<{ code: RCRTCCode }> {
  const traceId = context.logger.createTraceId();
  context.logger.info(RCLoggerTag.L_LIVING_ROOM_SPREAD_CDN_INFO_T, JSON.stringify({
    CDNUris,
  }), traceId);

  const code = await context.setRTCCDNUris(roomId, RCRTCMessageType.TOTAL_CONTENT_RESOURCE, JSON.stringify([CDNUris]));

  if (code !== ErrorCode.SUCCESS) {
    context.logger.error(RCLoggerTag.L_LIVING_ROOM_SPREAD_CDN_INFO_R, `status: ${RCLoggerStatus.FAILED}, code: ${code}`, traceId);
    return { code: RCRTCCode.SIGNAL_ERROR };
  }

  context.logger.info(RCLoggerTag.L_LIVING_ROOM_SPREAD_CDN_INFO_R, `status: ${RCLoggerStatus.SUCCESSED}`, traceId);
  return { code: RCRTCCode.SUCCESS };
}

/**
 * 给房间设置 CDN 数据
 */
export async function setRoomCDNInfo(context: RTCContext, roomId: string, CDNUris: ICDNUris): Promise<{ code: RCRTCCode }> {
  const traceId = context.logger.createTraceId();
  context.logger.info(RCLoggerTag.L_LIVING_ROOM_SET_ROOM_CDN_INFO_T, `CDNUris: ${CDNUris}`, traceId);

  const code = await context.setRTCData(roomId, 'cdn_uris', JSON.stringify([CDNUris]), true, RTCApiType.ROOM);

  if (code !== ErrorCode.SUCCESS) {
    context.logger.error(RCLoggerTag.L_LIVING_ROOM_SET_ROOM_CDN_INFO_R, `status: ${RCLoggerStatus.FAILED}, code: ${code}`, traceId);
    return { code: RCRTCCode.SIGNAL_ERROR };
  }

  context.logger.info(RCLoggerTag.L_LIVING_ROOM_SET_ROOM_CDN_INFO_R, `status: ${RCLoggerStatus.SUCCESSED}`, traceId);
  return { code: RCRTCCode.SUCCESS };
}

/**
 * 开启、停用 CDN 推资源后发信令
 */
export async function sendCDNInfoSignal(context: RTCContext, store: ReadableStore): Promise<{ code: RCRTCCode }> {
  const { roomId } = store;
  // eslint-disable-next-line
  const CDNUris = Object.assign({}, store.getCDNUris(), { enableInnerCDN: store.getCDNEnable() });

  const resCodeArr = await Promise.all([
    spreadCDNInfo(context, roomId, CDNUris),
    setRoomCDNInfo(context, roomId, CDNUris),
  ]);

  const isSuccess = resCodeArr.every((item) => item.code === RCRTCCode.SUCCESS);
  return isSuccess ? { code: RCRTCCode.SUCCESS } : { code: RCRTCCode.SIGNAL_ERROR };
}

/**
 * 处理房间全量数据
 * 重连或
 */
export async function handleFullRoomData(usersData: IRTCUsers, store: Store, executeCtx: CommandExecuteContext, invoker: Invoker, CDNUris?: string, traceId?: string) {
  const { logger } = executeCtx;
  const { roomId, crtUserId, roomMode } = store;

  // 查找新加入人员
  const joinedUserIds: string[] = [];
  // 新发布资源
  const published: { [userId: string]: IPublishedResource[] } = {};
  // 取消发布的资源
  const unpublished: { [userId: string]: IPublishedResource[] } = {};
  // 状态变更的资源
  const modified: { [userId: string]: IPublishedResource[] } = {};

  // 当前最新的房间资源数据
  const roomData: RoomData = parseRoomData(usersData, roomId, logger, traceId!);

  const nowUserIds = Object.keys(roomData);
  const prevUserIds = store.getAllUserIds();

  for (let i = nowUserIds.length - 1; i >= 0; i -= 1) {
    const userId = nowUserIds[i];
    const index = prevUserIds.indexOf(userId);

    if (index === -1) {
      // 新增人员
      joinedUserIds.push(userId);
      // 新增人员发布的资源
      published[userId] = deepCopyResources(roomData[userId]);
      continue;
    }

    // 房间缓存中的已发布资源
    const prevResources = store.getResourcesByUserId(userId)!;
    // 当前资源
    const nowResources = roomData[userId];
    // 资源比对
    const { publishedList, modifiedList, unpublishedList } = diffPublishResources(prevResources, nowResources, true);

    published[userId] = deepCopyResources(publishedList);
    unpublished[userId] = deepCopyResources(unpublishedList);
    modified[userId] = deepCopyResources(modifiedList);

    // 从之前的人员列表中删除已存在人员，剩余人员为已退出人员
    prevUserIds.splice(index, 1);
  }

  // 更新缓存资源
  prevUserIds.length && prevUserIds.forEach((userId) => {
    store.removeResourcesByUserId(userId);
  });
  store.assignRoomData(roomData);

  // 通知人员退出
  if (prevUserIds.length) {
    await dealLeftUsers(prevUserIds, executeCtx, store, invoker, traceId!);
    executeCtx.emit(CommandEvent.USER_LEAVE, prevUserIds);
  }

  // 通知人员加入
  joinedUserIds.length && executeCtx.emit(CommandEvent.USER_JOIN, joinedUserIds);

  /**
    * 资源取消发布
    * im 重连加入房间后，服务返回的自己资源为空时，上抛资源被取消发布需过滤掉本端资源
    */
  for (const userId in unpublished) {
    if (userId === crtUserId) {
      continue;
    }
    const resources = unpublished[userId];
    if (resources.length) {
      const tracks = resources.map(
        (item) => store.getRemoteTrack(getTrackId(item))!,
      );
      await new OnRemoteUserUnpubCommand(tracks).execute(executeCtx, store, invoker);
    }
  }

  // 新发布资源
  Object.keys(published).forEach((userId) => {
    const resources = published[userId];
    if (resources.length === 0) {
      return;
    }

    const tracks: RCRemoteTrack[] = resources.map((item) => {
      const trackId = getTrackId(item);
      const { userId, tag, mediaType } = parseTrackId(trackId);
      const track = mediaType === RCMediaType.AUDIO_ONLY ? new RCRemoteAudioTrack(logger, tag, userId) : new RCRemoteVideoTrack(logger, tag, userId);
      store.addRemoteTrack(track);
      track.__innerSetRemoteMuted(item.state === 0);
      return track;
    });
    executeCtx.emit(CommandEvent.TRACKS_PUBLISH, tracks);
  });

  // 资源状态变更
  Object.keys(modified).forEach((userId) => {
    const resources = modified[userId];
    // 音频与视频区分
    resources.forEach((item) => {
      const trackId = getTrackId(item);
      const rTrack = store.getRemoteTrack(trackId)!;
      rTrack.__innerSetRemoteMuted(item.state === 0);
      rTrack.isAudioTrack() ? executeCtx.emit(CommandEvent.AUDIO_MUTE_CHANGE, rTrack) : executeCtx.emit(CommandEvent.VIDEO_MUTE_CHANGE, rTrack);
    });
  });

  /**
   * 处理直播模式的 cdn_uris 数据
   */
  if (roomMode === RTCMode.LIVE) {
    executeInLivingRoom(executeCtx, store, logger, CDNUris);
  }
}

/**
 * 获取到新的全量数据后，需更新内存中的 CDN 数据
 * 判断房间内 CDN 状态是否和内存数据一致，不一致时需通知到客户端
 */
function executeInLivingRoom(executeCtx: CommandExecuteContext, store: Store, logger: ILogger, CDNUris?: string) {
  if (!CDNUris) {
    logger.warn(RCLoggerTag.L_LIVING_ROOM_RECONNECTED_R, JSON.stringify({
      status: RCLoggerStatus.FAILED,
      code: '',
      msg: 'cdn_uris not found',
    }));
    return;
  }

  const parseCDNUris = JSON.parse(CDNUris);
  const changed = store.getCDNUris()?.enableInnerCDN !== parseCDNUris.enableInnerCDN;
  store.setCDNUris(parseCDNUris);

  if (changed) {
    executeCtx.emit(CommandEvent.CDN_ENABLE_CHANGE, parseCDNUris.enableInnerCDN);
  }
}

/**
 * 处理离开房间的人
 * 需取消订阅离开人员的资源、更新 store 数据
 * @param leftUsers 离开的人员 userId 列表
 */
export async function dealLeftUsers(leftUsers: string[], executeCtx: CommandExecuteContext, store: Store, invoker: Invoker, traceId: string) {
  const tracks: RCRemoteTrack[] = [];
  const userIds: string[] = [];
  leftUsers.forEach((userId) => {
    tracks.push(...store.getRemoteTracksByUserId(userId));
    // 先暂存待删用户，因当前异步队列中可能存在等待中的待处理任务，需要当前房间数据状态
    userIds.push(userId);
  });
  if (tracks.length) {
    await new UnsubscribeCommand(tracks, traceId).execute(executeCtx, store, invoker);
    tracks.forEach((item) => store.removeRemoteTrack(item.getTrackId()));
  }
  // 等待队列执行完成后清除内存数据
  if (userIds.length) {
    userIds.forEach((userId) => store.removeResourcesByUserId(userId));
  }
}

/**
 * 通知拉取到的增量房间数据格式为 IRoomStatus
 * 把 IRoomStatus 转化为通用处理人员方法(_stateHandle)需要的数据
 */
export function transPullDataToStateMsgCont(data: IRoomStatus) {
  const {
    userId, userData, event: state, switchRoleType,
  } = data;
  const extra = userData.filter((item) => item.key === 'extra')[0]?.value;
  const content = {
    users: [{
      userId,
      extra,
      state,
      switchRoleType,
    }],
  };
  return content;
}

const isIncludeUris = (userData: {key: string, value: string}[]) => userData.some((item) => (item.key === 'uris' || item.key === 'cdn_uris'));

/**
 * 通知拉取到的增量房间数据格式为 IRoomStatus
 * 把 IRoomStatus 转化为通用解析资源方法(ParseRemoteResCommand)需要的数据
 */
export function transPullDataToResMsgCont(data: IRoomStatus) {
  const { userId, userData } = data;
  /**
   * PullRoomStatusEvent 为 3 时，并不全代表资源变动，
   * 房间内设置的其他 key value，也会被拉下来，event 为 3
   */
  const hasUris = isIncludeUris(userData);
  if (!hasUris) {
    return;
  }
  const uris = userData.filter((item) => (item.key === 'uris'))[0]?.value;
  const cdnUris = userData.filter((item) => (item.key === 'cdn_uris'))[0]?.value;
  return {
    userId,
    content: {
      uris: uris ? JSON.parse(uris) : [],
      cdn_uris: (cdnUris && JSON.parse(cdnUris)),
    },
  };
}

/**
 * 通知拉取到的全量房间数据格式为 IPullRTCRoomUsersData
 * 把 IPullRTCRoomUsersData 转化为通用解析所有房间数据方法(parseRoomData)需要的数据
 */
export function transPullFullUsersData(usersData: IPullRTCRoomUsersData[]): { urisData: IRTCUsers, CDNUris: string } {
  const urisData: IRTCUsers = {
    users: {},
  };
  let CDNUris = '';
  usersData.forEach((item) => {
    const { userId, userData } = item;
    urisData.users[userId] = {};
    const uris = userData.filter((item) => item.key === 'uris')[0]?.value;
    const newCDNUris = userData.filter((item) => item.key === 'cdn_uris')[0]?.value;
    CDNUris = newCDNUris || CDNUris;
    urisData.users[userId] = { uris };
  });
  return {
    urisData,
    CDNUris: CDNUris && JSON.stringify(JSON.parse(CDNUris)[0]),
  };
}

/**
 * 用于取视频小流和不加房间的观众的 ssrc
 * signal 中没有小流、不在房间内的观众收不到 signal，所以需要从 sdp 中拿小流的 ssrc
 */
export const getSsrcByStreamIdFromSdp = (sdp: string, streamId: string, mediaType: RCMediaType) => {
  const sdpArr = sdp.split('\r\nm');
  const kind = mediaType === RCMediaType.AUDIO_ONLY ? 'audio' : 'video';
  const matchSdp = sdpArr.filter((sdp) => sdp.includes(`=${kind}`) && sdp.includes(streamId))[0];
  let rule = new RegExp(`a=ssrc:(.*?) msid:${streamId}`);
  const matchSsrc = matchSdp.match(rule);
  const ssrc = matchSsrc ? matchSsrc[1] : '';
  return ssrc;
};
