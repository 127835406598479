import {
  validate, ErrorCode, IRuntime,
  notEmptyString, IReceivedMessage, ConversationType, RCConnectionStatus, BasicLogger,
} from '@rongcloud/engine';

import RCLivingRoom from './room/RCLivingRoom';
import RCRTCRoom from './room/RCRTCRoom';
import RCAudienceClient from './RCAudienceClient';
import RCMediaService from './service/RCMediaService';
import { IJoinResCDNInfo, IRCRTCInitOptions } from './interfaces';
import RCAbstractRoom, { RCAbstractRoomEvent } from './room/RCAbstractRoom';
import { isIllegalConnection } from '../helper';
import { RCRTCCode } from './enums/RCRTCCode';
import { RCLivingType } from './enums/RCLivingType';
import { ASdpStrategy } from './webrtc/sdp/ASdpStrategy';
import { RCRemoteTrack } from './tracks/RCRemoteTrack';
import RCAudienceLivingRoom from './room/RCAudienceLivingRoom';
import { RCInnerCDNPushMode } from './enums/RCInnerCDNPushMode';
import { getUUID } from './service';
import { RCLoggerStatus, RCLoggerTag } from './enums/RCLoggerTag';
import RCMediaStreamCapture from './RCMediaStreamCapture';
import { RTCContext } from './codec/RTCContext';

import { RTCMode } from './enums/RTCMode';
import { RTCJoinType } from './enums/RTCJoinType';
import { RTCIdentityChangeType } from './enums/inner/RTCIdentityChangeType';
import { IRTCUserData, IRTCJoinedInfo, IRTCNaviInfo } from './codec/interface';

/**
 * RTC 业务客户端
 * @public
 */
export default class RCRTCClient extends RCMediaStreamCapture {
  private readonly _service: RCMediaService;

  constructor(
    protected readonly _context: RTCContext,
    private readonly _runtime: IRuntime,
    private _options: IRCRTCInitOptions,
  ) {
    super(_context);
    // 用户不指定时，默认以 plan-b 优先选项
    ASdpStrategy.setSdpSemantics(_options.sdpSemantics || 'unified-plan', this._logger);

    this._service = new RCMediaService(this._runtime, this._context, this._options.mediaServer, this._options.timeout);
    // 监听 IM 连接状态变更
    this._context.registerConnectionStateChangeListener(this._onIMStatusChange.bind(this));
    // 监听业务层主动断开连接
    this._context.registerDisconnectListener(this._onIMDisconnect.bind(this));
    // 监听业务层 IM 连接销毁
    this._context.registerDestroyListener(this._onIMUninit.bind(this));
    // 监听房间内的消息
    this._context.registerMessageListener(this._handleMessage.bind(this));
    // 监听 navi 变更
    this._context.getPluginContext().onnavidatachange = this.naviDataChange.bind(this);
  }

  private _handleMessage(message: IReceivedMessage): boolean {
    // 过滤非 RTC 消息
    if (message.conversationType !== ConversationType.RTC_ROOM) {
      return false;
    }

    const traceId = this._logger.createTraceId();

    // 给连麦房间增加消息处理器
    if (this._crtRoom instanceof RCLivingRoom) {
      const PKRooms = this._getJoinedPKRoomList();
      PKRooms.forEach((room) => {
        room.__parseInnerMessage(message, traceId!);
      });
    }

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_MESSAGE_O, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      message,
    }), traceId);

    if (!this._crtRoom) {
      this._logger.warn(RCLoggerTag.L_RTC_CLIENT_MESSAGE_O, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        message: 'the crtRoom is empty',
      }), traceId);
      return true;
    }

    this._crtRoom.__parseInnerMessage(message, traceId!);
    return true;
  }

  /**
   * voip 变更、jwt 变更需要触发重走嗅探逻辑
   */
  private naviDataChange(naviInfo: IRTCNaviInfo): void {
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_NAVI_CHANGE_O, `on navi change data => ${JSON.stringify(naviInfo)}`);
    this._service.detectorMediaSever();
  }

  /**
   * 获取加入的连麦房间
   */
  private _getJoinedPKRoomList() {
    const { code, roomPKHandler } = (this._crtRoom as RCLivingRoom).getRoomPKHandler();
    if (code === RCRTCCode.SUCCESS && roomPKHandler) {
      const PKRooms = roomPKHandler.getJoinedPKRooms() || {};
      return Object.values(PKRooms);
    }
    return [];
  }

  /**
   * 获取当前用户 Id，若 IM 未连接，这返回 `''`
   * @returns
   */
  getCurrentId() {
    return this._context.getCurrentId();
  }

  private _crtRoom: RCAbstractRoom | null = null

  /**
   * 加入普通音视频房间，加入成功后返回 room 实例、code、userIds、tracks
   * @param {string} roomId - 房间号
   * @param {RTCJoinType} [joinType] - 要加入的房间类型。
   * @param {IRTCUserData} [outerUserDatas] - 加入房间的用户的用户数据。
   * @param {boolean} [_] - 已废弃~~（是否使用多条 PeerConnection 链路）~~
   * @param {RTCMode} roomType - 房间类型，请谨慎修改，默认为 RTCMode.RTC
   */
  async joinRTCRoom(
    roomId: string,
    joinType?: RTCJoinType,
    outerUserDatas?: IRTCUserData,
    _: boolean = false,
    roomType:RTCMode.RTC | RTCMode.SIP | RTCMode.CALL = RTCMode.RTC,
  ): Promise<{ room?: RCRTCRoom, code: RCRTCCode, userIds?: string[], tracks?: RCRemoteTrack[] }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_JOIN_RTC_ROOM_T, JSON.stringify({
      roomId,
      joinType,
      outerUserDatas,
      roomType,
    }), traceId);

    if (this._crtRoom) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_RTC_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: 'Join the room repeatedly',
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    if (![RTCMode.RTC, RTCMode.CALL, RTCMode.SIP].includes(roomType)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_RTC_ROOM_R, `invalid roomType: ${roomType}`, traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const room = this._crtRoom = new RCRTCRoom(this._context, this._runtime, roomId, this._service, this._options, false, false, getUUID());
    const { code } = await room.__innerInit(roomType, joinType, undefined, undefined, outerUserDatas, traceId);
    if (code !== RCRTCCode.SUCCESS) {
      this._crtRoom = null;
      return { code: code as RCRTCCode };
    }
    room.once(RCAbstractRoomEvent.LEAVE, () => {
      this._crtRoom = null;
    });
    return {
      code, room, userIds: room.getRemoteUserIds(), tracks: room.getRemoteTracks(),
    };
  }

  /**
   * 加入跨 AppKey 音视频房间
   * @param roomId
   * @param joinType 多端处理方式
   * @param outerUserDatas 业务层设置人员属性
   * @param _ 参数已废弃
   * @param __ 参数已废弃
   */
  async joinCrossRTCRoom(roomId: string, joinType?: RTCJoinType, outerUserDatas?: IRTCUserData, _: boolean = false, __:RTCMode = RTCMode.CROSS_MUTI): Promise<{ room?: RCRTCRoom, code: RCRTCCode, userIds?: string[], tracks?: RCRemoteTrack[] }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_JOIN_CROSS_RTC_ROOM_T, JSON.stringify({
      roomId,
      joinType,
      outerUserDatas,
    }), traceId);

    if (this._crtRoom) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_CROSS_RTC_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: 'Join the room repeatedly',
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    const roomType = RTCMode.CROSS_MUTI;
    const room = this._crtRoom = new RCRTCRoom(this._context, this._runtime, roomId, this._service, this._options, false, false, getUUID());
    const { code } = await room.__innerInit(roomType, joinType, undefined, undefined, outerUserDatas, traceId);
    if (code !== RCRTCCode.SUCCESS) {
      this._crtRoom = null;
      return { code: code as RCRTCCode };
    }
    room.once(RCAbstractRoomEvent.LEAVE, () => {
      this._crtRoom = null;
    });
    return {
      code, room, userIds: room.getRemoteUserIds(), tracks: room.getRemoteTracks(),
    };
  }

  /**
   * 主播加入直播房间或观众上麦场景调用，观众上麦之前需先取消已订阅的直播间资源
   * @param roomId 房间 Id
   * @param livingType 直播间类型，`RCLivingType.AUDIO` 为音频直播，`RCLivingType.VIDEO` 为音视频直播
   * @param joinType 多端处理方式，公有云暂不支持该字段
   * @param outerUserDatas 业务层设置人员属性
   * @param _ 参数已废弃
   */
  async joinLivingRoom(roomId: string, livingType: RCLivingType, joinType?: RTCJoinType, outerUserDatas?: IRTCUserData, _: boolean = false): Promise<{ room?: RCLivingRoom, code: RCRTCCode, userIds?: string[], tracks?: RCRemoteTrack[], CDNEnable?: boolean }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_T, JSON.stringify({
      roomId,
      livingType,
      joinType,
      outerUserDatas,
    }), traceId);

    // 已存在直播房间
    if (this._crtRoom) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: `Join the room repeatedly -> roomId: ${roomId}`,
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    const room = this._crtRoom = new RCLivingRoom(this._context, this._runtime, roomId, this._service, this._options, livingType, false, true);
    const { code } = await room.__innerInit(RTCMode.LIVE, joinType, livingType, undefined, outerUserDatas, traceId);
    if (code !== RCRTCCode.SUCCESS) {
      this._crtRoom = null;
      return { code: code as RCRTCCode };
    }

    room.once(RCAbstractRoomEvent.LEAVE, () => {
      this._crtRoom = null;
    });

    const res = {
      code, room, userIds: room.getRemoteUserIds(), tracks: room.getRemoteTracks(),
    };
    // 手动模式时，用户加入房间，需返回 CDN 开关状态
    if (room.__getCDNPushMode() === RCInnerCDNPushMode.MANUAL) {
      Object.assign(res, { CDNEnable: room.__getCDNEnable() });
    }
    return res;
  }

  private _audience: RCAudienceClient | null = null

  /**
   * 获取直播观众客户端
   * @param _ - 参数已废弃
   */
  getAudienceClient(_?: boolean): RCAudienceClient {
    if (!this._audience) {
      this._audience = new RCAudienceClient(this._context, this._runtime, this._options);
    }
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_GET_AUDIENCE_CLIENT_O);
    return this._audience;
  }

  private _onIMStatusChange(status: RCConnectionStatus) {
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_IM_CONNECTION_STATE_S, JSON.stringify({
      status,
    }));

    if (status !== RCConnectionStatus.CONNECTED) {
      return;
    }
    // 重连后执行探测逻辑
    this._service.detectorMediaSever();
    // 给连麦房间增加重连处理
    if (this._crtRoom instanceof RCLivingRoom) {
      const PKRooms = this._getJoinedPKRoomList();
      PKRooms.forEach((room) => {
        room.__onReconnected();
      });
    }

    if (this._crtRoom) {
      this._crtRoom.__onReconnected();
    }
  }

  /**
   * 用户主动断开 IM 连接
   */
  private _onIMDisconnect() {
    this._logger.warn(RCLoggerTag.L_RTC_CLIENT_IM_DISCONNECT_S);
    // TODO IM 主动断开，应检测是否有 RTC 业务进行中，若有应退出
  }

  /**
   * 用户销毁 IM 客户端，IM 客户端需重新初始化
   */
  private _onIMUninit() {
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_DESTROY_S, JSON.stringify({
      msg: 'TODO -> on IM client ondestroy',
    }));
    // 用户销毁 IM 客户端，IM 客户端需重新初始化
    this._crtRoom?.__destroy(true);
  }

  /**
   * 退出并销毁当前房间实例，退出后该房间的所有方法将不可用
   */
  async leaveRoom(room: RCAbstractRoom): Promise<{ code: RCRTCCode }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_LEAVE_ROOM_T, JSON.stringify({
      roomId: room.getRoomId(),
    }), traceId);

    if (!this._crtRoom) {
      this._logger.warn(RCLoggerTag.L_RTC_CLIENT_LEAVE_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'Room does not exist, without leave',
      }), traceId);
      return { code: RCRTCCode.SUCCESS };
    }

    await this._crtRoom.__destroy(true);

    // 离开房间后调用探测逻辑，查看是否需要更新探测结果
    this._service.detectorMediaSever();

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_LEAVE_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomId: this._crtRoom?.getRoomId(),
    }), traceId);

    this._crtRoom = null;
    return { code: RCRTCCode.SUCCESS };
  }

  private _crtAudienceLivingRoom: RCAudienceLivingRoom | null = null

  /**
   * 观众加入直播房间
   * @param roomId 房间 ID
   * @param livingType 直播类型（音频直播 or 音视频直播）
   * @param _ 废弃参数
   */
  async joinLivingRoomAsAudience(roomId: string, livingType: RCLivingType, _?: boolean): Promise<{room?: RCAudienceLivingRoom, code: RCRTCCode, userIds?: string[], RTCTracks?: RCRemoteTrack[], MCUTracks?: RCRemoteTrack[], CDNUris?: IJoinResCDNInfo }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_T, JSON.stringify({
      roomId,
      livingType,
    }), traceId);

    // 观众加房间前进行探测
    this._service.detectorMediaSever();

    if (isIllegalConnection(this._context.getNaviInfo()!)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PACKAGE_ENVIRONMENT_ERROR,
        msg: 'package environment error',
      }), traceId);

      return { code: RCRTCCode.PACKAGE_ENVIRONMENT_ERROR };
    }

    if (!(
      validate('roomId', roomId, notEmptyString, true)
      && validate('livingType', livingType, (value) => value === RCLivingType.AUDIO || value === RCLivingType.VIDEO)
    )) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> roomId or livingType',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    // 已存在直播房间
    if (this._crtAudienceLivingRoom) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: 'Join the room repeatedly',
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    const urls = this._service.getNaviMS();
    if (!urls.length) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER,
        msg: 'No audio / video server address available',
      }), traceId);

      return { code: RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER };
    }

    const { code, data } = await this._context.joinLivingRoomAsAudience(roomId, RTCMode.LIVE, livingType);

    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
        msg: 'signal error -> audience join room',
      }), traceId);

      return { code: RCRTCCode.SIGNAL_AUDIENCE_JOIN_ROOM_FAILED };
    }

    const clientSessionId = getUUID();
    const room = new RCAudienceLivingRoom(this._context, this._runtime, this._options, roomId, data!, livingType, clientSessionId);
    this._crtAudienceLivingRoom = room;

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_JOIN_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomData: data,
    }), traceId);

    return {
      room,
      code: RCRTCCode.SUCCESS,
      userIds: room.getRemoteUserIds(),
      RTCTracks: room.getRemoteRTCTracks(),
      MCUTracks: room.getRemoteMCUTracks(),
      CDNUris: room.getCDNInfo(),
    };
  }

  /**
   * 观众退出并销毁当前房间实例，退出后该房间的所有方法将不可用
   */
  async leaveLivingRoomAsAudience(room: RCAudienceLivingRoom): Promise<{ code: RCRTCCode }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_RTC_CLIENT_LEAVE_LIVING_ROOM_AS_AUDIENCE_T, JSON.stringify({
      roomId: room.getRoomId(),
      userId: this._context.getCurrentId(),
    }), traceId);

    if (!this._crtAudienceLivingRoom) {
      this._logger.warn(RCLoggerTag.L_RTC_CLIENT_LEAVE_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: 'Room does not exist, without leave',
      }), traceId);

      return { code: RCRTCCode.SUCCESS };
    }

    if (this._crtAudienceLivingRoom !== room) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_LEAVE_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> room',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    await this._crtAudienceLivingRoom.__destroy(true);
    this._crtAudienceLivingRoom = null;

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_LEAVE_LIVING_ROOM_AS_AUDIENCE_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
    }), traceId);

    return { code: RCRTCCode.SUCCESS };
  }

  /**
   * 升级为主播房间
   * @param room 观众房间实例
   */
  async upgradeToAnchorRoom(room: RCAudienceLivingRoom): Promise<{room?: RCLivingRoom, code: RCRTCCode, userIds?: string[], tracks?: RCRemoteTrack[], CDNEnable?: boolean}> {
    const traceId = this._logger.createTraceId();

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_T, undefined, traceId);

    if (!validate('room', room, () => room instanceof RCAudienceLivingRoom, true)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> room',
      }), traceId);
      return { code: RCRTCCode.PARAMS_ERROR };
    }

    const roomId = room.getRoomId();

    // 已存在主播房间
    if (this._crtRoom) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_UPGRADE_TO_ANCHOR_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: 'Join the room repeatedly',
        roomId,
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    const crtRoom = this._crtRoom = new RCLivingRoom(this._context, this._runtime, roomId, this._service, this._options, room.livingType, true, true, room.getClientSessionId());

    const code = await crtRoom.__innerInitByIdentityChange(traceId!);
    if (code !== ErrorCode.SUCCESS) {
      this._crtRoom = null;
      // TODO: 将 ErrorCode 直接抛给业务层，不要中间转换
      return { code: RCRTCCode.SIGNAL_ROOM_CHANGE_IDENTITY_FAILED };
    }

    crtRoom.once(RCAbstractRoomEvent.LEAVE, () => {
      this._crtRoom = null;
    });

    // 观众房间内存数据清除
    await this._crtAudienceLivingRoom!.__destroy(false);
    // 重置观众房间
    this._crtAudienceLivingRoom = null;

    const res = {
      room: crtRoom, code: RCRTCCode.SUCCESS, userIds: crtRoom.getRemoteUserIds(), tracks: crtRoom.getRemoteTracks(),
    };

    // 手动模式时，用户加入房间，需返回 CDN 开关状态
    if (crtRoom.__getCDNPushMode() === RCInnerCDNPushMode.MANUAL) {
      Object.assign(res, { CDNEnable: crtRoom.__getCDNEnable() });
    }

    return res;
  }

  /**
   * 降级为观众房间
   * @param room 主播房间实例
   */
  async downgradeToAudienceRoom(room: RCLivingRoom): Promise<{room?: RCAudienceLivingRoom, code: RCRTCCode, userIds?: string[], RTCTracks?: RCRemoteTrack[], MCUTracks?: RCRemoteTrack[], CDNUris?: IJoinResCDNInfo}> {
    const traceId = this._logger.createTraceId();

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_T, JSON.stringify({
      roomId: room._roomId,
      userId: this._context.getCurrentId(),
    }), traceId);

    /**
     * 副房间不能调用
     */
    if (!room.isMainRoom()) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM,
        msg: 'the `downgradeToAudienceRoom` is disabled in PK room',
      }), traceId);

      return { code: RCRTCCode.THE_FUNCTION_IS_DISABLED_IN_PKROOM };
    }

    if (!validate('room', room, () => room instanceof RCLivingRoom, true)) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.PARAMS_ERROR,
        msg: 'params error -> room',
      }), traceId);

      return { code: RCRTCCode.PARAMS_ERROR };
    }

    // 已存在观众房间
    if (this._crtAudienceLivingRoom) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.REPERT_JOIN_ROOM,
        msg: 'Join the room repeatedly',
      }), traceId);

      return { code: RCRTCCode.REPERT_JOIN_ROOM };
    }

    const { code, data } = await this._context.rtcIdentityChange(room._roomId, RTCIdentityChangeType.AnchorToViewer, room.getLivingType());

    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.SIGNAL_ROOM_CHANGE_IDENTITY_FAILED,
      }), traceId);

      return { code: RCRTCCode.SIGNAL_ROOM_CHANGE_IDENTITY_FAILED };
    }

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_DOWNGRADE_TO_AUDIENCE_ROOM_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomData: data,
    }), traceId);

    const clientSessionId = room.getClientSessionId();
    const crtRoom = new RCAudienceLivingRoom(this._context, this._runtime, this._options, room._roomId, data!, room.getLivingType(), clientSessionId);
    this._crtAudienceLivingRoom = crtRoom;
    // 主播房间内存数据清除及停止 Signal 房间心跳
    this._crtRoom!.__destroy(false);
    // 重置主播房间
    this._crtRoom = null;

    return {
      room: crtRoom,
      code: RCRTCCode.SUCCESS,
      userIds: crtRoom.getRemoteUserIds(),
      RTCTracks: crtRoom.getRemoteRTCTracks(),
      MCUTracks: crtRoom.getRemoteMCUTracks(),
      CDNUris: crtRoom.getCDNInfo(),
    };
  }

  /**
   * 验证本用户是否存在于某个 RTC 房间内
   * @since version 5.2.1
   */
  async getJoinedRoomInfo(): Promise<{code: RCRTCCode | ErrorCode, data?: IRTCJoinedInfo[]}> {
    const traceId = this._logger.createTraceId();
    const userId = this._context.getCurrentId();
    this._logger.error(RCLoggerTag.L_RTC_CLIENT_GET_JOINED_ROOM_INFO_T, JSON.stringify({
      userId,
    }), traceId);

    const { code, data } = await this._context.getRTCJoinedUserInfo(userId);
    if (code !== ErrorCode.SUCCESS) {
      this._logger.error(RCLoggerTag.L_RTC_CLIENT_GET_JOINED_ROOM_INFO_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code,
      }), traceId);

      return { code };
    }

    this._logger.info(RCLoggerTag.L_RTC_CLIENT_GET_JOINED_ROOM_INFO_R, JSON.stringify({
      status: RCLoggerStatus.SUCCESSED,
      roomInfo: data,
    }), traceId);

    return { code: RCRTCCode.SUCCESS, data };
  }
}
