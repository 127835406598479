import { BasicLogger } from '@rongcloud/engine';
import { IRCTrackBitrate } from '../../interfaces';
import { ISdpSemantics, RtpTransceiverDirection } from './ASdpStrategy';

/**
 * 以字符串的形式 硬处理 SDP 信息
 * 对于 plan-b | unified-plan 数据的处理 方式是相同的。
 * 将 SDP 以 m=[video|audio] 进行拆分，
 * 从而获取 SDPHeader | videoStreams | audioStreams 并对其内容进行处理
 */
export default abstract class ASdpBuilder {
  protected SDPHeader: string = ''

  protected videoStreams: string[] = []

  protected audioStreams: string[] = []

  static KBitrate: number = 1

  public get videoSdps() :string[] {
    return this.videoStreams.slice();
  }

  public get audioSdps() :string[] {
    return this.audioStreams.slice();
  }

  constructor(
    public readonly SDP:string,
    public readonly type: ISdpSemantics,
    protected readonly _logger: BasicLogger,
  ) {
    // 获取 Mid 的位置
    const streamPositions = this.getStreamIndex();
    this.spliteStreams(streamPositions);
  }

  /**
   * 它从 SDP 中删除空行和尾随空格
   * @param {string} sdp - 要处理的 SDP 字符串。
   * @returns 删除了换行符的 sdp 字符串。
   */
  static trimBlankLine(sdp: string):string {
    // 移除空行以及行尾的空格
    return sdp.replace(/\n\r\s*\r\n/g, '\r\n').replace(/\s+\r\n/g, '\r\n');
  }

  /**
   * 通过 m=video | m=audio 将 SDP 进行分隔
   * 它返回一个索引数组，其中在 SDP 中找到子字符串“m=video”或“m=audio”
   * 它返回 SDP 中 execResult.index 的索引数组。
   * @returns 一组数字。
   */
  private getStreamIndex():number[] {
    const mid = new RegExp('m=(?:video|audio)', 'img');
    const midPositions = new Set([0]);
    let execResult = mid.exec(this.SDP);
    while (execResult !== null) {
      midPositions.add(execResult?.index);
      execResult = mid.exec(this.SDP);
    }
    midPositions.add(this.SDP.length);
    return [...midPositions];
  }

  /**
   * 它将 SDP 拆分为标头和流
   * @param {number[]} streams - 表示每个流的开始和结束的数字数组。
   */
  private spliteStreams(streams:number[]) {
    // streams = [0, 30, 40, 50, 80, 100]
    this.SDPHeader = this.SDP.substring(streams[0], streams[1]).replace(/\r\ns=-/g, `\r\ns=${(new Date()).valueOf()}`);
    for (let mid = 1; mid < streams.length; mid++) {
      const stream = this.SDP.substring(streams[mid], streams[mid + 1]);
      if (/^\bm=video\b/.test(stream)) {
        this.videoStreams.push(stream);
      } else {
        this.audioStreams.push(stream);
      }
    }
  }

  /**
   * 如果当前资源方向 RTCRtpTransceiverDirection 是 recvonly | inactive
   * 表明当前不需要发送 RTP 数据所以将 SDP 中删除所有以 `a=ssrc` 或 `a=msid` 开头的行
   * @param {string} stream - string - 要修改的 SDP 字符串
   * @returns 正在返回流。
   */
  static clearInactiveOrRecvonly(stream: string): string {
    const recvonlyORinactive = /\ba=(recvonly|inactive)\b/.test(stream);
    if (recvonlyORinactive) {
      return stream.replace(/\r\na=(ssrc|msid)[^\r\n]+/ig, '');
    }
    return stream;
  }

  /**
   * 它采用 SDP 标头和音频和视频流并将它们连接到一个字符串中
   * @returns SDP 标头和音频和视频流的字符串。
   */
  abstract stringify ():string

  /**
   * 它将 SDP 标头中的 `a=group` 行替换为 `b=AS` 和 `b=TIAS` 行
   * @param {number} maxBitrate - 流的最大比特率。
   */
  public setHeaderBitrate(maxBitrate: number) {
    this.SDPHeader = this.SDPHeader.replace(/(\r\ns=[^\r\n]+)/ig, `$1\r\nc=IN IP4 0.0.0.0\r\nb=AS:${maxBitrate * ASdpBuilder.KBitrate}\r\nb=TIAS:${maxBitrate * ASdpBuilder.KBitrate * 850}`);
  }

  /**
   * 它获取音频流并将它们映射到一个新数组，其中每个项目都是调用 setAudioItemBitrate 函数的结果
   * @param {IRCTrackBitrate} bitrate - 以 kbps 为单位的比特率。
   */
  public setAudiosBitrate(bitrate: IRCTrackBitrate): ASdpBuilder {
    this.audioStreams = this.audioStreams.map((sdpAudioBlock) => this.setAudioItemBitrate(sdpAudioBlock, bitrate));
    return this;
  }

  /**
   * 它设置特定 MID 的音频比特率。
   * @param {IRCTrackBitrate} bitrate - 音频流的比特率，以 kbps 为单位。
   * @param {string} streamId - 音频流的媒体流 ID。
   */
  public setAudioBitrateWithStreamId(bitrate: IRCTrackBitrate, streamId: string): ASdpBuilder {
    this.audioStreams = this.audioStreams.map((sdpAudioBlock) => {
      const testStreamId = new RegExp(`\\bmsid:${streamId}\\b`, 'ig');
      // 判断 msid 是否存在
      if (testStreamId.test(sdpAudioBlock)) {
        return this.setAudioItemBitrate(sdpAudioBlock, bitrate);
      }
      return sdpAudioBlock;
    });
    return this;
  }

  /**
   * 它设置特定 MID 的音频比特率。
   * @param {IRCTrackBitrate} bitrate - 音频流的比特率，以 kbps 为单位。
   * @param {string} mid - 音频流的媒体流 ID。
   */
  public setAudioBitrateWithMid(bitrate: IRCTrackBitrate, mid: string): ASdpBuilder {
    this.audioStreams = this.audioStreams.map((sdpAudioBlock) => {
      const testStreamId = new RegExp(`\\bmid:${mid}\\b`, 'ig');
      // 判断 msid 是否存在
      if (testStreamId.test(sdpAudioBlock)) {
        return this.setAudioItemBitrate(sdpAudioBlock, bitrate);
      }
      return sdpAudioBlock;
    });
    return this;
  }

  /**
   * 它接受一个 SDP 音频块和一个比特率，并返回比特率设置为给定值的 SDP 音频块
   * @param {string} sdpAudioBlock - SDP 的音频块。
   * @param {IRCTrackBitrate} bitrate - 以 kbps 为单位的比特率。
   * @returns 正在返回 sdpAudioBlock 并将比特率设置为传入的比特率。
   */
  private setAudioItemBitrate(sdpAudioBlock: string, bitrate: IRCTrackBitrate): string {
    if (bitrate.max === 0 && /\ba=inactive\b/ig.test(sdpAudioBlock)) {
      return sdpAudioBlock;
    }
    // 如果未替换 则进行替换
    if (!/\bmaxaveragebitrate\b/ig.test(sdpAudioBlock)) {
      const audioBitrate = [
        '$1',
        `maxaveragebitrate=${bitrate.max * ASdpBuilder.KBitrate * 1000}`,
      ];
      sdpAudioBlock = sdpAudioBlock.replace(/(\buseinbandfec=[^\r\n]+)/ig, audioBitrate.join(';'));
    }
    return this.addAudioBlineAS(sdpAudioBlock, bitrate);
  }

  /**
   * 如果 SDP 的音频块没有 b=AS: 行，则添加一个
   * @param {string} sdpAudioBlock - SDP 的音频块。
   * @param {IRCTrackBitrate} bitrate - IRCTrack比特率
   * @returns 正在返回 sdpAudioBlock。
   */
  private addAudioBlineAS(sdpAudioBlock: string, bitrate: IRCTrackBitrate):string {
    if (!/\bb=AS:\d+\b/ig.test(sdpAudioBlock)) {
      return sdpAudioBlock.replace(/(\bc=IN[^\r\n]+)/ig, `$1\r\nb=AS:${bitrate.max * ASdpBuilder.KBitrate}\r\nb=TIAS:${bitrate.max * ASdpBuilder.KBitrate * 850}`);
    }
    return sdpAudioBlock;
  }

  /**
   * 对视频元素设置 SDP 相关信息
   * 针对 SDP 中所有的 video 元素添加码率设置
   * @param bitrate
   */
  public setVideosBitrate(bitrate: IRCTrackBitrate): ASdpBuilder {
    // 找到 profile-level-id 行在后面添加  ;x-google--bitrate ;x-google--bitrate=
    this.videoStreams = this.videoStreams.map((sdpVideoBlock) => this.setVideoItemBitrate(sdpVideoBlock, bitrate));
    return this;
  }

  /**
   * 它设置特定中间的视频比特率， 针对 SDP 中给定 mid 的 video 元素添加码率设置。
   * @param {IRCTrackBitrate} bitrate - 您要设置的比特率。
   * @param {string} streamId - 媒体流 ID。
   */
  public setVideoBitrateWithStreamId(bitrate: IRCTrackBitrate, streamId: string): ASdpBuilder {
    this.videoStreams = this.videoStreams.map((sdpVideoBlock) => {
      const testStreamId = new RegExp(`\\bmsid:${streamId}\\b`, 'ig');
      if (testStreamId.test(sdpVideoBlock)) {
        return this.setVideoItemBitrate(sdpVideoBlock, bitrate);
      }
      return sdpVideoBlock;
    });
    return this;
  }

  /**
   * 它设置特定中间的视频比特率， 针对 SDP 中给定 mid 的 video 元素添加码率设置。
   * @param {IRCTrackBitrate} bitrate - 您要设置的比特率。
   * @param {string} streamId - 媒体流 ID。
   */
  public setVideoBitrateWithMid(bitrate: IRCTrackBitrate, mid: string): ASdpBuilder {
    this.videoStreams = this.videoStreams.map((sdpVideoBlock) => {
      const testStreamId = new RegExp(`\\bmid:${mid}\\b`, 'ig');
      if (testStreamId.test(sdpVideoBlock)) {
        return this.setVideoItemBitrate(sdpVideoBlock, bitrate);
      }
      return sdpVideoBlock;
    });
    return this;
  }

  /**
   * 它设置视频项目的比特率。
   * @param {string} sdpVideoBlock - SDP 的视频块。
   * @param {IRCTrackBitrate} bitrate - 包含最小、最大和起始比特率值的比特率对象。
   * @returns 正在返回 sdpVideoBlock。
   */
  private setVideoItemBitrate(sdpVideoBlock: string, bitrate: IRCTrackBitrate):string {
    if (bitrate.max === 0 && /\ba=inactive\b/ig.test(sdpVideoBlock)) {
      return sdpVideoBlock;
    }
    // 如果未替换 则进行替换
    if (!/\bx-google-max-bitrate\b/.test(sdpVideoBlock)) {
      const videoBitrate = [
        '$1',
        `x-google-max-bitrate=${bitrate.max * ASdpBuilder.KBitrate}`,
        `x-google-min-bitrate=${bitrate.min * ASdpBuilder.KBitrate}`,
        `x-google-start-bitrate=${bitrate.start || bitrate.max * ASdpBuilder.KBitrate * 0.7}`,
      ];
      // 42e01f 42001f 为 H264 编码
      sdpVideoBlock = sdpVideoBlock.replace(/(\bprofile-level-id=42[e0]01f\b)/ig, videoBitrate.join(';'));
    }
    return this.addVideoBlineAS(sdpVideoBlock, bitrate);
  }

  /**
   * 如果视频块没有 b=AS: 行，则添加一个
   * @param {string} sdpVideoBlock - SDP 的视频块。
   * @param {IRCTrackBitrate} bitrate - IRCTrack比特率
   * @returns 正在返回 sdpVideoBlock。
   */
  private addVideoBlineAS(sdpVideoBlock: string, bitrate: IRCTrackBitrate):string {
    // b=AS: 定义本端带宽信息
    if (!/\bb=AS:\d+\b/ig.test(sdpVideoBlock)) {
      return sdpVideoBlock.replace(/(\bc=IN[^\r\n]+)/ig, `$1\r\nb=AS:${bitrate.max * ASdpBuilder.KBitrate}\r\nb=TIAS:${bitrate.max * ASdpBuilder.KBitrate * 850}`);
    }
    return sdpVideoBlock;
  }

  public clearnSsrcWithMid(mid: string, direction: RtpTransceiverDirection): ASdpBuilder {
    this.audioStreams = this.audioStreams.map((sdpAudioBlock) => {
      const testMId = new RegExp(`\\bmid:${mid}\\b`, 'ig');
      // 判断 msid 是否存在
      if (testMId.test(sdpAudioBlock)) {
        return this.clearSSRC(sdpAudioBlock, direction);
      }
      return sdpAudioBlock;
    });

    this.videoStreams = this.videoStreams.map((sdpVideoBlock) => {
      const testMId = new RegExp(`\\bmid:${mid}\\b`, 'ig');
      if (testMId.test(sdpVideoBlock)) {
        return this.clearSSRC(sdpVideoBlock, direction);
      }
      return sdpVideoBlock;
    });
    return this;
  }

  private clearSSRC(stream: string, direction: RtpTransceiverDirection): string {
    if (direction === RtpTransceiverDirection.SENDONLY) {
      const recvonlyORinactive = /\ba=sendonly\b/.test(stream);
      if (recvonlyORinactive) {
        return stream.replace(/\r\na=(ssrc|msid)[^\r\n]+/ig, '');
      }
    }

    if (direction === RtpTransceiverDirection.RECVONLY) {
      const recvonlyORinactive = /\ba=recvonly\b/.test(stream);
      if (recvonlyORinactive) {
        return stream.replace(/\r\na=(ssrc|msid)[^\r\n]+/ig, '');
      }
    }

    if (direction === RtpTransceiverDirection.INACTIVE) {
      const recvonlyORinactive = /\ba=inactive\b/.test(stream);
      if (recvonlyORinactive) {
        return stream.replace(/\r\na=(ssrc|msid)[^\r\n]+/ig, '');
      }
    }
    return stream;
  }
}
