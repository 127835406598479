import {
  HttpMethod, IRuntime, RTCPluginContext, notEmptyObject, notEmptyArray, RCConnectionStatus, BasicLogger,
} from '@rongcloud/engine';
import { gzip } from '../../submodule/pako/index';
import { browserInfo } from '../../helper';
import { RCRTCCode } from '../enums/RCRTCCode';
import {
  parseNaviInfo, getUUID, getDetectorUrls,
} from './helper';
import {
  IRTCReqHeader, IExchangeReqBody, IExchangeResponse, IMCUReqHeaders,
  IBroadcastSubReqBody, IBroadcastSubRespBody,
  ICDNPlayUrlReqHeaders, ICDNPlayUrlResponse,
} from './interface';
import { IMCUConfig, ISetEnableCDN } from './mcu-interface';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';
import { RTCContext } from '../codec/RTCContext';
import { DETECT_MINUTE } from '../constants';
import { IRTCNaviInfo } from '../codec/interface';
import { IMediaServerQualityData } from '../logger/IQualityReportData';

const getCommonHeader = () => ({
  'Content-Type': 'application/json;charset=UTF-8',
  'Cache-Control': 'no-cache',
  ClientType: `web|${browserInfo.browser}|${browserInfo.version}`,
  ClientVersion: __VERSION__,
  'Client-Session-Id': getUUID(),
  'Request-Id': Date.now().toString(),
});

export default class RCMediaService {
  /**
   * navi 中获取的媒体服务地址
   */
  private readonly _msInNavi: string[] = []

  /**
   * 已失败的请求地址
   */
  private readonly _failedMs: string[] = []

  /**
   * 服务器指纹数据，客户端不得修改，直接透传
   */
  private _rtcFinger: any = undefined

  /**
   * 服务器接口返回的 clusterId 数据，当此数据有值时，后续所有请求向此服务发送
   */
  private _clusterId: string = ''

  /**
   * MCU 服务地址
   */
  private _configUrl: string = ''

  /**
   * 嗅探中获取的媒体服务地址
   */
  private static isDetector: boolean = false

  /**
   * 嗅探中获取的媒体服务地址
   */
  private static msInDetector: string[] = []

  // 记录执行探测时间
  private static detectorTime: number = 0

  // 默认有效 120 分钟
  private static detectValidMinute: number = DETECT_MINUTE * 60 * 1000

  // 一次链接中最多刷新 navi 5 次
  // TODO: 仅为和移动端对其规则，实现方案待考量，应该按照有效时间规范 navi刷新次数，不该是定死的数值
  // private static naviRefetchCount = 0

  // navi 下发的 jwtToken
  public static jwtToken: string = ''

  private _msList: string[] = []

  private _qualityMsList: IMediaServerQualityData[] = []

  private readonly _logger: BasicLogger

  constructor(
    private readonly _runtime: IRuntime,
    private readonly _context: RTCContext,
    /**
     * 自定义 MediaServer 地址，当有值时，不再使用导航内的地址
     */
    private readonly _msUrl?: string,
    /**
     * 请求超时时长
     */
    private readonly _timeout: number = 5000,
  ) {
    this._logger = this._context.logger;
    // 初始化时判断当前 IM 是否已经处于链接状态，已连接切没有执行过嗅探或嗅探结果无效，主动触发一次嗅探逻辑
    if (!RCMediaService.isDetector && _context.getConnectionStatus() === RCConnectionStatus.CONNECTED) {
      this.detectorMediaSever();
    }
  }

  public detectorMediaSever() {
    const naviInfo: IRTCNaviInfo | null = this._context.getNaviInfo();
    RCMediaService.jwtToken = naviInfo?.jwt || '';
    // 如果有传入自定义 MediaServer 地址不走探测逻辑
    if (this._msUrl) {
      return;
    }

    const nowDate = Date.now();
    const isValid = (RCMediaService.detectValidMinute + RCMediaService.detectorTime > nowDate);
    RCMediaService.isDetector = isValid;
    if (!isValid && naviInfo && notEmptyObject(naviInfo)) {
      this._msList = [];
      this._getDetectorUrls(naviInfo);
    }
  }

  /**
   *  地址探测
   *  RTC 初始化时检测是否可以拿到 navi，可以拿到开始嗅探
   *  拿不到等 IM 链接成功后，再回调中调用开始嗅探
   */
  private async _getDetectorUrls(naviInfo: IRTCNaviInfo) {
    if (RCMediaService.isDetector) {
      return;
    }
    RCMediaService.isDetector = true;
    const {
      fastMediaUrl, clientDetectMinute, status,
    } = await getDetectorUrls(this._runtime, this._logger, naviInfo);
    if (status === RCRTCCode.JWT_TIME_OUT) {
      const context: RTCPluginContext = this._context.getPluginContext();
      await context.refetchNaviInfo();
      this.detectorMediaSever();
    }
    RCMediaService.msInDetector = fastMediaUrl || [];
    RCMediaService.detectorTime = Date.now();
    RCMediaService.detectValidMinute = clientDetectMinute * 60 * 1000;
  }

  public getNaviMS() {
    if (this._msUrl) {
      return [this._msUrl];
    }

    // 如果存在 _clusterId 或者 _msList 为空重新构造 _msList 列表
    if (!notEmptyArray(this._msList) || this._clusterId) {
      this._msList = this.setMediaServiceList();
    }
    return this._msList;
  }

  /**
   * _mslist 列表排序：[_clusterId, ping1, 主域名，ping2, ..., pingN, 备用域名list ]
   * ping1 ：ping 结果返回最快值
   */
  private setMediaServiceList() {
    let backupMsInDetector: string[] = [];
    let backupMsInNavi = [];
    if (this._clusterId) {
      this._clusterId = this._clusterId.replace(/^(https?:\/\/)?/, 'https://');
      this._msList.push(this._clusterId);
    }

    if (notEmptyArray(RCMediaService.msInDetector)) {
      RCMediaService.msInDetector = RCMediaService.msInDetector.map((item) => item.replace(/^(https?:\/\/)?/, 'https://'));
      this._msList.push(RCMediaService.msInDetector[0]);
      backupMsInDetector = RCMediaService.msInDetector.concat().splice(1, RCMediaService.msInDetector.length - 1);
    }

    if (this._msInNavi.length === 0) {
      if (this._failedMs.length === 0) {
        this._msInNavi.push(...parseNaviInfo(this._context.getNaviInfo(), this._logger));
      } else {
        this._msInNavi.push(...this._failedMs);
        this._failedMs.length = 0;
      }
    }

    backupMsInNavi = this._msInNavi.concat().splice(1, this._msInNavi.length - 1);

    this._msList.push(this._msInNavi[0]);
    this._msList = [...this._msList, ...backupMsInDetector, ...backupMsInNavi];

    this._msList = [...new Set(this._msList)];

    this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_MSLIST_CHANGE_O, `MediaServiceList msList -> ${JSON.stringify(this._msList)}`);
    return this._msList;
  }

  /**
   * 发送请求，请求发送若失败，会继续尝试使用后续可用地址直到无地址可用，此时认为请求失败
   * @param path
   * @param header
   * @param body
   */
  private async _request<T>(path: string, headers: any, body: any, traceId?: string, isNeedUpdateMsas?: boolean): Promise<{ code: RCRTCCode, data?: T, qualityMsList?: IMediaServerQualityData[] }> {
    this._qualityMsList = [];
    const openGzip = this._context.getNaviInfo()?.openGzip || false;
    if (openGzip) {
      // TIPS: 改造上行数据，将文本格式修改为 gzip 压缩上传 从而减少上行数据对带宽的占用
      const rawBody = JSON.stringify(body);
      const rawLen = rawBody.length;
      const jsonData = new Uint8Array(rawLen);
      for (let i = 0; i < rawLen; i++) {
        jsonData[i] = rawBody.charCodeAt(i);
      }
      const gzipHeader = {
        'Content-Encoding': 'gzip',
        // 'Accept-Encoding': 'gzip',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      };
      headers = { ...headers, ...gzipHeader };
      body = gzip(jsonData);
    }

    const urls = this.getNaviMS();
    if (urls.length === 0) {
      this._logger.error(RCLoggerTag.L_MEDIA_SERVICE_REQUEST_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER,
        msg: 'have no valid service address',
      }), traceId);

      return { code: RCRTCCode.NOT_OPEN_VIDEO_AUDIO_SERVER };
    }
    if (this._rtcFinger) {
      body.rtcFinger = this._rtcFinger;
    }
    for (let i = 0; i < urls.length; i += 1) {
      const startTime = Date.now();
      const url = `${urls[i]}${path}`;
      const commonHeader = getCommonHeader();
      const mergeHeaders = { ...commonHeader, ...headers };
      // INFO: 当 Content-Type 为 x-www-form-urlencoded 则不需要 进行 Json 转换
      const jsonBody = /x-www-form-urlencoded/.test(mergeHeaders['Content-Type']) ? body : JSON.stringify(body);
      const reqId = commonHeader['Request-Id'];

      this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_REQUEST_T, JSON.stringify({
        url,
        'Request-Id': reqId,
      }), traceId);

      const { status, data } = await this._runtime.httpReq({
        url,
        body: jsonBody,
        headers: mergeHeaders,
        method: HttpMethod.POST,
        timeout: this._timeout,
      });

      /**
       * 发布、取消发布、订阅、取消订阅和 ms 的交互数据
       */
      isNeedUpdateMsas && this._qualityMsList.push({
        rsdur: Date.now() - startTime,
        rsid: reqId,
        rscod: status === 200 ? RCRTCCode.SUCCESS : RCRTCCode.REQUEST_FAILED,
        msa: url,
      });

      if (status === 200) {
        const resp = JSON.parse(data!);
        if (resp.rtcFinger) {
          this._rtcFinger = resp.rtcFinger;
        }
        if (resp.clusterId) {
          this._clusterId = resp.clusterId;
        }

        this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_REQUEST_R, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          url,
        }), traceId);

        return { code: RCRTCCode.SUCCESS, data: resp, qualityMsList: this._qualityMsList };
      }

      // 失败的请求需记录，避免多配置时总是请求无效的地址
      this._failedMs.push(...this._msInNavi.splice(i, 1));
      this._logger.error(RCLoggerTag.L_MEDIA_SERVICE_REQUEST_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        msg: `request error -> Request-Id: ${reqId}, status: ${status}, url: ${url}`,
        timeout: this._timeout,
      }), traceId);
    }
    return { code: RCRTCCode.REQUEST_FAILED, qualityMsList: this._qualityMsList };
  }

  /**
   * 资源协商接口，订阅、发布、变更资源均可以使用此接口。该接口通过 sdp 字段交换 SDP 信息，
   * 并通过 subscribeList 和 publishList 表明最终发布和订阅的资源。本端产出 offer，服务器产出 answer
   * 每次接口调用，都会全量覆盖发布和订阅的资源。
   * @param header
   * @param body
   */
  async exchange(headers: IRTCReqHeader, body: IExchangeReqBody, traceId: string, isNeedUpdateMsas?: boolean) {
    const data = await this._request<IExchangeResponse>('/exchange', headers, body, traceId, isNeedUpdateMsas);
    if (data.code === RCRTCCode.SUCCESS && data.data?.resultCode === RCRTCCode.SUCCESS) {
      const { urls } = data.data;
      if (urls) {
        this._configUrl = urls.configUrl;
      }
    }
    return data;
  }

  /**
   * 退出房间
   */
  async exit(headers: IRTCReqHeader) {
    const traceId = this._logger.createTraceId();
    const { code } = await this._request('/exit', headers, {}, traceId);
    return code;
  }

  /**
   * 观众端订阅主播资源
   */
  broadcastSubscribe(headers: IRTCReqHeader, body: IBroadcastSubReqBody) {
    const traceId = this._logger.createTraceId();
    return this._request<IBroadcastSubRespBody>('/broadcast/subscribe', headers, body, traceId, true);
  }

  /**
   * 观众端退出订阅
   */
  async broadcastExit(headers: IRTCReqHeader): Promise<{ code: RCRTCCode }> {
    const traceId = this._logger.createTraceId();
    const { code } = await this._request('/broadcast/exit', headers, {}, traceId, true);
    return { code };
  }

  /**
   * 直播推流、自定义布局配置
   */
  async setMcuConfig(headers: IMCUReqHeaders, body: IMCUConfig | ISetEnableCDN): Promise<{ code: RCRTCCode, res?: any }> {
    const traceId = this._logger.createTraceId();
    this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_SET_MCU_CONFIG_T, JSON.stringify({
      body,
    }), traceId);

    if (!this._configUrl) {
      this._logger.error(RCLoggerTag.L_MEDIA_SERVICE_SET_MCU_CONFIG_R, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.MCU_SERVER_NOT_FOUND,
        msg: 'MCU server not found',
      }), traceId);

      return { code: RCRTCCode.MCU_SERVER_NOT_FOUND };
    }

    // mcu 地址默认使用 https 协议
    const url = `${this._configUrl.replace(/^(https?:\/\/)?/, 'https://')}/server/mcu/config`;
    const commonHeader = getCommonHeader();
    const mergeHeaders = { ...commonHeader, ...headers };
    const jsonBody = JSON.stringify(body);
    const reqId = commonHeader['Request-Id'];

    const { status, data: jsonStr } = await this._runtime.httpReq({
      url,
      headers: mergeHeaders,
      body: jsonBody,
      method: HttpMethod.POST,
    });

    if (status === 200) {
      const data = JSON.parse(jsonStr!);
      const code = data.resultCode;

      if (code === RCRTCCode.SUCCESS) {
        this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_SET_MCU_CONFIG_R, JSON.stringify({
          status: RCLoggerStatus.SUCCESSED,
          'Request-Id': reqId,
        }), traceId);
      } else {
        this._logger.warn(RCLoggerTag.L_MEDIA_SERVICE_SET_MCU_CONFIG_R, JSON.stringify({
          code,
          'Request-Id': reqId,
        }), traceId);
      }

      return { code, res: data };
    }

    this._logger.error(RCLoggerTag.L_MEDIA_SERVICE_SET_MCU_CONFIG_R, JSON.stringify({
      status: RCLoggerStatus.FAILED,
      code: RCRTCCode.REQUEST_FAILED,
      msg: `request error -> Request-Id: ${reqId}, status: ${status}, url: ${url}`,
    }), traceId);

    return { code: RCRTCCode.REQUEST_FAILED };
  }

  /**
   * 房间内观众获取 CDN 资源信息、拉流地址
   */
  async getCDNResourceInfo(headers: ICDNPlayUrlReqHeaders, url: string, traceId: string): Promise<{ code: RCRTCCode, res?: ICDNPlayUrlResponse }> {
    const commonHeader = getCommonHeader();
    const mergeHeaders = { ...commonHeader, ...headers };
    const reqId = commonHeader['Request-Id'];

    this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_GET_CDN_RESOURCE_INFO_T, `Request-Id: ${reqId}, url: ${url}`, traceId);

    const { status, data: resStr } = await this._runtime.httpReq({
      url,
      headers: mergeHeaders,
      method: HttpMethod.GET,
    });

    if (status === 200) {
      const data = JSON.parse(resStr!);

      this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_GET_CDN_RESOURCE_INFO_R, JSON.stringify({
        status: RCLoggerStatus.SUCCESSED,
        cdnPlayerUrl: data?.data.pull_url,
      }), traceId);

      return { code: data.resultCode, res: data };
    }

    this._logger.info(RCLoggerTag.L_MEDIA_SERVICE_GET_CDN_RESOURCE_INFO_R, JSON.stringify({
      status: RCLoggerStatus.FAILED,
      code: RCRTCCode.REQUEST_FAILED,
    }), traceId);

    return { code: RCRTCCode.REQUEST_FAILED };
  }
}
