import {
  HttpMethod, ILogger, INaviInfo, IRuntime, isArray, RTCPluginContext,
} from '@rongcloud/engine';
import { browserInfo } from '../../helper';
import { IRTCNaviInfo } from '../codec/interface';
import { RTCContext } from '../codec/RTCContext';
import { DETECT_MINUTE } from '../constants';
import { RCLoggerTag } from '../enums/RCLoggerTag';
import { ICallEngine3, ICallEngine4, IDetectoraddrs } from './interface';

export const getUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

const PING_REQ_TIMEOUT = 5 * 1000;

export const randomNum = (min: number, max: number) => min + Math.floor(Math.random() * (max - min));

/**
 * 解析导航数据获取 RTC Server 地址
 * @param info
 */
export const parseNaviInfo = (info: INaviInfo | null, logger: ILogger): string[] => {
  if (!info) {
    return [];
  }
  let voipInfo: { strategy: 0 | 1, callEngine?: Array<ICallEngine3 | ICallEngine4> };
  try {
    voipInfo = JSON.parse(info.voipCallInfo || '{ "strategy": 0 }');
  } catch (error) {
    logger.warn(RCLoggerTag.L_PARSE_VOIP_SERVER_CONF_E, info.voipCallInfo);
    return [];
  }
  if (voipInfo.strategy === 0) {
    return [];
  }
  const engines = voipInfo.callEngine?.filter((item) => item.engineType === 4);
  if (!engines || engines.length === 0) {
    return [];
  }
  const engine = engines[0] as ICallEngine4;
  const result = [];
  engine.mediaServer && result.push(engine.mediaServer.replace(/^(https?:\/\/)?/, 'https://'));
  if (engine.backupMediaServer) {
    engine.backupMediaServer.forEach((item) => {
      result.push(item.replace(/^(https?:\/\/)?/, 'https://'));
    });
  }
  return result;
};

const getCommonHeader = () => ({
  'Content-Type': 'application/json;charset=UTF-8',
  'Cache-Control': 'no-cache',
  ClientType: `web|${browserInfo.browser}|${browserInfo.version}`,
  ClientVersion: __VERSION__,
  'Client-Session-Id': getUUID(),
  'Request-Id': Date.now().toString(),
});

/**
 * 根据探测地址 list 发 ping 拿到返回最快的 Media 地址
 * 执行时机：RTC 初始化时
 * pingRes 使用时机：加房间时
 * 为不阻塞初始化代码执行，有一个 host 地址请求完就 resolve
 * 其他 host 地址后续继续排序进入 pingRes 数组（引用类型保证使用时为更新后的 host 列表）
 * @param hosts 探测地址 list
 * @param runtime
 * @returns
 */
const getFastMediaUrl = async (hosts: IDetectoraddrs[], runtime: IRuntime, jwt: string): Promise<string[]> => {
  // 根据 /ping 的响应速度对 hosts 进行排序响应速度排序
  const pingRes: string[] = [];
  return new Promise((resolve, reject) => {
    hosts.map(async (host) => {
      const detectorAddr = host.detectorAddr.replace(/^(https?:\/\/)?/, 'https://');
      const url = `${detectorAddr}/rtc/detector/ping?t=${randomNum(1000, 9999)}`;
      // 临时测试测试使用
      // const url = `${detectorAddr}/state/server?t=${randomNum(1000, 9999)}`
      const body = { jwt };
      const res = await runtime.httpReq({
        url,
        body: JSON.stringify(body),
        timeout: PING_REQ_TIMEOUT,
        method: HttpMethod.POST,
      });
      if (res.status === 200) {
        pingRes.push(host.clusterId);
      }
      resolve(pingRes);
    });
  });
};

/**
 * @param info navi 信息
 * @returns
 */
const parseDetectorServer = (logger: ILogger, info: INaviInfo | null): string => {
  if (!info) {
    return '';
  }
  let voipInfo: { strategy: 0 | 1, callEngine?: Array<ICallEngine3 | ICallEngine4> };
  try {
    voipInfo = JSON.parse(info.voipCallInfo || '{ "strategy": 0 }');
  } catch (error) {
    logger.warn(RCLoggerTag.L_MEDIA_SERVICE_PARSE_NAVI_CALLVOIP_INFO_O, `parse \`voipCallInfo\` of navi failed: ${info.voipCallInfo}`);
    return '';
  }
  if (voipInfo.strategy === 0) {
    return '';
  }
  const engines = voipInfo.callEngine?.filter((item) => item.engineType === 4);
  if (!engines || engines.length === 0) {
    return '';
  }
  const engine = engines[0] as ICallEngine4;
  return engine.detectorManager ? engine.detectorManager : '';
};

/** 获取 jwt token
 * @param info navi 信息
 * @returns
 */
const parseJWTToken = (info: INaviInfo | null): string => {
  if (!info) {
    return '';
  }
  const jwt = info.jwt || '';
  return jwt;
};
/**
 * 根据导航下发探测服务的总域名请求探测地址
 * @param runtime
 */
export const getDetectorUrls = async (runtime: IRuntime, logger: ILogger, naviInfo: INaviInfo | null): Promise<{
  fastMediaUrl: string[] | null,
  clientDetectMinute: number,
  status: number,
}> => {
  const detectionServer = parseDetectorServer(logger, naviInfo);
  const jwt = parseJWTToken(naviInfo);
  let fastMediaUrl = null;
  let clientDetectMinute = DETECT_MINUTE;
  let status = 0;

  const body = { jwt };
  // navi 下发 detectionServer 或者 jwt 为空时不走嗅探逻辑
  if (detectionServer && jwt) {
    const resStr = await runtime.httpReq({
      url: `${detectionServer}/rtc/detector/servers`,
      body: JSON.stringify(body),
      headers: getCommonHeader(),
      method: HttpMethod.POST,
    });

    const detectoraddrs = resStr.data && JSON.parse(resStr.data).detectorAddrs;
    clientDetectMinute = resStr.data && JSON.parse(resStr.data).clientDetectMinute;
    status = resStr.data && JSON.parse(resStr.data).resultCode;

    if (detectoraddrs && isArray(detectoraddrs)) {
      fastMediaUrl = await getFastMediaUrl(detectoraddrs, runtime, jwt);
    }
  }

  return {
    fastMediaUrl, clientDetectMinute, status,
  };
};

/**
 * 重新获取 naviInfo
 * @param context RTCContext
 * @returns { naviInfo, jwt }
 *
*/
export const refetchNaviInfo = async (context: RTCContext): Promise<{ naviInfo: IRTCNaviInfo | null, jwt: string }> => {
  const _context: RTCPluginContext = context.getPluginContext();
  await _context.refetchNaviInfo();
  const naviInfo = context.getNaviInfo();
  return {
    naviInfo,
    jwt: parseJWTToken(naviInfo),
  };
};
