import {
  BasicLogger, EventEmitter, isNumber,
} from '@rongcloud/engine';
import { RCRTCCode } from '../enums/RCRTCCode';
import { device } from '../../device';
import { RCLoggerStatus, RCLoggerTag } from '../enums/RCLoggerTag';

export enum RCTrackKind {
  AUDIO = 'audio',
  VIDEO = 'video'
}

export abstract class RCTrack extends EventEmitter {
  protected _localMuted: boolean = false

  protected _remoteMuted: boolean = false

  protected readonly _id: string

  protected readonly _streamId: string

  protected _msTrack?: MediaStreamTrack

  private _msStream!: MediaStream

  protected readonly DataTrackId: string = 'data-track-id'

  constructor(
    protected _logger: BasicLogger,
    private readonly _tag: string,
    private readonly _userId: string,
    protected readonly _kind: 'audio' | 'video',
    private readonly _isLocalTrack: boolean,
    private readonly _roomId?: string,
  ) {
    super();
    this._streamId = [this._userId || this._roomId, this._tag].join('_');
    this._id = [this._streamId, this.isAudioTrack() ? 0 : 1].join('_');
  }

  /**
   * 获取音视轨所属的 streamId，streamId 相同的音轨和视轨可认为属于统一道流
   * @returns
   */
  public getStreamId(): string {
    return this._streamId;
  }

  public getTrackId() {
    return this._id;
  }

  /**
   * 当 isMCUTrack 为 true 时，返回空字符串
   */
  public getUserId(): string {
    return this._userId;
  }

  public __innerGetMediaStreamTrack() {
    return this._msTrack;
  }

  /**
   * 它返回 MediaStreamTrack 对象。
   * @returns 表示媒体源的 MediaStreamTrack 对象。
   */
  public get streamTrack(): (MediaStreamTrack | undefined) {
    return this._msTrack;
  }

  /**
   * 获取数据标识
   * @returns
   */
  public getTag(): string {
    return this._tag;
  }

  public isLocalTrack() : boolean {
    return this._isLocalTrack;
  }

  public isVideoTrack(): boolean {
    return this._kind === 'video';
  }

  public isAudioTrack(): boolean {
    return this._kind === 'audio';
  }

  /**
   * 查询流数据是否已可进行播放
   * @returns
   */
  public isReady(): boolean {
    return this._msTrack?.readyState === 'live';
  }

  public __innerSetMediaStreamTrack(track: MediaStreamTrack | undefined) {
    this._msTrack = track;
    this._setLocalMuted(this._localMuted);

    const stream = this._msStream = this._msStream || new MediaStream();
    const preTrack = stream.getTracks()[0];
    preTrack && stream.removeTrack(preTrack);

    if (track) {
      stream.addTrack(track);
      // 判断元素是否存在，以及是元素是否被复用
    } else if (this._element && this._element.getAttribute(this.DataTrackId) === this.getTrackId()) {
      this._element.pause();
      this._element.srcObject = null;
    }
  }

  protected async _setLocalMuted(bool: boolean): Promise<RCRTCCode> {
    if (this._msTrack) {
      this._msTrack.enabled = !bool;
    }
    this._localMuted = bool;
    return RCRTCCode.SUCCESS;
  }

  /**
   * 禁用
   */
  public async mute(): Promise<RCRTCCode> {
    return this._setLocalMuted(true);
  }

  /**
   * 启用
   */
  public async unmute(): Promise<RCRTCCode> {
    return this._setLocalMuted(false);
  }

  /**
   * 本端是否已禁用该轨道数据
   */
  public isLocalMuted(): boolean {
    return this._localMuted;
  }

  /**
   * 是否为 MCU track
   */
  public isMCUTrack(): boolean {
    // 普通 track roomId 为空串，mcu track roomId 为直播房间 Id
    return Boolean(this._roomId);
  }

  /**
   * 发布者是否已禁用该轨道数据，在 RCLocalTrack 实例中，则其值始终等于 `isLocalMuted()`
   */
  public isOwnerMuted(): boolean {
    return this._remoteMuted;
  }

  protected _element?: HTMLMediaElement

  /**
   * 播放
   * @param element 用于承载媒体流的元素标签，音频流可传空
   * @param volume 有效值为 0-100
   */
  public async play(element?: HTMLVideoElement, options?: { volume?: number, audioDeviceId?: string }): Promise<{ code: RCRTCCode }> {
    this._logger.info(RCLoggerTag.L_TRACK_PLAY_O, JSON.stringify({
      element,
      options,
      kind: this._kind,
      msg: `start play trackId: ${this._id}`,
    }));

    if (!this._msTrack) {
      this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.TRACK_NOT_READY,
        kind: this._kind,
        msg: `the track is not ready to play -> id: ${this._id}`,
      }));

      return { code: RCRTCCode.TRACK_NOT_READY };
    }

    if (this._msTrack.readyState === 'ended') {
      this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        kind: this._kind,
        msg: `the track's readyState is ended -> id: ${this._id}`,
      }));
      return { code: RCRTCCode.TRACK_READYSTATE_IS_ENDED };
    }

    if (options?.volume) {
      if (!isNumber(options?.volume)) {
        this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          code: RCRTCCode.PARAMS_ERROR,
          msg: 'params error -> options.volume not a number',
        }));

        return { code: RCRTCCode.PARAMS_ERROR };
      }
      if (options?.volume < 0) {
        options.volume = 0;
        this._logger.warn(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          msg: 'params error -> options.volume < 0',
          tips: 'the valid range of options.volume is 0-100, the value of volume has been set 0',
        }));
      }
      if (options?.volume > 100) {
        options.volume = 100;
        this._logger.warn(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          msg: 'params error -> options.volume > 100',
          tips: 'the valid range of options.volume is 0-100, the value of volume has been set 100',
        }));
      }
    }

    if (options?.audioDeviceId) {
      /**
       * 检测传入的 audioDeviceId 是否有效
       */
      const deviceIds = (await device.getSpeakers()).map((item) => item.deviceId);
      const isValid = deviceIds.includes(options.audioDeviceId);
      if (!isValid) {
        this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          code: RCRTCCode.PARAMS_ERROR,
          msg: 'params error -> options.audioDeviceId',
        }));

        return { code: RCRTCCode.PARAMS_ERROR };
      }
    }

    const isVideoTrack = this.isVideoTrack();

    // video 播放必须传递一个 HTMLVideoElement 实例作为 video track 的播放组件
    if (isVideoTrack && (!element || !(element instanceof HTMLVideoElement || this.__validateVideoNodeName(element)))) {
      this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
        status: RCLoggerStatus.FAILED,
        code: RCRTCCode.VIDEO_TRACK_MISS_MEDIA_ELEMENT,
        kind: this._kind,
        msg: 'params element is not a HTMLVideoElement',
      }));

      return { code: RCRTCCode.VIDEO_TRACK_MISS_MEDIA_ELEMENT };
    }

    this._element = isVideoTrack ? element! : (this._element || new Audio());

    // 若本地静音，则恢复本地播放
    if (this._localMuted) {
      this._setLocalMuted(false);
    }

    if (!this._element.srcObject || this._element.srcObject !== this._msStream) {
      this._element.pause();
    }

    this._element.onloadstart = (evt) => {
      // 开始寻找资源
      this._logger.info(RCLoggerTag.L_TRACK_LOAD_START_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        kind: this._kind,
        msg: `HTMLMediaElement onloadstart -> id: ${(evt.target as any)?.id}, trackId: ${this._id}`,
      }));
    };
    this._element.ondurationchange = (evt) => {
      // 时长变更
      this._logger.info(RCLoggerTag.L_TRACK_DURATION_CHANGE_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        kind: this._kind,
        msg: `HTMLMediaElement ondurationchange -> id: ${(evt.target as any)?.id}, trackId: ${this._id}`,
      }));
    };
    this._element.onloadedmetadata = (evt) => {
      // 元数据加载完成
      this._logger.info(RCLoggerTag.L_TRACK_LOADED_META_DATA_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        kind: this._kind,
        msg: `HTMLMediaElement onloadedmetadata -> id: ${(evt.target as any)?.id}, trackId: ${this._id}`,
      }));
    };
    this._element.onloadeddata = (evt) => {
      // 首帧加载完成
      this._logger.info(RCLoggerTag.L_TRACK_LOADED_DATA_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        kind: this._kind,
        msg: `HTMLMediaElement onloadeddata -> id: ${(evt.target as any)?.id}, trackId: ${this._id}`,
      }));
    };
    this._element.onabort = (evt: UIEvent) => {
      // 中止
      this._logger.info(RCLoggerTag.L_TRACK_ABORT_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        kind: this._kind,
        msg: `HTMLMediaElement onabort -> id: ${(evt.target as any)?.id}, trackId: ${this._id}`,
      }));
    };
    this._element.oncanplay = async (evt) => {
      // 可以播放
      this._logger.info(RCLoggerTag.L_TRACK_CANPLAY_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        kind: this._kind,
        msg: `HTMLMediaElement oncanplay -> id: ${(evt.target as any)?.id}, trackId: ${this._id}`,
      }));
      try {
        if (options?.audioDeviceId && !isVideoTrack) {
          await (this._element as any).setSinkId(options.audioDeviceId);
        }
        await this._element?.play();
      } catch (error) {
        /**
         * 检测是否有设置音频输出设备的权限
         */
        if ((error as DOMException).message === 'No permission to use requested device') {
          this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
            status: RCLoggerStatus.FAILED,
            kind: this._kind,
            code: RCRTCCode.NO_PERMISSION_TO_USE_REQUESTED_DEVICE,
            msg: `setSinkId failed, error msg: ${(error as DOMException).message}`,
          }));
          return;
        }

        this._logger.error(RCLoggerTag.L_TRACK_PLAY_E, JSON.stringify({
          status: RCLoggerStatus.FAILED,
          code: RCRTCCode.TRACK_PLAY_ERROR,
          kind: this._kind,
          msg: `play error, error msg: ${(error as DOMException).message}`,
        }));
      }
    };
    this._element.onvolumechange = (evt) => {
      // 音量变化
      const volume = Math.floor((evt.target as any)?.volume * 100);

      this._logger.info(RCLoggerTag.L_TRACK_VOLUME_CHANGE_O, JSON.stringify({
        status: RCLoggerStatus.INFO,
        msg: `HTMLMediaElement onvolumechange -> id: ${(evt.target as any)?.id}, volume: ${volume}, trackId: ${this._id}`,
      }));
    };
    this._element.srcObject = this._msStream;
    this._element.autoplay = true;

    // video 标签页面内播放
    if (isVideoTrack) {
      (this._element as HTMLVideoElement).playsInline = true;
      (this._element as any).x5PlaysInline = true;
      (this._element as any).webkitPlaysInline = true;
    }

    if (options?.audioDeviceId && !isVideoTrack) {
      await (this._element as any).setSinkId(options.audioDeviceId);
    }

    // audio 标签设置音量
    if (!isVideoTrack && (options?.volume || options?.volume === 0)) {
      this._element.volume = options.volume / 100;
    }

    // 添加标签ID
    this._element?.setAttribute(this.DataTrackId, this.getTrackId());

    return { code: RCRTCCode.SUCCESS };
  }

  public __innerDestroy() {
    this.__innerSetMediaStreamTrack(undefined);
  }

  /**
   * 释放内存中的 video、audio 标签
   */
  public __releaseMediaElement() {
    // 判断元素是否存在，以及是元素是否被复用
    if (this._element && this._element.getAttribute(this.DataTrackId) === this.getTrackId()) {
      this._element.remove();
      this._element.srcObject = null;
    }
  }

  /**
   * 它检查元素是否是视频节点
   * @param {any} element - 您要检查的元素是否为视频元素。
   * @returns 一个布尔值。
   */
  private __validateVideoNodeName(element: any) {
    return element && element.nodeName && element.nodeName.toUpperCase() === 'VIDEO';
  }
}
